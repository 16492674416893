import ConfirmationDialog from '@/app/module/campaigns/component/item/manage/subscriptions/confirmation-dialog'
import React from 'react'

type Props = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

const SpeechTranscribeDialog: React.FC<Props> = ({ open, onClose, onConfirm }) => {
  return (
    <ConfirmationDialog
      id="speech-transcribe-dialog"
      deleteButtonText="Enable Transcription"
      isOpen={open}
      title="AI enhanced answer recognition"
      icon="assistant"
      text={
        <>
          <br />
          <span>
            Use Artificial Intelligence (AI) to automatically detect what language the contact speaks in their response
            and transcribe it to text. This text is then used to find the matching answer.
          </span>
          <br />
          <br />
          <span>
            <b>NOT PERFECT:</b> AI is far from perfect and can get things (very) wrong, both what language is spoken by
            the contact and what the contact says. In particular, single-word clips or other very short responses are
            often misunderstood. We highly recommend you test how well AI does for your use-case.
          </span>
          <br />
          <br />
          <span>
            <b>DATA SHARING:</b> This means we will send data to a large language model like ChatGPT. We will send the
            question with any transcription, all answer choices, and the audio file and its eventual transcription. We
            will NOT send the phone number, first or last name or any other contact data—unless it's included in the
            question or the reply somehow.
          </span>
          <br />
          <br />
          <b>
            By enabling this feature you confirm that you understand this and consent to the data sharing as described
            above.
          </b>
        </>
      }
      onClose={onClose}
      onConfirm={onConfirm}
    />
  )
}
export default SpeechTranscribeDialog
