// eslint-disable
// file generated with loadresources.sh
export const languages = [
  'Acholi',
  'Adhola',
  'Afrikaans',
  'Albanian',
  'Alur',
  'Arabic',
  'Argentinian Spanish',
  'Asturian',
  'Australian English',
  'Azerbaijani',
  'Bari',
  'Basque',
  'Belarusian',
  'Bengali',
  'Bisaya',
  'Bosnian',
  'Brazilian Portuguese',
  'Breton',
  'British English',
  'Bulgarian',
  'Burmese',
  'Catalan',
  'Cebuano',
  'Croatian',
  'Czech',
  'Danish',
  'Dholuo',
  'Dutch',
  'English',
  'Esperanto',
  'Estonian',
  'Filipino',
  'Finnish',
  'French',
  'Frisian',
  'Galician',
  'Georgian',
  'German',
  'Greek',
  'Hebrew',
  'Hindi',
  'Hungarian',
  'Icelandic',
  'Ido',
  'Indonesian',
  'Interlingua',
  'Irish',
  'Italian',
  'Japanese',
  'Jopadhola',
  'Kannada',
  'Karo',
  'Kazakh',
  'Khmer',
  'Korean',
  'Kumam',
  'Kutuk',
  'Lango',
  'Latvian',
  'Lithuanian',
  'Ludama',
  'Luganda',
  'Luo',
  'Luxembourgish',
  'Macedonian',
  'Malayalam',
  'Marathi',
  'Mexican Spanish',
  'Mongolian',
  'Nepali',
  'Nicaraguan Spanish',
  'Norwegian Bokmal',
  'Norwegian Nynorsk',
  'Ossetic',
  'Persian',
  'Polish',
  'Portuguese',
  'Punjabi',
  'Romanian',
  'Russian',
  'Serbian',
  'Serbian Latin',
  'Simplified Chinese',
  'Slovak',
  'Slovenian',
  'Spanish',
  'Swahili',
  'Swedish',
  'Tagalog',
  'Tamil',
  'Tatar',
  'Telugu',
  'Thai',
  'Traditional Chinese',
  'Turkish',
  'Udmurt',
  'Ukrainian',
  'Urdu',
  'Venezuelan Spanish',
  'Vietnamese',
  'Waray',
  'Welsh',
]
export const esCountries = [
  {
    code: 'AB',
    name: 'Abkhazia',
  },
  {
    code: 'AF',
    name: 'Afghanistan',
  },
  {
    code: 'AX',
    name: 'Aland Islands',
  },
  {
    code: 'AL',
    name: 'Albania',
  },
  {
    code: 'DZ',
    name: 'Algeria',
  },
  {
    code: 'AS',
    name: 'American Samoa',
  },
  {
    code: 'AD',
    name: 'Andorra',
  },
  {
    code: 'AO',
    name: 'Angola',
  },
  {
    code: 'AI',
    name: 'Anguilla',
  },
  {
    code: 'AQ',
    name: 'Antarctica',
  },
  {
    code: 'AG',
    name: 'Antigua and Barbuda',
  },
  {
    code: 'AR',
    name: 'Argentina',
  },
  {
    code: 'AM',
    name: 'Armenia',
  },
  {
    code: 'AW',
    name: 'Aruba',
  },
  {
    code: 'AC',
    name: 'Ascension Island',
  },
  {
    code: 'AU',
    name: 'Australia',
  },
  {
    code: 'AT',
    name: 'Austria',
  },
  {
    code: 'AZ',
    name: 'Azerbaijan',
  },
  {
    code: 'BS',
    name: 'Bahamas',
  },
  {
    code: 'BH',
    name: 'Bahrain',
  },
  {
    code: 'BD',
    name: 'Bangladesh',
  },
  {
    code: 'BB',
    name: 'Barbados',
  },
  {
    code: 'BY',
    name: 'Belarus',
  },
  {
    code: 'BE',
    name: 'Belgium',
  },
  {
    code: 'BZ',
    name: 'Belize',
  },
  {
    code: 'BJ',
    name: 'Benin',
  },
  {
    code: 'BM',
    name: 'Bermuda',
  },
  {
    code: 'BT',
    name: 'Bhutan',
  },
  {
    code: 'BO',
    name: 'Bolivia',
  },
  {
    code: 'BQ',
    name: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    code: 'BA',
    name: 'Bosnia and Herzegovina',
  },
  {
    code: 'BW',
    name: 'Botswana',
  },
  {
    code: 'BV',
    name: 'Bouvet Island',
  },
  {
    code: 'BR',
    name: 'Brazil',
  },
  {
    code: 'IO',
    name: 'British Indian Ocean Territory',
  },
  {
    code: 'VG',
    name: 'British Virgin Islands-BVI',
  },
  {
    code: 'BN',
    name: 'Brunei Darussalam',
  },
  {
    code: 'BG',
    name: 'Bulgaria',
  },
  {
    code: 'BF',
    name: 'Burkina Faso',
  },
  {
    code: 'BI',
    name: 'Burundi',
  },
  {
    code: 'KH',
    name: 'Cambodia',
  },
  {
    code: 'CM',
    name: 'Cameroon',
  },
  {
    code: 'CA',
    name: 'Canada',
  },
  {
    code: 'CV',
    name: 'Cape Verde',
  },
  {
    code: 'KY',
    name: 'Cayman Islands',
  },
  {
    code: 'CF',
    name: 'Central African Republic',
  },
  {
    code: 'TD',
    name: 'Chad',
  },
  {
    code: 'CL',
    name: 'Chile',
  },
  {
    code: 'CN',
    name: 'China',
  },
  {
    code: 'CX',
    name: 'Christmas Island',
  },
  {
    code: 'CC',
    name: 'Cocos (Keeling) Islands',
  },
  {
    code: 'CO',
    name: 'Colombia',
  },
  {
    code: 'KM',
    name: 'Comoros',
  },
  {
    code: 'CG',
    name: 'Congo',
  },
  {
    code: 'CK',
    name: 'Cook Islands',
  },
  {
    code: 'CR',
    name: 'Costa Rica',
  },
  {
    code: 'CI',
    name: "Cote d'Ivoire-Ivory Coast",
  },
  {
    code: 'HR',
    name: 'Croatia',
  },
  {
    code: 'CU',
    name: 'Cuba',
  },
  {
    code: 'CW',
    name: 'Curacao',
  },
  {
    code: 'CY',
    name: 'Cyprus',
  },
  {
    code: 'CZ',
    name: 'Czech Republic',
  },
  {
    code: 'CD',
    name: 'Democratic Republic of Congo-DRC',
  },
  {
    code: 'DK',
    name: 'Denmark',
  },
  {
    code: 'DJ',
    name: 'Djibouti',
  },
  {
    code: 'DM',
    name: 'Dominica',
  },
  {
    code: 'DO',
    name: 'Dominican Republic',
  },
  {
    code: 'TL',
    name: 'East Timor-Timor-Leste',
  },
  {
    code: 'EC',
    name: 'Ecuador',
  },
  {
    code: 'EG',
    name: 'Egypt',
  },
  {
    code: 'SV',
    name: 'El Salvador',
  },
  {
    code: 'GQ',
    name: 'Equatorial Guinea',
  },
  {
    code: 'ER',
    name: 'Eritrea',
  },
  {
    code: 'EE',
    name: 'Estonia',
  },
  {
    code: 'SZ',
    name: 'Eswatini (Swaziland)',
  },
  {
    code: 'ET',
    name: 'Ethiopia',
  },
  {
    code: 'FK',
    name: 'Falkland Islands (Malvinas)',
  },
  {
    code: 'FO',
    name: 'Faroe Islands',
  },
  {
    code: 'FJ',
    name: 'Fiji',
  },
  {
    code: 'FI',
    name: 'Finland',
  },
  {
    code: 'FR',
    name: 'France',
  },
  {
    code: 'GF',
    name: 'French Guiana',
  },
  {
    code: 'PF',
    name: 'French Polynesia',
  },
  {
    code: 'TF',
    name: 'French Southern Territories',
  },
  {
    code: 'GA',
    name: 'Gabon',
  },
  {
    code: 'GM',
    name: 'Gambia',
  },
  {
    code: 'GE',
    name: 'Georgia',
  },
  {
    code: 'DE',
    name: 'Germany',
  },
  {
    code: 'GH',
    name: 'Ghana',
  },
  {
    code: 'GI',
    name: 'Gibraltar',
  },
  {
    code: 'GR',
    name: 'Greece',
  },
  {
    code: 'GL',
    name: 'Greenland',
  },
  {
    code: 'GD',
    name: 'Grenada',
  },
  {
    code: 'GP',
    name: 'Guadeloupe',
  },
  {
    code: 'GU',
    name: 'Guam',
  },
  {
    code: 'GT',
    name: 'Guatemala',
  },
  {
    code: 'GG',
    name: 'Guernsey',
  },
  {
    code: 'GN',
    name: 'Guinea',
  },
  {
    code: 'GW',
    name: 'Guinea-Bissau',
  },
  {
    code: 'GY',
    name: 'Guyana',
  },
  {
    code: 'HT',
    name: 'Haiti',
  },
  {
    code: 'HM',
    name: 'Heard Island and McDonald Islands',
  },
  {
    code: 'HN',
    name: 'Honduras',
  },
  {
    code: 'HK',
    name: 'Hong Kong',
  },
  {
    code: 'HU',
    name: 'Hungary',
  },
  {
    code: 'IS',
    name: 'Iceland',
  },
  {
    code: 'IN',
    name: 'India',
  },
  {
    code: 'ID',
    name: 'Indonesia',
  },
  {
    code: 'IR',
    name: 'Iran',
  },
  {
    code: 'IQ',
    name: 'Iraq',
  },
  {
    code: 'IE',
    name: 'Ireland',
  },
  {
    code: 'IM',
    name: 'Isle of Man',
  },
  {
    code: 'IL',
    name: 'Israel',
  },
  {
    code: 'IT',
    name: 'Italy',
  },
  {
    code: 'JM',
    name: 'Jamaica',
  },
  {
    code: 'JP',
    name: 'Japan',
  },
  {
    code: 'JE',
    name: 'Jersey',
  },
  {
    code: 'JO',
    name: 'Jordan',
  },
  {
    code: 'KZ',
    name: 'Kazakhstan',
  },
  {
    code: 'KE',
    name: 'Kenya',
  },
  {
    code: 'KI',
    name: 'Kiribati',
  },
  {
    code: 'XK',
    name: 'Kosovo',
  },
  {
    code: 'KW',
    name: 'Kuwait',
  },
  {
    code: 'KG',
    name: 'Kyrgyzstan',
  },
  {
    code: 'LA',
    name: 'Laos',
  },
  {
    code: 'LV',
    name: 'Latvia',
  },
  {
    code: 'LB',
    name: 'Lebanon',
  },
  {
    code: 'LS',
    name: 'Lesotho',
  },
  {
    code: 'LR',
    name: 'Liberia',
  },
  {
    code: 'LY',
    name: 'Libya',
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
  },
  {
    code: 'LT',
    name: 'Lithuania',
  },
  {
    code: 'LU',
    name: 'Luxembourg',
  },
  {
    code: 'MO',
    name: 'Macao',
  },
  {
    code: 'MK',
    name: 'Macedonia',
  },
  {
    code: 'MG',
    name: 'Madagascar',
  },
  {
    code: 'MW',
    name: 'Malawi',
  },
  {
    code: 'MY',
    name: 'Malaysia',
  },
  {
    code: 'MV',
    name: 'Maldives',
  },
  {
    code: 'ML',
    name: 'Mali',
  },
  {
    code: 'MT',
    name: 'Malta',
  },
  {
    code: 'MH',
    name: 'Marshall Islands',
  },
  {
    code: 'MQ',
    name: 'Martinique',
  },
  {
    code: 'MR',
    name: 'Mauritania',
  },
  {
    code: 'MU',
    name: 'Mauritius',
  },
  {
    code: 'YT',
    name: 'Mayotte',
  },
  {
    code: 'MX',
    name: 'Mexico',
  },
  {
    code: 'FM',
    name: 'Micronesia',
  },
  {
    code: 'MD',
    name: 'Moldova',
  },
  {
    code: 'MC',
    name: 'Monaco',
  },
  {
    code: 'MN',
    name: 'Mongolia',
  },
  {
    code: 'ME',
    name: 'Montenegro',
  },
  {
    code: 'MS',
    name: 'Montserrat',
  },
  {
    code: 'MA',
    name: 'Morocco',
  },
  {
    code: 'MZ',
    name: 'Mozambique',
  },
  {
    code: 'MM',
    name: 'Myanmar',
  },
  {
    code: 'NA',
    name: 'Namibia',
  },
  {
    code: 'NR',
    name: 'Nauru',
  },
  {
    code: 'NP',
    name: 'Nepal',
  },
  {
    code: 'NL',
    name: 'Netherlands',
  },
  {
    code: 'NC',
    name: 'New Caledonia',
  },
  {
    code: 'NZ',
    name: 'New Zealand',
  },
  {
    code: 'NI',
    name: 'Nicaragua',
  },
  {
    code: 'NE',
    name: 'Niger',
  },
  {
    code: 'NG',
    name: 'Nigeria',
  },
  {
    code: 'NU',
    name: 'Niue',
  },
  {
    code: 'NF',
    name: 'Norfolk Island',
  },
  {
    code: 'MP',
    name: 'Northern Mariana Islands',
  },
  {
    code: 'KP',
    name: 'North Korea',
  },
  {
    code: 'NO',
    name: 'Norway',
  },
  {
    code: 'OM',
    name: 'Oman',
  },
  {
    code: 'PK',
    name: 'Pakistan',
  },
  {
    code: 'PW',
    name: 'Palau',
  },
  {
    code: 'PS',
    name: 'Palestine',
  },
  {
    code: 'PA',
    name: 'Panama',
  },
  {
    code: 'PG',
    name: 'Papua New Guinea',
  },
  {
    code: 'PY',
    name: 'Paraguay',
  },
  {
    code: 'PE',
    name: 'Peru',
  },
  {
    code: 'PH',
    name: 'Philippines',
  },
  {
    code: 'PN',
    name: 'Pitcairn',
  },
  {
    code: 'PL',
    name: 'Poland',
  },
  {
    code: 'PT',
    name: 'Portugal',
  },
  {
    code: 'PR',
    name: 'Puerto Rico',
  },
  {
    code: 'QA',
    name: 'Qatar',
  },
  {
    code: 'RE',
    name: 'Reunion',
  },
  {
    code: 'RO',
    name: 'Romania',
  },
  {
    code: 'RU',
    name: 'Russia',
  },
  {
    code: 'RW',
    name: 'Rwanda',
  },
  {
    code: 'BL',
    name: 'Saint Barthelemy',
  },
  {
    code: 'SH',
    name: 'Saint Helena and Tristan da Cunha',
  },
  {
    code: 'KN',
    name: 'Saint Kitts and Nevis',
  },
  {
    code: 'LC',
    name: 'Saint Lucia',
  },
  {
    code: 'MF',
    name: 'Saint Martin (French part)',
  },
  {
    code: 'PM',
    name: 'Saint Pierre and Miquelon',
  },
  {
    code: 'VC',
    name: 'Saint Vincent and the Grenadines',
  },
  {
    code: 'WS',
    name: 'Samoa',
  },
  {
    code: 'SM',
    name: 'San Marino',
  },
  {
    code: 'ST',
    name: 'Sao Tome and Principe',
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
  },
  {
    code: 'SN',
    name: 'Senegal',
  },
  {
    code: 'RS',
    name: 'Serbia',
  },
  {
    code: 'SC',
    name: 'Seychelles',
  },
  {
    code: 'SL',
    name: 'Sierra Leone',
  },
  {
    code: 'SG',
    name: 'Singapore',
  },
  {
    code: 'SX',
    name: 'Sint Maarten (Dutch part)',
  },
  {
    code: 'SK',
    name: 'Slovakia',
  },
  {
    code: 'SI',
    name: 'Slovenia',
  },
  {
    code: 'SB',
    name: 'Solomon Islands',
  },
  {
    code: 'SO',
    name: 'Somalia',
  },
  {
    code: 'ZA',
    name: 'South Africa',
  },
  {
    code: 'GS',
    name: 'South Georgia and the South Sandwich Islands',
  },
  {
    code: 'KR',
    name: 'South Korea',
  },
  {
    code: 'SS',
    name: 'South Sudan',
  },
  {
    code: 'ES',
    name: 'Spain',
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
  },
  {
    code: 'SD',
    name: 'Sudan',
  },
  {
    code: 'SR',
    name: 'Suriname',
  },
  {
    code: 'SJ',
    name: 'Svalbard and Jan Mayen',
  },
  {
    code: 'SE',
    name: 'Sweden',
  },
  {
    code: 'CH',
    name: 'Switzerland',
  },
  {
    code: 'SY',
    name: 'Syria',
  },
  {
    code: 'TW',
    name: 'Taiwan',
  },
  {
    code: 'TJ',
    name: 'Tajikistan',
  },
  {
    code: 'TZ',
    name: 'Tanzania',
  },
  {
    code: 'TH',
    name: 'Thailand',
  },
  {
    code: 'TG',
    name: 'Togo',
  },
  {
    code: 'TK',
    name: 'Tokelau',
  },
  {
    code: 'TO',
    name: 'Tonga',
  },
  {
    code: 'TT',
    name: 'Trinidad and Tobago',
  },
  {
    code: 'TN',
    name: 'Tunisia',
  },
  {
    code: 'TR',
    name: 'Turkey',
  },
  {
    code: 'TM',
    name: 'Turkmenistan',
  },
  {
    code: 'TC',
    name: 'Turks and Caicos Islands',
  },
  {
    code: 'TV',
    name: 'Tuvalu',
  },
  {
    code: 'UG',
    name: 'Uganda',
  },
  {
    code: 'UA',
    name: 'Ukraine',
  },
  {
    code: 'AE',
    name: 'United Arab Emirates-UAE',
  },
  {
    code: 'GB',
    name: 'United Kingdom-UK',
  },
  {
    code: 'UM',
    name: 'United States Minor Outlying Islands',
  },
  {
    code: 'US',
    name: 'United States of America-USA',
  },
  {
    code: 'UY',
    name: 'Uruguay',
  },
  {
    code: 'VI',
    name: 'U.S. Virgin Islands-USVI',
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
  },
  {
    code: 'VU',
    name: 'Vanuatu',
  },
  {
    code: 'VA',
    name: 'Vatican-Holy See',
  },
  {
    code: 'VE',
    name: 'Venezuela',
  },
  {
    code: 'VN',
    name: 'Vietnam',
  },
  {
    code: 'WF',
    name: 'Wallis and Futuna',
  },
  {
    code: 'EH',
    name: 'Western Sahara',
  },
  {
    code: 'YE',
    name: 'Yemen',
  },
  {
    code: 'ZM',
    name: 'Zambia',
  },
  {
    code: 'ZW',
    name: 'Zimbabwe',
  },
]
export const countries = {
  AD: {
    country: 'Andorra',
    tzs: [
      {
        city: 'Andorra',
        offset: '+0200',
        timezone: 'Europe/Andorra',
      },
    ],
  },
  AE: {
    country: 'United Arab Emirates',
    tzs: [
      {
        city: 'Dubai',
        offset: '+0400',
        timezone: 'Asia/Dubai',
      },
    ],
  },
  AF: {
    country: 'Afghanistan',
    tzs: [
      {
        city: 'Kabul',
        offset: '+0430',
        timezone: 'Asia/Kabul',
      },
    ],
  },
  AG: {
    country: 'Antigua and Barbuda',
    tzs: [
      {
        city: 'Antigua',
        offset: '-0400',
        timezone: 'America/Antigua',
      },
    ],
  },
  AI: {
    country: 'Anguilla',
    tzs: [
      {
        city: 'Anguilla',
        offset: '-0400',
        timezone: 'America/Anguilla',
      },
    ],
  },
  AL: {
    country: 'Albania',
    tzs: [
      {
        city: 'Tirane',
        offset: '+0200',
        timezone: 'Europe/Tirane',
      },
    ],
  },
  AM: {
    country: 'Armenia',
    tzs: [
      {
        city: 'Yerevan',
        offset: '+0400',
        timezone: 'Asia/Yerevan',
      },
    ],
  },
  AO: {
    country: 'Angola',
    tzs: [
      {
        city: 'Luanda',
        offset: '+0100',
        timezone: 'Africa/Luanda',
      },
    ],
  },
  AQ: {
    country: 'Antarctica',
    tzs: [
      {
        city: 'Casey',
        offset: '+1100',
        timezone: 'Antarctica/Casey',
      },
      {
        city: 'Davis',
        offset: '+0700',
        timezone: 'Antarctica/Davis',
      },
      {
        city: 'DumontDUrville',
        offset: '+1000',
        timezone: 'Antarctica/DumontDUrville',
      },
      {
        city: 'Mawson',
        offset: '+0500',
        timezone: 'Antarctica/Mawson',
      },
      {
        city: 'McMurdo',
        offset: '+1300',
        timezone: 'Antarctica/McMurdo',
      },
      {
        city: 'Palmer',
        offset: '-0300',
        timezone: 'Antarctica/Palmer',
      },
      {
        city: 'Rothera',
        offset: '-0300',
        timezone: 'Antarctica/Rothera',
      },
      {
        city: 'Syowa',
        offset: '+0300',
        timezone: 'Antarctica/Syowa',
      },
      {
        city: 'Troll',
        offset: '+0200',
        timezone: 'Antarctica/Troll',
      },
      {
        city: 'Vostok',
        offset: '+0600',
        timezone: 'Antarctica/Vostok',
      },
    ],
  },
  AR: {
    country: 'Argentina',
    tzs: [
      {
        city: 'Buenos Aires',
        offset: '-0300',
        timezone: 'America/Argentina/Buenos_Aires',
      },
      {
        city: 'Catamarca',
        offset: '-0300',
        timezone: 'America/Argentina/Catamarca',
      },
      {
        city: 'Cordoba',
        offset: '-0300',
        timezone: 'America/Argentina/Cordoba',
      },
      {
        city: 'Jujuy',
        offset: '-0300',
        timezone: 'America/Argentina/Jujuy',
      },
      {
        city: 'La Rioja',
        offset: '-0300',
        timezone: 'America/Argentina/La_Rioja',
      },
      {
        city: 'Mendoza',
        offset: '-0300',
        timezone: 'America/Argentina/Mendoza',
      },
      {
        city: 'Rio Gallegos',
        offset: '-0300',
        timezone: 'America/Argentina/Rio_Gallegos',
      },
      {
        city: 'Salta',
        offset: '-0300',
        timezone: 'America/Argentina/Salta',
      },
      {
        city: 'San Juan',
        offset: '-0300',
        timezone: 'America/Argentina/San_Juan',
      },
      {
        city: 'San Luis',
        offset: '-0300',
        timezone: 'America/Argentina/San_Luis',
      },
      {
        city: 'Tucuman',
        offset: '-0300',
        timezone: 'America/Argentina/Tucuman',
      },
      {
        city: 'Ushuaia',
        offset: '-0300',
        timezone: 'America/Argentina/Ushuaia',
      },
    ],
  },
  AS: {
    country: 'American Samoa',
    tzs: [
      {
        city: 'Pago Pago',
        offset: '-1100',
        timezone: 'Pacific/Pago_Pago',
      },
    ],
  },
  AT: {
    country: 'Austria',
    tzs: [
      {
        city: 'Vienna',
        offset: '+0200',
        timezone: 'Europe/Vienna',
      },
    ],
  },
  AU: {
    country: 'Australia',
    tzs: [
      {
        city: 'Macquarie',
        offset: '+1100',
        timezone: 'Antarctica/Macquarie',
      },
      {
        city: 'Adelaide',
        offset: '+1030',
        timezone: 'Australia/Adelaide',
      },
      {
        city: 'Brisbane',
        offset: '+1000',
        timezone: 'Australia/Brisbane',
      },
      {
        city: 'Broken Hill',
        offset: '+1030',
        timezone: 'Australia/Broken_Hill',
      },
      {
        city: 'Currie',
        offset: '+1100',
        timezone: 'Australia/Currie',
      },
      {
        city: 'Darwin',
        offset: '+0930',
        timezone: 'Australia/Darwin',
      },
      {
        city: 'Eucla',
        offset: '+0845',
        timezone: 'Australia/Eucla',
      },
      {
        city: 'Hobart',
        offset: '+1100',
        timezone: 'Australia/Hobart',
      },
      {
        city: 'Lindeman',
        offset: '+1000',
        timezone: 'Australia/Lindeman',
      },
      {
        city: 'Lord Howe',
        offset: '+1100',
        timezone: 'Australia/Lord_Howe',
      },
      {
        city: 'Melbourne',
        offset: '+1100',
        timezone: 'Australia/Melbourne',
      },
      {
        city: 'Perth',
        offset: '+0800',
        timezone: 'Australia/Perth',
      },
      {
        city: 'Sydney',
        offset: '+1100',
        timezone: 'Australia/Sydney',
      },
    ],
  },
  AW: {
    country: 'Aruba',
    tzs: [
      {
        city: 'Aruba',
        offset: '-0400',
        timezone: 'America/Aruba',
      },
    ],
  },
  AX: {
    country: 'Åland Islands',
    tzs: [
      {
        city: 'Mariehamn',
        offset: '+0300',
        timezone: 'Europe/Mariehamn',
      },
    ],
  },
  AZ: {
    country: 'Azerbaijan',
    tzs: [
      {
        city: 'Baku',
        offset: '+0400',
        timezone: 'Asia/Baku',
      },
    ],
  },
  BA: {
    country: 'Bosnia and Herzegovina',
    tzs: [
      {
        city: 'Sarajevo',
        offset: '+0200',
        timezone: 'Europe/Sarajevo',
      },
    ],
  },
  BB: {
    country: 'Barbados',
    tzs: [
      {
        city: 'Barbados',
        offset: '-0400',
        timezone: 'America/Barbados',
      },
    ],
  },
  BD: {
    country: 'Bangladesh',
    tzs: [
      {
        city: 'Dhaka',
        offset: '+0600',
        timezone: 'Asia/Dhaka',
      },
    ],
  },
  BE: {
    country: 'Belgium',
    tzs: [
      {
        city: 'Brussels',
        offset: '+0200',
        timezone: 'Europe/Brussels',
      },
    ],
  },
  BF: {
    country: 'Burkina Faso',
    tzs: [
      {
        city: 'Ouagadougou',
        offset: '+0000',
        timezone: 'Africa/Ouagadougou',
      },
    ],
  },
  BG: {
    country: 'Bulgaria',
    tzs: [
      {
        city: 'Sofia',
        offset: '+0300',
        timezone: 'Europe/Sofia',
      },
    ],
  },
  BH: {
    country: 'Bahrain',
    tzs: [
      {
        city: 'Bahrain',
        offset: '+0300',
        timezone: 'Asia/Bahrain',
      },
    ],
  },
  BI: {
    country: 'Burundi',
    tzs: [
      {
        city: 'Bujumbura',
        offset: '+0200',
        timezone: 'Africa/Bujumbura',
      },
    ],
  },
  BJ: {
    country: 'Benin',
    tzs: [
      {
        city: 'Porto-Novo',
        offset: '+0100',
        timezone: 'Africa/Porto-Novo',
      },
    ],
  },
  BL: {
    country: 'Saint Barthélemy',
    tzs: [
      {
        city: 'St Barthelemy',
        offset: '-0400',
        timezone: 'America/St_Barthelemy',
      },
    ],
  },
  BM: {
    country: 'Bermuda',
    tzs: [
      {
        city: 'Bermuda',
        offset: '-0300',
        timezone: 'Atlantic/Bermuda',
      },
    ],
  },
  BN: {
    country: 'Brunei Darussalam',
    tzs: [
      {
        city: 'Brunei',
        offset: '+0800',
        timezone: 'Asia/Brunei',
      },
    ],
  },
  BO: {
    country: 'Bolivia, Plurinational State of',
    tzs: [
      {
        city: 'La Paz',
        offset: '-0400',
        timezone: 'America/La_Paz',
      },
    ],
  },
  BQ: {
    country: 'Bonaire, Sint Eustatius and Saba',
    tzs: [
      {
        city: 'Kralendijk',
        offset: '-0400',
        timezone: 'America/Kralendijk',
      },
    ],
  },
  BR: {
    country: 'Brazil',
    tzs: [
      {
        city: 'Araguaina',
        offset: '-0300',
        timezone: 'America/Araguaina',
      },
      {
        city: 'Bahia',
        offset: '-0300',
        timezone: 'America/Bahia',
      },
      {
        city: 'Belem',
        offset: '-0300',
        timezone: 'America/Belem',
      },
      {
        city: 'Boa Vista',
        offset: '-0400',
        timezone: 'America/Boa_Vista',
      },
      {
        city: 'Campo Grande',
        offset: '-0400',
        timezone: 'America/Campo_Grande',
      },
      {
        city: 'Cuiaba',
        offset: '-0400',
        timezone: 'America/Cuiaba',
      },
      {
        city: 'Eirunepe',
        offset: '-0500',
        timezone: 'America/Eirunepe',
      },
      {
        city: 'Fortaleza',
        offset: '-0300',
        timezone: 'America/Fortaleza',
      },
      {
        city: 'Maceio',
        offset: '-0300',
        timezone: 'America/Maceio',
      },
      {
        city: 'Manaus',
        offset: '-0400',
        timezone: 'America/Manaus',
      },
      {
        city: 'Noronha',
        offset: '-0200',
        timezone: 'America/Noronha',
      },
      {
        city: 'Porto Velho',
        offset: '-0400',
        timezone: 'America/Porto_Velho',
      },
      {
        city: 'Recife',
        offset: '-0300',
        timezone: 'America/Recife',
      },
      {
        city: 'Rio Branco',
        offset: '-0500',
        timezone: 'America/Rio_Branco',
      },
      {
        city: 'Santarem',
        offset: '-0300',
        timezone: 'America/Santarem',
      },
      {
        city: 'Sao Paulo',
        offset: '-0300',
        timezone: 'America/Sao_Paulo',
      },
    ],
  },
  BS: {
    country: 'Bahamas',
    tzs: [
      {
        city: 'Nassau',
        offset: '-0400',
        timezone: 'America/Nassau',
      },
    ],
  },
  BT: {
    country: 'Bhutan',
    tzs: [
      {
        city: 'Thimphu',
        offset: '+0600',
        timezone: 'Asia/Thimphu',
      },
    ],
  },
  BW: {
    country: 'Botswana',
    tzs: [
      {
        city: 'Gaborone',
        offset: '+0200',
        timezone: 'Africa/Gaborone',
      },
    ],
  },
  BY: {
    country: 'Belarus',
    tzs: [
      {
        city: 'Minsk',
        offset: '+0300',
        timezone: 'Europe/Minsk',
      },
    ],
  },
  BZ: {
    country: 'Belize',
    tzs: [
      {
        city: 'Belize',
        offset: '-0600',
        timezone: 'America/Belize',
      },
    ],
  },
  CA: {
    country: 'Canada',
    tzs: [
      {
        city: 'Atikokan',
        offset: '-0500',
        timezone: 'America/Atikokan',
      },
      {
        city: 'Blanc-Sablon',
        offset: '-0400',
        timezone: 'America/Blanc-Sablon',
      },
      {
        city: 'Cambridge Bay',
        offset: '-0600',
        timezone: 'America/Cambridge_Bay',
      },
      {
        city: 'Creston',
        offset: '-0700',
        timezone: 'America/Creston',
      },
      {
        city: 'Dawson',
        offset: '-0700',
        timezone: 'America/Dawson',
      },
      {
        city: 'Dawson Creek',
        offset: '-0700',
        timezone: 'America/Dawson_Creek',
      },
      {
        city: 'Edmonton',
        offset: '-0600',
        timezone: 'America/Edmonton',
      },
      {
        city: 'Fort Nelson',
        offset: '-0700',
        timezone: 'America/Fort_Nelson',
      },
      {
        city: 'Glace Bay',
        offset: '-0300',
        timezone: 'America/Glace_Bay',
      },
      {
        city: 'Goose Bay',
        offset: '-0300',
        timezone: 'America/Goose_Bay',
      },
      {
        city: 'Halifax',
        offset: '-0300',
        timezone: 'America/Halifax',
      },
      {
        city: 'Inuvik',
        offset: '-0600',
        timezone: 'America/Inuvik',
      },
      {
        city: 'Iqaluit',
        offset: '-0400',
        timezone: 'America/Iqaluit',
      },
      {
        city: 'Moncton',
        offset: '-0300',
        timezone: 'America/Moncton',
      },
      {
        city: 'Nipigon',
        offset: '-0400',
        timezone: 'America/Nipigon',
      },
      {
        city: 'Pangnirtung',
        offset: '-0400',
        timezone: 'America/Pangnirtung',
      },
      {
        city: 'Rainy River',
        offset: '-0500',
        timezone: 'America/Rainy_River',
      },
      {
        city: 'Rankin Inlet',
        offset: '-0500',
        timezone: 'America/Rankin_Inlet',
      },
      {
        city: 'Regina',
        offset: '-0600',
        timezone: 'America/Regina',
      },
      {
        city: 'Resolute',
        offset: '-0500',
        timezone: 'America/Resolute',
      },
      {
        city: 'St Johns',
        offset: '-0230',
        timezone: 'America/St_Johns',
      },
      {
        city: 'Swift Current',
        offset: '-0600',
        timezone: 'America/Swift_Current',
      },
      {
        city: 'Thunder Bay',
        offset: '-0400',
        timezone: 'America/Thunder_Bay',
      },
      {
        city: 'Toronto',
        offset: '-0400',
        timezone: 'America/Toronto',
      },
      {
        city: 'Vancouver',
        offset: '-0700',
        timezone: 'America/Vancouver',
      },
      {
        city: 'Whitehorse',
        offset: '-0700',
        timezone: 'America/Whitehorse',
      },
      {
        city: 'Winnipeg',
        offset: '-0500',
        timezone: 'America/Winnipeg',
      },
      {
        city: 'Yellowknife',
        offset: '-0600',
        timezone: 'America/Yellowknife',
      },
    ],
  },
  CC: {
    country: 'Cocos (Keeling) Islands',
    tzs: [
      {
        city: 'Cocos',
        offset: '+0630',
        timezone: 'Indian/Cocos',
      },
    ],
  },
  CD: {
    country: 'Congo, the Democratic Republic of the',
    tzs: [
      {
        city: 'Kinshasa',
        offset: '+0100',
        timezone: 'Africa/Kinshasa',
      },
      {
        city: 'Lubumbashi',
        offset: '+0200',
        timezone: 'Africa/Lubumbashi',
      },
    ],
  },
  CF: {
    country: 'Central African Republic',
    tzs: [
      {
        city: 'Bangui',
        offset: '+0100',
        timezone: 'Africa/Bangui',
      },
    ],
  },
  CG: {
    country: 'Congo',
    tzs: [
      {
        city: 'Brazzaville',
        offset: '+0100',
        timezone: 'Africa/Brazzaville',
      },
    ],
  },
  CH: {
    country: 'Switzerland',
    tzs: [
      {
        city: 'Zurich',
        offset: '+0200',
        timezone: 'Europe/Zurich',
      },
    ],
  },
  CI: {
    country: "Côte d'Ivoire",
    tzs: [
      {
        city: 'Abidjan',
        offset: '+0000',
        timezone: 'Africa/Abidjan',
      },
    ],
  },
  CK: {
    country: 'Cook Islands',
    tzs: [
      {
        city: 'Rarotonga',
        offset: '-1000',
        timezone: 'Pacific/Rarotonga',
      },
    ],
  },
  CL: {
    country: 'Chile',
    tzs: [
      {
        city: 'Punta Arenas',
        offset: '-0300',
        timezone: 'America/Punta_Arenas',
      },
      {
        city: 'Santiago',
        offset: '-0300',
        timezone: 'America/Santiago',
      },
      {
        city: 'Easter',
        offset: '-0500',
        timezone: 'Pacific/Easter',
      },
    ],
  },
  CM: {
    country: 'Cameroon',
    tzs: [
      {
        city: 'Douala',
        offset: '+0100',
        timezone: 'Africa/Douala',
      },
    ],
  },
  CN: {
    country: 'China',
    tzs: [
      {
        city: 'Shanghai',
        offset: '+0800',
        timezone: 'Asia/Shanghai',
      },
      {
        city: 'Urumqi',
        offset: '+0600',
        timezone: 'Asia/Urumqi',
      },
    ],
  },
  CO: {
    country: 'Colombia',
    tzs: [
      {
        city: 'Bogota',
        offset: '-0500',
        timezone: 'America/Bogota',
      },
    ],
  },
  CR: {
    country: 'Costa Rica',
    tzs: [
      {
        city: 'Costa Rica',
        offset: '-0600',
        timezone: 'America/Costa_Rica',
      },
    ],
  },
  CU: {
    country: 'Cuba',
    tzs: [
      {
        city: 'Havana',
        offset: '-0400',
        timezone: 'America/Havana',
      },
    ],
  },
  CV: {
    country: 'Cape Verde',
    tzs: [
      {
        city: 'Cape Verde',
        offset: '-0100',
        timezone: 'Atlantic/Cape_Verde',
      },
    ],
  },
  CW: {
    country: 'Curaçao',
    tzs: [
      {
        city: 'Curacao',
        offset: '-0400',
        timezone: 'America/Curacao',
      },
    ],
  },
  CX: {
    country: 'Christmas Island',
    tzs: [
      {
        city: 'Christmas',
        offset: '+0700',
        timezone: 'Indian/Christmas',
      },
    ],
  },
  CY: {
    country: 'Cyprus',
    tzs: [
      {
        city: 'Famagusta',
        offset: '+0300',
        timezone: 'Asia/Famagusta',
      },
      {
        city: 'Nicosia',
        offset: '+0300',
        timezone: 'Asia/Nicosia',
      },
    ],
  },
  CZ: {
    country: 'Czech Republic',
    tzs: [
      {
        city: 'Prague',
        offset: '+0200',
        timezone: 'Europe/Prague',
      },
    ],
  },
  DE: {
    country: 'Germany',
    tzs: [
      {
        city: 'Berlin',
        offset: '+0200',
        timezone: 'Europe/Berlin',
      },
      {
        city: 'Busingen',
        offset: '+0200',
        timezone: 'Europe/Busingen',
      },
    ],
  },
  DJ: {
    country: 'Djibouti',
    tzs: [
      {
        city: 'Djibouti',
        offset: '+0300',
        timezone: 'Africa/Djibouti',
      },
    ],
  },
  DK: {
    country: 'Denmark',
    tzs: [
      {
        city: 'Copenhagen',
        offset: '+0200',
        timezone: 'Europe/Copenhagen',
      },
    ],
  },
  DM: {
    country: 'Dominica',
    tzs: [
      {
        city: 'Dominica',
        offset: '-0400',
        timezone: 'America/Dominica',
      },
    ],
  },
  DO: {
    country: 'Dominican Republic',
    tzs: [
      {
        city: 'Santo Domingo',
        offset: '-0400',
        timezone: 'America/Santo_Domingo',
      },
    ],
  },
  DZ: {
    country: 'Algeria',
    tzs: [
      {
        city: 'Algiers',
        offset: '+0100',
        timezone: 'Africa/Algiers',
      },
    ],
  },
  EC: {
    country: 'Ecuador',
    tzs: [
      {
        city: 'Guayaquil',
        offset: '-0500',
        timezone: 'America/Guayaquil',
      },
      {
        city: 'Galapagos',
        offset: '-0600',
        timezone: 'Pacific/Galapagos',
      },
    ],
  },
  EE: {
    country: 'Estonia',
    tzs: [
      {
        city: 'Tallinn',
        offset: '+0300',
        timezone: 'Europe/Tallinn',
      },
    ],
  },
  EG: {
    country: 'Egypt',
    tzs: [
      {
        city: 'Cairo',
        offset: '+0200',
        timezone: 'Africa/Cairo',
      },
    ],
  },
  EH: {
    country: 'Western Sahara',
    tzs: [
      {
        city: 'El Aaiun',
        offset: '+0100',
        timezone: 'Africa/El_Aaiun',
      },
    ],
  },
  ER: {
    country: 'Eritrea',
    tzs: [
      {
        city: 'Asmara',
        offset: '+0300',
        timezone: 'Africa/Asmara',
      },
    ],
  },
  ES: {
    country: 'Spain',
    tzs: [
      {
        city: 'Ceuta',
        offset: '+0200',
        timezone: 'Africa/Ceuta',
      },
      {
        city: 'Canary',
        offset: '+0100',
        timezone: 'Atlantic/Canary',
      },
      {
        city: 'Madrid',
        offset: '+0200',
        timezone: 'Europe/Madrid',
      },
    ],
  },
  ET: {
    country: 'Ethiopia',
    tzs: [
      {
        city: 'Addis Ababa',
        offset: '+0300',
        timezone: 'Africa/Addis_Ababa',
      },
    ],
  },
  FI: {
    country: 'Finland',
    tzs: [
      {
        city: 'Helsinki',
        offset: '+0300',
        timezone: 'Europe/Helsinki',
      },
    ],
  },
  FJ: {
    country: 'Fiji',
    tzs: [
      {
        city: 'Fiji',
        offset: '+1200',
        timezone: 'Pacific/Fiji',
      },
    ],
  },
  FK: {
    country: 'Falkland Islands (Malvinas)',
    tzs: [
      {
        city: 'Stanley',
        offset: '-0300',
        timezone: 'Atlantic/Stanley',
      },
    ],
  },
  FM: {
    country: 'Micronesia, Federated States of',
    tzs: [
      {
        city: 'Chuuk',
        offset: '+1000',
        timezone: 'Pacific/Chuuk',
      },
      {
        city: 'Kosrae',
        offset: '+1100',
        timezone: 'Pacific/Kosrae',
      },
      {
        city: 'Pohnpei',
        offset: '+1100',
        timezone: 'Pacific/Pohnpei',
      },
    ],
  },
  FO: {
    country: 'Faroe Islands',
    tzs: [
      {
        city: 'Faroe',
        offset: '+0100',
        timezone: 'Atlantic/Faroe',
      },
    ],
  },
  FR: {
    country: 'France',
    tzs: [
      {
        city: 'Paris',
        offset: '+0200',
        timezone: 'Europe/Paris',
      },
    ],
  },
  GA: {
    country: 'Gabon',
    tzs: [
      {
        city: 'Libreville',
        offset: '+0100',
        timezone: 'Africa/Libreville',
      },
    ],
  },
  GB: {
    country: 'United Kingdom',
    tzs: [
      {
        city: 'London',
        offset: '+0100',
        timezone: 'Europe/London',
      },
    ],
  },
  GD: {
    country: 'Grenada',
    tzs: [
      {
        city: 'Grenada',
        offset: '-0400',
        timezone: 'America/Grenada',
      },
    ],
  },
  GE: {
    country: 'Georgia',
    tzs: [
      {
        city: 'Tbilisi',
        offset: '+0400',
        timezone: 'Asia/Tbilisi',
      },
    ],
  },
  GF: {
    country: 'French Guiana',
    tzs: [
      {
        city: 'Cayenne',
        offset: '-0300',
        timezone: 'America/Cayenne',
      },
    ],
  },
  GG: {
    country: 'Guernsey',
    tzs: [
      {
        city: 'Guernsey',
        offset: '+0100',
        timezone: 'Europe/Guernsey',
      },
    ],
  },
  GH: {
    country: 'Ghana',
    tzs: [
      {
        city: 'Accra',
        offset: '+0000',
        timezone: 'Africa/Accra',
      },
    ],
  },
  GI: {
    country: 'Gibraltar',
    tzs: [
      {
        city: 'Gibraltar',
        offset: '+0200',
        timezone: 'Europe/Gibraltar',
      },
    ],
  },
  GL: {
    country: 'Greenland',
    tzs: [
      {
        city: 'Danmarkshavn',
        offset: '+0000',
        timezone: 'America/Danmarkshavn',
      },
      {
        city: 'Godthab',
        offset: '-0200',
        timezone: 'America/Godthab',
      },
      {
        city: 'Scoresbysund',
        offset: '+0000',
        timezone: 'America/Scoresbysund',
      },
      {
        city: 'Thule',
        offset: '-0300',
        timezone: 'America/Thule',
      },
    ],
  },
  GM: {
    country: 'Gambia',
    tzs: [
      {
        city: 'Banjul',
        offset: '+0000',
        timezone: 'Africa/Banjul',
      },
    ],
  },
  GN: {
    country: 'Guinea',
    tzs: [
      {
        city: 'Conakry',
        offset: '+0000',
        timezone: 'Africa/Conakry',
      },
    ],
  },
  GP: {
    country: 'Guadeloupe',
    tzs: [
      {
        city: 'Guadeloupe',
        offset: '-0400',
        timezone: 'America/Guadeloupe',
      },
    ],
  },
  GQ: {
    country: 'Equatorial Guinea',
    tzs: [
      {
        city: 'Malabo',
        offset: '+0100',
        timezone: 'Africa/Malabo',
      },
    ],
  },
  GR: {
    country: 'Greece',
    tzs: [
      {
        city: 'Athens',
        offset: '+0300',
        timezone: 'Europe/Athens',
      },
    ],
  },
  GS: {
    country: 'South Georgia and the South Sandwich Islands',
    tzs: [
      {
        city: 'South Georgia',
        offset: '-0200',
        timezone: 'Atlantic/South_Georgia',
      },
    ],
  },
  GT: {
    country: 'Guatemala',
    tzs: [
      {
        city: 'Guatemala',
        offset: '-0600',
        timezone: 'America/Guatemala',
      },
    ],
  },
  GU: {
    country: 'Guam',
    tzs: [
      {
        city: 'Guam',
        offset: '+1000',
        timezone: 'Pacific/Guam',
      },
    ],
  },
  GW: {
    country: 'Guinea-Bissau',
    tzs: [
      {
        city: 'Bissau',
        offset: '+0000',
        timezone: 'Africa/Bissau',
      },
    ],
  },
  GY: {
    country: 'Guyana',
    tzs: [
      {
        city: 'Guyana',
        offset: '-0400',
        timezone: 'America/Guyana',
      },
    ],
  },
  HK: {
    country: 'Hong Kong',
    tzs: [
      {
        city: 'Hong Kong',
        offset: '+0800',
        timezone: 'Asia/Hong_Kong',
      },
    ],
  },
  HN: {
    country: 'Honduras',
    tzs: [
      {
        city: 'Tegucigalpa',
        offset: '-0600',
        timezone: 'America/Tegucigalpa',
      },
    ],
  },
  HR: {
    country: 'Croatia',
    tzs: [
      {
        city: 'Zagreb',
        offset: '+0200',
        timezone: 'Europe/Zagreb',
      },
    ],
  },
  HT: {
    country: 'Haiti',
    tzs: [
      {
        city: 'Port-au-Prince',
        offset: '-0400',
        timezone: 'America/Port-au-Prince',
      },
    ],
  },
  HU: {
    country: 'Hungary',
    tzs: [
      {
        city: 'Budapest',
        offset: '+0200',
        timezone: 'Europe/Budapest',
      },
    ],
  },
  ID: {
    country: 'Indonesia',
    tzs: [
      {
        city: 'Jakarta',
        offset: '+0700',
        timezone: 'Asia/Jakarta',
      },
      {
        city: 'Jayapura',
        offset: '+0900',
        timezone: 'Asia/Jayapura',
      },
      {
        city: 'Makassar',
        offset: '+0800',
        timezone: 'Asia/Makassar',
      },
      {
        city: 'Pontianak',
        offset: '+0700',
        timezone: 'Asia/Pontianak',
      },
    ],
  },
  IE: {
    country: 'Ireland',
    tzs: [
      {
        city: 'Dublin',
        offset: '+0100',
        timezone: 'Europe/Dublin',
      },
    ],
  },
  IL: {
    country: 'Israel',
    tzs: [
      {
        city: 'Jerusalem',
        offset: '+0300',
        timezone: 'Asia/Jerusalem',
      },
    ],
  },
  IM: {
    country: 'Isle of Man',
    tzs: [
      {
        city: 'Isle of Man',
        offset: '+0100',
        timezone: 'Europe/Isle_of_Man',
      },
    ],
  },
  IN: {
    country: 'India',
    tzs: [
      {
        city: 'Kolkata',
        offset: '+0530',
        timezone: 'Asia/Kolkata',
      },
    ],
  },
  IO: {
    country: 'British Indian Ocean Territory',
    tzs: [
      {
        city: 'Chagos',
        offset: '+0600',
        timezone: 'Indian/Chagos',
      },
    ],
  },
  IQ: {
    country: 'Iraq',
    tzs: [
      {
        city: 'Baghdad',
        offset: '+0300',
        timezone: 'Asia/Baghdad',
      },
    ],
  },
  IR: {
    country: 'Iran, Islamic Republic of',
    tzs: [
      {
        city: 'Tehran',
        offset: '+0330',
        timezone: 'Asia/Tehran',
      },
    ],
  },
  IS: {
    country: 'Iceland',
    tzs: [
      {
        city: 'Reykjavik',
        offset: '+0000',
        timezone: 'Atlantic/Reykjavik',
      },
    ],
  },
  IT: {
    country: 'Italy',
    tzs: [
      {
        city: 'Rome',
        offset: '+0200',
        timezone: 'Europe/Rome',
      },
    ],
  },
  JE: {
    country: 'Jersey',
    tzs: [
      {
        city: 'Jersey',
        offset: '+0100',
        timezone: 'Europe/Jersey',
      },
    ],
  },
  JM: {
    country: 'Jamaica',
    tzs: [
      {
        city: 'Jamaica',
        offset: '-0500',
        timezone: 'America/Jamaica',
      },
    ],
  },
  JO: {
    country: 'Jordan',
    tzs: [
      {
        city: 'Amman',
        offset: '+0300',
        timezone: 'Asia/Amman',
      },
    ],
  },
  JP: {
    country: 'Japan',
    tzs: [
      {
        city: 'Tokyo',
        offset: '+0900',
        timezone: 'Asia/Tokyo',
      },
    ],
  },
  KE: {
    country: 'Kenya',
    tzs: [
      {
        city: 'Nairobi',
        offset: '+0300',
        timezone: 'Africa/Nairobi',
      },
    ],
  },
  KG: {
    country: 'Kyrgyzstan',
    tzs: [
      {
        city: 'Bishkek',
        offset: '+0600',
        timezone: 'Asia/Bishkek',
      },
    ],
  },
  KH: {
    country: 'Cambodia',
    tzs: [
      {
        city: 'Phnom Penh',
        offset: '+0700',
        timezone: 'Asia/Phnom_Penh',
      },
    ],
  },
  KI: {
    country: 'Kiribati',
    tzs: [
      {
        city: 'Enderbury',
        offset: '+1300',
        timezone: 'Pacific/Enderbury',
      },
      {
        city: 'Kiritimati',
        offset: '+1400',
        timezone: 'Pacific/Kiritimati',
      },
      {
        city: 'Tarawa',
        offset: '+1200',
        timezone: 'Pacific/Tarawa',
      },
    ],
  },
  KM: {
    country: 'Comoros',
    tzs: [
      {
        city: 'Comoro',
        offset: '+0300',
        timezone: 'Indian/Comoro',
      },
    ],
  },
  KN: {
    country: 'Saint Kitts and Nevis',
    tzs: [
      {
        city: 'St Kitts',
        offset: '-0400',
        timezone: 'America/St_Kitts',
      },
    ],
  },
  KP: {
    country: "Korea, Democratic People's Republic of",
    tzs: [
      {
        city: 'Pyongyang',
        offset: '+0900',
        timezone: 'Asia/Pyongyang',
      },
    ],
  },
  KR: {
    country: 'Korea, Republic of',
    tzs: [
      {
        city: 'Seoul',
        offset: '+0900',
        timezone: 'Asia/Seoul',
      },
    ],
  },
  KW: {
    country: 'Kuwait',
    tzs: [
      {
        city: 'Kuwait',
        offset: '+0300',
        timezone: 'Asia/Kuwait',
      },
    ],
  },
  KY: {
    country: 'Cayman Islands',
    tzs: [
      {
        city: 'Cayman',
        offset: '-0500',
        timezone: 'America/Cayman',
      },
    ],
  },
  KZ: {
    country: 'Kazakhstan',
    tzs: [
      {
        city: 'Almaty',
        offset: '+0600',
        timezone: 'Asia/Almaty',
      },
      {
        city: 'Aqtau',
        offset: '+0500',
        timezone: 'Asia/Aqtau',
      },
      {
        city: 'Aqtobe',
        offset: '+0500',
        timezone: 'Asia/Aqtobe',
      },
      {
        city: 'Atyrau',
        offset: '+0500',
        timezone: 'Asia/Atyrau',
      },
      {
        city: 'Oral',
        offset: '+0500',
        timezone: 'Asia/Oral',
      },
      {
        city: 'Qostanay',
        offset: '+0600',
        timezone: 'Asia/Qostanay',
      },
      {
        city: 'Qyzylorda',
        offset: '+0500',
        timezone: 'Asia/Qyzylorda',
      },
    ],
  },
  LA: {
    country: "Lao People's Democratic Republic",
    tzs: [
      {
        city: 'Vientiane',
        offset: '+0700',
        timezone: 'Asia/Vientiane',
      },
    ],
  },
  LB: {
    country: 'Lebanon',
    tzs: [
      {
        city: 'Beirut',
        offset: '+0300',
        timezone: 'Asia/Beirut',
      },
    ],
  },
  LC: {
    country: 'Saint Lucia',
    tzs: [
      {
        city: 'St Lucia',
        offset: '-0400',
        timezone: 'America/St_Lucia',
      },
    ],
  },
  LI: {
    country: 'Liechtenstein',
    tzs: [
      {
        city: 'Vaduz',
        offset: '+0200',
        timezone: 'Europe/Vaduz',
      },
    ],
  },
  LK: {
    country: 'Sri Lanka',
    tzs: [
      {
        city: 'Colombo',
        offset: '+0530',
        timezone: 'Asia/Colombo',
      },
    ],
  },
  LR: {
    country: 'Liberia',
    tzs: [
      {
        city: 'Monrovia',
        offset: '+0000',
        timezone: 'Africa/Monrovia',
      },
    ],
  },
  LS: {
    country: 'Lesotho',
    tzs: [
      {
        city: 'Maseru',
        offset: '+0200',
        timezone: 'Africa/Maseru',
      },
    ],
  },
  LT: {
    country: 'Lithuania',
    tzs: [
      {
        city: 'Vilnius',
        offset: '+0300',
        timezone: 'Europe/Vilnius',
      },
    ],
  },
  LU: {
    country: 'Luxembourg',
    tzs: [
      {
        city: 'Luxembourg',
        offset: '+0200',
        timezone: 'Europe/Luxembourg',
      },
    ],
  },
  LV: {
    country: 'Latvia',
    tzs: [
      {
        city: 'Riga',
        offset: '+0300',
        timezone: 'Europe/Riga',
      },
    ],
  },
  LY: {
    country: 'Libya',
    tzs: [
      {
        city: 'Tripoli',
        offset: '+0200',
        timezone: 'Africa/Tripoli',
      },
    ],
  },
  MA: {
    country: 'Morocco',
    tzs: [
      {
        city: 'Casablanca',
        offset: '+0100',
        timezone: 'Africa/Casablanca',
      },
    ],
  },
  MC: {
    country: 'Monaco',
    tzs: [
      {
        city: 'Monaco',
        offset: '+0200',
        timezone: 'Europe/Monaco',
      },
    ],
  },
  MD: {
    country: 'Moldova, Republic of',
    tzs: [
      {
        city: 'Chisinau',
        offset: '+0300',
        timezone: 'Europe/Chisinau',
      },
    ],
  },
  ME: {
    country: 'Montenegro',
    tzs: [
      {
        city: 'Podgorica',
        offset: '+0200',
        timezone: 'Europe/Podgorica',
      },
    ],
  },
  MF: {
    country: 'Saint Martin (French part)',
    tzs: [
      {
        city: 'Marigot',
        offset: '-0400',
        timezone: 'America/Marigot',
      },
    ],
  },
  MG: {
    country: 'Madagascar',
    tzs: [
      {
        city: 'Antananarivo',
        offset: '+0300',
        timezone: 'Indian/Antananarivo',
      },
    ],
  },
  MH: {
    country: 'Marshall Islands',
    tzs: [
      {
        city: 'Kwajalein',
        offset: '+1200',
        timezone: 'Pacific/Kwajalein',
      },
      {
        city: 'Majuro',
        offset: '+1200',
        timezone: 'Pacific/Majuro',
      },
    ],
  },
  MK: {
    country: 'Macedonia, the Former Yugoslav Republic of',
    tzs: [
      {
        city: 'Skopje',
        offset: '+0200',
        timezone: 'Europe/Skopje',
      },
    ],
  },
  ML: {
    country: 'Mali',
    tzs: [
      {
        city: 'Bamako',
        offset: '+0000',
        timezone: 'Africa/Bamako',
      },
    ],
  },
  MM: {
    country: 'Myanmar',
    tzs: [
      {
        city: 'Yangon',
        offset: '+0630',
        timezone: 'Asia/Yangon',
      },
    ],
  },
  MN: {
    country: 'Mongolia',
    tzs: [
      {
        city: 'Choibalsan',
        offset: '+0800',
        timezone: 'Asia/Choibalsan',
      },
      {
        city: 'Hovd',
        offset: '+0700',
        timezone: 'Asia/Hovd',
      },
      {
        city: 'Ulaanbaatar',
        offset: '+0800',
        timezone: 'Asia/Ulaanbaatar',
      },
    ],
  },
  MO: {
    country: 'Macao',
    tzs: [
      {
        city: 'Macau',
        offset: '+0800',
        timezone: 'Asia/Macau',
      },
    ],
  },
  MP: {
    country: 'Northern Mariana Islands',
    tzs: [
      {
        city: 'Saipan',
        offset: '+1000',
        timezone: 'Pacific/Saipan',
      },
    ],
  },
  MQ: {
    country: 'Martinique',
    tzs: [
      {
        city: 'Martinique',
        offset: '-0400',
        timezone: 'America/Martinique',
      },
    ],
  },
  MR: {
    country: 'Mauritania',
    tzs: [
      {
        city: 'Nouakchott',
        offset: '+0000',
        timezone: 'Africa/Nouakchott',
      },
    ],
  },
  MS: {
    country: 'Montserrat',
    tzs: [
      {
        city: 'Montserrat',
        offset: '-0400',
        timezone: 'America/Montserrat',
      },
    ],
  },
  MT: {
    country: 'Malta',
    tzs: [
      {
        city: 'Malta',
        offset: '+0200',
        timezone: 'Europe/Malta',
      },
    ],
  },
  MU: {
    country: 'Mauritius',
    tzs: [
      {
        city: 'Mauritius',
        offset: '+0400',
        timezone: 'Indian/Mauritius',
      },
    ],
  },
  MV: {
    country: 'Maldives',
    tzs: [
      {
        city: 'Maldives',
        offset: '+0500',
        timezone: 'Indian/Maldives',
      },
    ],
  },
  MW: {
    country: 'Malawi',
    tzs: [
      {
        city: 'Blantyre',
        offset: '+0200',
        timezone: 'Africa/Blantyre',
      },
    ],
  },
  MX: {
    country: 'Mexico',
    tzs: [
      {
        city: 'Bahia Banderas',
        offset: '-0500',
        timezone: 'America/Bahia_Banderas',
      },
      {
        city: 'Cancun',
        offset: '-0500',
        timezone: 'America/Cancun',
      },
      {
        city: 'Chihuahua',
        offset: '-0600',
        timezone: 'America/Chihuahua',
      },
      {
        city: 'Hermosillo',
        offset: '-0700',
        timezone: 'America/Hermosillo',
      },
      {
        city: 'Matamoros',
        offset: '-0500',
        timezone: 'America/Matamoros',
      },
      {
        city: 'Mazatlan',
        offset: '-0600',
        timezone: 'America/Mazatlan',
      },
      {
        city: 'Merida',
        offset: '-0500',
        timezone: 'America/Merida',
      },
      {
        city: 'Mexico City',
        offset: '-0500',
        timezone: 'America/Mexico_City',
      },
      {
        city: 'Monterrey',
        offset: '-0500',
        timezone: 'America/Monterrey',
      },
      {
        city: 'Ojinaga',
        offset: '-0600',
        timezone: 'America/Ojinaga',
      },
      {
        city: 'Tijuana',
        offset: '-0700',
        timezone: 'America/Tijuana',
      },
    ],
  },
  MY: {
    country: 'Malaysia',
    tzs: [
      {
        city: 'Kuala Lumpur',
        offset: '+0800',
        timezone: 'Asia/Kuala_Lumpur',
      },
      {
        city: 'Kuching',
        offset: '+0800',
        timezone: 'Asia/Kuching',
      },
    ],
  },
  MZ: {
    country: 'Mozambique',
    tzs: [
      {
        city: 'Maputo',
        offset: '+0200',
        timezone: 'Africa/Maputo',
      },
    ],
  },
  NA: {
    country: 'Namibia',
    tzs: [
      {
        city: 'Windhoek',
        offset: '+0200',
        timezone: 'Africa/Windhoek',
      },
    ],
  },
  NC: {
    country: 'New Caledonia',
    tzs: [
      {
        city: 'Noumea',
        offset: '+1100',
        timezone: 'Pacific/Noumea',
      },
    ],
  },
  NE: {
    country: 'Niger',
    tzs: [
      {
        city: 'Niamey',
        offset: '+0100',
        timezone: 'Africa/Niamey',
      },
    ],
  },
  NF: {
    country: 'Norfolk Island',
    tzs: [
      {
        city: 'Norfolk',
        offset: '+1200',
        timezone: 'Pacific/Norfolk',
      },
    ],
  },
  NG: {
    country: 'Nigeria',
    tzs: [
      {
        city: 'Lagos',
        offset: '+0100',
        timezone: 'Africa/Lagos',
      },
    ],
  },
  NI: {
    country: 'Nicaragua',
    tzs: [
      {
        city: 'Managua',
        offset: '-0600',
        timezone: 'America/Managua',
      },
    ],
  },
  NL: {
    country: 'Netherlands',
    tzs: [
      {
        city: 'Amsterdam',
        offset: '+0200',
        timezone: 'Europe/Amsterdam',
      },
    ],
  },
  NO: {
    country: 'Norway',
    tzs: [
      {
        city: 'Oslo',
        offset: '+0200',
        timezone: 'Europe/Oslo',
      },
    ],
  },
  NP: {
    country: 'Nepal',
    tzs: [
      {
        city: 'Kathmandu',
        offset: '+0545',
        timezone: 'Asia/Kathmandu',
      },
    ],
  },
  NR: {
    country: 'Nauru',
    tzs: [
      {
        city: 'Nauru',
        offset: '+1200',
        timezone: 'Pacific/Nauru',
      },
    ],
  },
  NU: {
    country: 'Niue',
    tzs: [
      {
        city: 'Niue',
        offset: '-1100',
        timezone: 'Pacific/Niue',
      },
    ],
  },
  NZ: {
    country: 'New Zealand',
    tzs: [
      {
        city: 'Auckland',
        offset: '+1300',
        timezone: 'Pacific/Auckland',
      },
      {
        city: 'Chatham',
        offset: '+1345',
        timezone: 'Pacific/Chatham',
      },
    ],
  },
  OM: {
    country: 'Oman',
    tzs: [
      {
        city: 'Muscat',
        offset: '+0400',
        timezone: 'Asia/Muscat',
      },
    ],
  },
  PA: {
    country: 'Panama',
    tzs: [
      {
        city: 'Panama',
        offset: '-0500',
        timezone: 'America/Panama',
      },
    ],
  },
  PE: {
    country: 'Peru',
    tzs: [
      {
        city: 'Lima',
        offset: '-0500',
        timezone: 'America/Lima',
      },
    ],
  },
  PF: {
    country: 'French Polynesia',
    tzs: [
      {
        city: 'Gambier',
        offset: '-0900',
        timezone: 'Pacific/Gambier',
      },
      {
        city: 'Marquesas',
        offset: '-0930',
        timezone: 'Pacific/Marquesas',
      },
      {
        city: 'Tahiti',
        offset: '-1000',
        timezone: 'Pacific/Tahiti',
      },
    ],
  },
  PG: {
    country: 'Papua New Guinea',
    tzs: [
      {
        city: 'Bougainville',
        offset: '+1100',
        timezone: 'Pacific/Bougainville',
      },
      {
        city: 'Port Moresby',
        offset: '+1000',
        timezone: 'Pacific/Port_Moresby',
      },
    ],
  },
  PH: {
    country: 'Philippines',
    tzs: [
      {
        city: 'Manila',
        offset: '+0800',
        timezone: 'Asia/Manila',
      },
    ],
  },
  PK: {
    country: 'Pakistan',
    tzs: [
      {
        city: 'Karachi',
        offset: '+0500',
        timezone: 'Asia/Karachi',
      },
    ],
  },
  PL: {
    country: 'Poland',
    tzs: [
      {
        city: 'Warsaw',
        offset: '+0200',
        timezone: 'Europe/Warsaw',
      },
    ],
  },
  PM: {
    country: 'Saint Pierre and Miquelon',
    tzs: [
      {
        city: 'Miquelon',
        offset: '-0200',
        timezone: 'America/Miquelon',
      },
    ],
  },
  PN: {
    country: 'Pitcairn',
    tzs: [
      {
        city: 'Pitcairn',
        offset: '-0800',
        timezone: 'Pacific/Pitcairn',
      },
    ],
  },
  PR: {
    country: 'Puerto Rico',
    tzs: [
      {
        city: 'Puerto Rico',
        offset: '-0400',
        timezone: 'America/Puerto_Rico',
      },
    ],
  },
  PS: {
    country: 'Palestine, State of',
    tzs: [
      {
        city: 'Gaza',
        offset: '+0300',
        timezone: 'Asia/Gaza',
      },
      {
        city: 'Hebron',
        offset: '+0300',
        timezone: 'Asia/Hebron',
      },
    ],
  },
  PT: {
    country: 'Portugal',
    tzs: [
      {
        city: 'Azores',
        offset: '+0000',
        timezone: 'Atlantic/Azores',
      },
      {
        city: 'Madeira',
        offset: '+0100',
        timezone: 'Atlantic/Madeira',
      },
      {
        city: 'Lisbon',
        offset: '+0100',
        timezone: 'Europe/Lisbon',
      },
    ],
  },
  PW: {
    country: 'Palau',
    tzs: [
      {
        city: 'Palau',
        offset: '+0900',
        timezone: 'Pacific/Palau',
      },
    ],
  },
  PY: {
    country: 'Paraguay',
    tzs: [
      {
        city: 'Asuncion',
        offset: '-0300',
        timezone: 'America/Asuncion',
      },
    ],
  },
  QA: {
    country: 'Qatar',
    tzs: [
      {
        city: 'Qatar',
        offset: '+0300',
        timezone: 'Asia/Qatar',
      },
    ],
  },
  RE: {
    country: 'Réunion',
    tzs: [
      {
        city: 'Reunion',
        offset: '+0400',
        timezone: 'Indian/Reunion',
      },
    ],
  },
  RO: {
    country: 'Romania',
    tzs: [
      {
        city: 'Bucharest',
        offset: '+0300',
        timezone: 'Europe/Bucharest',
      },
    ],
  },
  RS: {
    country: 'Serbia',
    tzs: [
      {
        city: 'Belgrade',
        offset: '+0200',
        timezone: 'Europe/Belgrade',
      },
    ],
  },
  RU: {
    country: 'Russian Federation',
    tzs: [
      {
        city: 'Anadyr',
        offset: '+1200',
        timezone: 'Asia/Anadyr',
      },
      {
        city: 'Barnaul',
        offset: '+0700',
        timezone: 'Asia/Barnaul',
      },
      {
        city: 'Chita',
        offset: '+0900',
        timezone: 'Asia/Chita',
      },
      {
        city: 'Irkutsk',
        offset: '+0800',
        timezone: 'Asia/Irkutsk',
      },
      {
        city: 'Kamchatka',
        offset: '+1200',
        timezone: 'Asia/Kamchatka',
      },
      {
        city: 'Khandyga',
        offset: '+0900',
        timezone: 'Asia/Khandyga',
      },
      {
        city: 'Krasnoyarsk',
        offset: '+0700',
        timezone: 'Asia/Krasnoyarsk',
      },
      {
        city: 'Magadan',
        offset: '+1100',
        timezone: 'Asia/Magadan',
      },
      {
        city: 'Novokuznetsk',
        offset: '+0700',
        timezone: 'Asia/Novokuznetsk',
      },
      {
        city: 'Novosibirsk',
        offset: '+0700',
        timezone: 'Asia/Novosibirsk',
      },
      {
        city: 'Omsk',
        offset: '+0600',
        timezone: 'Asia/Omsk',
      },
      {
        city: 'Sakhalin',
        offset: '+1100',
        timezone: 'Asia/Sakhalin',
      },
      {
        city: 'Srednekolymsk',
        offset: '+1100',
        timezone: 'Asia/Srednekolymsk',
      },
      {
        city: 'Tomsk',
        offset: '+0700',
        timezone: 'Asia/Tomsk',
      },
      {
        city: 'Ust-Nera',
        offset: '+1000',
        timezone: 'Asia/Ust-Nera',
      },
      {
        city: 'Vladivostok',
        offset: '+1000',
        timezone: 'Asia/Vladivostok',
      },
      {
        city: 'Yakutsk',
        offset: '+0900',
        timezone: 'Asia/Yakutsk',
      },
      {
        city: 'Yekaterinburg',
        offset: '+0500',
        timezone: 'Asia/Yekaterinburg',
      },
      {
        city: 'Astrakhan',
        offset: '+0400',
        timezone: 'Europe/Astrakhan',
      },
      {
        city: 'Kaliningrad',
        offset: '+0200',
        timezone: 'Europe/Kaliningrad',
      },
      {
        city: 'Kirov',
        offset: '+0300',
        timezone: 'Europe/Kirov',
      },
      {
        city: 'Moscow',
        offset: '+0300',
        timezone: 'Europe/Moscow',
      },
      {
        city: 'Samara',
        offset: '+0400',
        timezone: 'Europe/Samara',
      },
      {
        city: 'Saratov',
        offset: '+0400',
        timezone: 'Europe/Saratov',
      },
      {
        city: 'Ulyanovsk',
        offset: '+0400',
        timezone: 'Europe/Ulyanovsk',
      },
      {
        city: 'Volgograd',
        offset: '+0300',
        timezone: 'Europe/Volgograd',
      },
    ],
  },
  RW: {
    country: 'Rwanda',
    tzs: [
      {
        city: 'Kigali',
        offset: '+0200',
        timezone: 'Africa/Kigali',
      },
    ],
  },
  SA: {
    country: 'Saudi Arabia',
    tzs: [
      {
        city: 'Riyadh',
        offset: '+0300',
        timezone: 'Asia/Riyadh',
      },
    ],
  },
  SB: {
    country: 'Solomon Islands',
    tzs: [
      {
        city: 'Guadalcanal',
        offset: '+1100',
        timezone: 'Pacific/Guadalcanal',
      },
    ],
  },
  SC: {
    country: 'Seychelles',
    tzs: [
      {
        city: 'Mahe',
        offset: '+0400',
        timezone: 'Indian/Mahe',
      },
    ],
  },
  SD: {
    country: 'Sudan',
    tzs: [
      {
        city: 'Khartoum',
        offset: '+0200',
        timezone: 'Africa/Khartoum',
      },
    ],
  },
  SE: {
    country: 'Sweden',
    tzs: [
      {
        city: 'Stockholm',
        offset: '+0200',
        timezone: 'Europe/Stockholm',
      },
    ],
  },
  SG: {
    country: 'Singapore',
    tzs: [
      {
        city: 'Singapore',
        offset: '+0800',
        timezone: 'Asia/Singapore',
      },
    ],
  },
  SH: {
    country: 'Saint Helena, Ascension and Tristan da Cunha',
    tzs: [
      {
        city: 'St Helena',
        offset: '+0000',
        timezone: 'Atlantic/St_Helena',
      },
    ],
  },
  SI: {
    country: 'Slovenia',
    tzs: [
      {
        city: 'Ljubljana',
        offset: '+0200',
        timezone: 'Europe/Ljubljana',
      },
    ],
  },
  SJ: {
    country: 'Svalbard and Jan Mayen',
    tzs: [
      {
        city: 'Longyearbyen',
        offset: '+0200',
        timezone: 'Arctic/Longyearbyen',
      },
    ],
  },
  SK: {
    country: 'Slovakia',
    tzs: [
      {
        city: 'Bratislava',
        offset: '+0200',
        timezone: 'Europe/Bratislava',
      },
    ],
  },
  SL: {
    country: 'Sierra Leone',
    tzs: [
      {
        city: 'Freetown',
        offset: '+0000',
        timezone: 'Africa/Freetown',
      },
    ],
  },
  SM: {
    country: 'San Marino',
    tzs: [
      {
        city: 'San Marino',
        offset: '+0200',
        timezone: 'Europe/San_Marino',
      },
    ],
  },
  SN: {
    country: 'Senegal',
    tzs: [
      {
        city: 'Dakar',
        offset: '+0000',
        timezone: 'Africa/Dakar',
      },
    ],
  },
  SO: {
    country: 'Somalia',
    tzs: [
      {
        city: 'Mogadishu',
        offset: '+0300',
        timezone: 'Africa/Mogadishu',
      },
    ],
  },
  SR: {
    country: 'Suriname',
    tzs: [
      {
        city: 'Paramaribo',
        offset: '-0300',
        timezone: 'America/Paramaribo',
      },
    ],
  },
  SS: {
    country: 'South Sudan',
    tzs: [
      {
        city: 'Juba',
        offset: '+0200',
        timezone: 'Africa/Juba',
      },
    ],
  },
  ST: {
    country: 'Sao Tome and Principe',
    tzs: [
      {
        city: 'Sao Tome',
        offset: '+0000',
        timezone: 'Africa/Sao_Tome',
      },
    ],
  },
  SV: {
    country: 'El Salvador',
    tzs: [
      {
        city: 'El Salvador',
        offset: '-0600',
        timezone: 'America/El_Salvador',
      },
    ],
  },
  SX: {
    country: 'Sint Maarten (Dutch part)',
    tzs: [
      {
        city: 'Lower Princes',
        offset: '-0400',
        timezone: 'America/Lower_Princes',
      },
    ],
  },
  SY: {
    country: 'Syrian Arab Republic',
    tzs: [
      {
        city: 'Damascus',
        offset: '+0300',
        timezone: 'Asia/Damascus',
      },
    ],
  },
  SZ: {
    country: 'Swaziland',
    tzs: [
      {
        city: 'Mbabane',
        offset: '+0200',
        timezone: 'Africa/Mbabane',
      },
    ],
  },
  TC: {
    country: 'Turks and Caicos Islands',
    tzs: [
      {
        city: 'Grand Turk',
        offset: '-0400',
        timezone: 'America/Grand_Turk',
      },
    ],
  },
  TD: {
    country: 'Chad',
    tzs: [
      {
        city: 'Ndjamena',
        offset: '+0100',
        timezone: 'Africa/Ndjamena',
      },
    ],
  },
  TF: {
    country: 'French Southern Territories',
    tzs: [
      {
        city: 'Kerguelen',
        offset: '+0500',
        timezone: 'Indian/Kerguelen',
      },
    ],
  },
  TG: {
    country: 'Togo',
    tzs: [
      {
        city: 'Lome',
        offset: '+0000',
        timezone: 'Africa/Lome',
      },
    ],
  },
  TH: {
    country: 'Thailand',
    tzs: [
      {
        city: 'Bangkok',
        offset: '+0700',
        timezone: 'Asia/Bangkok',
      },
    ],
  },
  TJ: {
    country: 'Tajikistan',
    tzs: [
      {
        city: 'Dushanbe',
        offset: '+0500',
        timezone: 'Asia/Dushanbe',
      },
    ],
  },
  TK: {
    country: 'Tokelau',
    tzs: [
      {
        city: 'Fakaofo',
        offset: '+1300',
        timezone: 'Pacific/Fakaofo',
      },
    ],
  },
  TL: {
    country: 'Timor-Leste',
    tzs: [
      {
        city: 'Dili',
        offset: '+0900',
        timezone: 'Asia/Dili',
      },
    ],
  },
  TM: {
    country: 'Turkmenistan',
    tzs: [
      {
        city: 'Ashgabat',
        offset: '+0500',
        timezone: 'Asia/Ashgabat',
      },
    ],
  },
  TN: {
    country: 'Tunisia',
    tzs: [
      {
        city: 'Tunis',
        offset: '+0100',
        timezone: 'Africa/Tunis',
      },
    ],
  },
  TO: {
    country: 'Tonga',
    tzs: [
      {
        city: 'Tongatapu',
        offset: '+1300',
        timezone: 'Pacific/Tongatapu',
      },
    ],
  },
  TR: {
    country: 'Turkey',
    tzs: [
      {
        city: 'Istanbul',
        offset: '+0300',
        timezone: 'Europe/Istanbul',
      },
    ],
  },
  TT: {
    country: 'Trinidad and Tobago',
    tzs: [
      {
        city: 'Port of Spain',
        offset: '-0400',
        timezone: 'America/Port_of_Spain',
      },
    ],
  },
  TV: {
    country: 'Tuvalu',
    tzs: [
      {
        city: 'Funafuti',
        offset: '+1200',
        timezone: 'Pacific/Funafuti',
      },
    ],
  },
  TW: {
    country: 'Taiwan, Province of China',
    tzs: [
      {
        city: 'Taipei',
        offset: '+0800',
        timezone: 'Asia/Taipei',
      },
    ],
  },
  TZ: {
    country: 'Tanzania, United Republic of',
    tzs: [
      {
        city: 'Dar es Salaam',
        offset: '+0300',
        timezone: 'Africa/Dar_es_Salaam',
      },
    ],
  },
  UA: {
    country: 'Ukraine',
    tzs: [
      {
        city: 'Kiev',
        offset: '+0300',
        timezone: 'Europe/Kiev',
      },
      {
        city: 'Simferopol',
        offset: '+0300',
        timezone: 'Europe/Simferopol',
      },
      {
        city: 'Uzhgorod',
        offset: '+0300',
        timezone: 'Europe/Uzhgorod',
      },
      {
        city: 'Zaporozhye',
        offset: '+0300',
        timezone: 'Europe/Zaporozhye',
      },
    ],
  },
  UG: {
    country: 'Uganda',
    tzs: [
      {
        city: 'Kampala',
        offset: '+0300',
        timezone: 'Africa/Kampala',
      },
    ],
  },
  UM: {
    country: 'United States Minor Outlying Islands',
    tzs: [
      {
        city: 'Midway',
        offset: '-1100',
        timezone: 'Pacific/Midway',
      },
      {
        city: 'Wake',
        offset: '+1200',
        timezone: 'Pacific/Wake',
      },
    ],
  },
  US: {
    country: 'United States',
    tzs: [
      {
        city: 'Adak',
        offset: '-0900',
        timezone: 'America/Adak',
      },
      {
        city: 'Anchorage',
        offset: '-0800',
        timezone: 'America/Anchorage',
      },
      {
        city: 'Boise',
        offset: '-0600',
        timezone: 'America/Boise',
      },
      {
        city: 'Chicago',
        offset: '-0500',
        timezone: 'America/Chicago',
      },
      {
        city: 'Denver',
        offset: '-0600',
        timezone: 'America/Denver',
      },
      {
        city: 'Detroit',
        offset: '-0400',
        timezone: 'America/Detroit',
      },
      {
        city: 'Indianapolis',
        offset: '-0400',
        timezone: 'America/Indiana/Indianapolis',
      },
      {
        city: 'Knox',
        offset: '-0500',
        timezone: 'America/Indiana/Knox',
      },
      {
        city: 'Marengo',
        offset: '-0400',
        timezone: 'America/Indiana/Marengo',
      },
      {
        city: 'Petersburg',
        offset: '-0400',
        timezone: 'America/Indiana/Petersburg',
      },
      {
        city: 'Tell City',
        offset: '-0500',
        timezone: 'America/Indiana/Tell_City',
      },
      {
        city: 'Vevay',
        offset: '-0400',
        timezone: 'America/Indiana/Vevay',
      },
      {
        city: 'Vincennes',
        offset: '-0400',
        timezone: 'America/Indiana/Vincennes',
      },
      {
        city: 'Winamac',
        offset: '-0400',
        timezone: 'America/Indiana/Winamac',
      },
      {
        city: 'Juneau',
        offset: '-0800',
        timezone: 'America/Juneau',
      },
      {
        city: 'Louisville',
        offset: '-0400',
        timezone: 'America/Kentucky/Louisville',
      },
      {
        city: 'Monticello',
        offset: '-0400',
        timezone: 'America/Kentucky/Monticello',
      },
      {
        city: 'Los Angeles',
        offset: '-0700',
        timezone: 'America/Los_Angeles',
      },
      {
        city: 'Menominee',
        offset: '-0500',
        timezone: 'America/Menominee',
      },
      {
        city: 'Metlakatla',
        offset: '-0800',
        timezone: 'America/Metlakatla',
      },
      {
        city: 'New York',
        offset: '-0400',
        timezone: 'America/New_York',
      },
      {
        city: 'Nome',
        offset: '-0800',
        timezone: 'America/Nome',
      },
      {
        city: 'Beulah',
        offset: '-0500',
        timezone: 'America/North_Dakota/Beulah',
      },
      {
        city: 'Center',
        offset: '-0500',
        timezone: 'America/North_Dakota/Center',
      },
      {
        city: 'New Salem',
        offset: '-0500',
        timezone: 'America/North_Dakota/New_Salem',
      },
      {
        city: 'Phoenix',
        offset: '-0700',
        timezone: 'America/Phoenix',
      },
      {
        city: 'Sitka',
        offset: '-0800',
        timezone: 'America/Sitka',
      },
      {
        city: 'Yakutat',
        offset: '-0800',
        timezone: 'America/Yakutat',
      },
      {
        city: 'Honolulu',
        offset: '-1000',
        timezone: 'Pacific/Honolulu',
      },
    ],
  },
  UY: {
    country: 'Uruguay',
    tzs: [
      {
        city: 'Montevideo',
        offset: '-0300',
        timezone: 'America/Montevideo',
      },
    ],
  },
  UZ: {
    country: 'Uzbekistan',
    tzs: [
      {
        city: 'Samarkand',
        offset: '+0500',
        timezone: 'Asia/Samarkand',
      },
      {
        city: 'Tashkent',
        offset: '+0500',
        timezone: 'Asia/Tashkent',
      },
    ],
  },
  VA: {
    country: 'Holy See (Vatican City State)',
    tzs: [
      {
        city: 'Vatican',
        offset: '+0200',
        timezone: 'Europe/Vatican',
      },
    ],
  },
  VC: {
    country: 'Saint Vincent and the Grenadines',
    tzs: [
      {
        city: 'St Vincent',
        offset: '-0400',
        timezone: 'America/St_Vincent',
      },
    ],
  },
  VE: {
    country: 'Venezuela, Bolivarian Republic of',
    tzs: [
      {
        city: 'Caracas',
        offset: '-0400',
        timezone: 'America/Caracas',
      },
    ],
  },
  VG: {
    country: 'Virgin Islands, British',
    tzs: [
      {
        city: 'Tortola',
        offset: '-0400',
        timezone: 'America/Tortola',
      },
    ],
  },
  VI: {
    country: 'Virgin Islands, U.S.',
    tzs: [
      {
        city: 'St Thomas',
        offset: '-0400',
        timezone: 'America/St_Thomas',
      },
    ],
  },
  VN: {
    country: 'Viet Nam',
    tzs: [
      {
        city: 'Ho Chi Minh',
        offset: '+0700',
        timezone: 'Asia/Ho_Chi_Minh',
      },
    ],
  },
  VU: {
    country: 'Vanuatu',
    tzs: [
      {
        city: 'Efate',
        offset: '+1100',
        timezone: 'Pacific/Efate',
      },
    ],
  },
  WF: {
    country: 'Wallis and Futuna',
    tzs: [
      {
        city: 'Wallis',
        offset: '+1200',
        timezone: 'Pacific/Wallis',
      },
    ],
  },
  WS: {
    country: 'Samoa',
    tzs: [
      {
        city: 'Apia',
        offset: '+1300',
        timezone: 'Pacific/Apia',
      },
    ],
  },
  YE: {
    country: 'Yemen',
    tzs: [
      {
        city: 'Aden',
        offset: '+0300',
        timezone: 'Asia/Aden',
      },
    ],
  },
  YT: {
    country: 'Mayotte',
    tzs: [
      {
        city: 'Mayotte',
        offset: '+0300',
        timezone: 'Indian/Mayotte',
      },
    ],
  },
  ZA: {
    country: 'South Africa',
    tzs: [
      {
        city: 'Johannesburg',
        offset: '+0200',
        timezone: 'Africa/Johannesburg',
      },
    ],
  },
  ZM: {
    country: 'Zambia',
    tzs: [
      {
        city: 'Lusaka',
        offset: '+0200',
        timezone: 'Africa/Lusaka',
      },
    ],
  },
  ZW: {
    country: 'Zimbabwe',
    tzs: [
      {
        city: 'Harare',
        offset: '+0200',
        timezone: 'Africa/Harare',
      },
    ],
  },
}
export const { voices } = {
  voices: [
    {
      id: 'af-ZA1',
      language: 'af-ZA',
      name: 'Afrikaans (South Africa) 1',
      gender: 'f',
    },
    {
      id: 'ar-XA1',
      language: 'ar-XA',
      name: 'Arabic 1',
      gender: 'f',
    },
    {
      id: 'ar-XA2',
      language: 'ar-XA',
      name: 'Arabic 2',
      gender: 'm',
    },
    {
      id: 'ar-XA3',
      language: 'ar-XA',
      name: 'Arabic 3',
      gender: 'm',
    },
    {
      id: 'ar-XA4',
      language: 'ar-XA',
      name: 'Arabic 4',
      gender: 'f',
    },
    {
      id: 'bg-BG1',
      language: 'bg-BG',
      name: 'Bulgarian (Bulgaria) 1',
      gender: 'f',
    },
    {
      id: 'bn-IN1',
      language: 'bn-IN',
      name: 'Bengali (India) 1',
      gender: 'f',
    },
    {
      id: 'bn-IN2',
      language: 'bn-IN',
      name: 'Bengali (India) 2',
      gender: 'm',
    },
    {
      id: 'ca-ES1',
      language: 'ca-ES',
      name: 'Catalan (Spain) 1',
      gender: 'f',
    },
    {
      id: 'cmn-CN1',
      language: 'cmn-CN',
      name: 'Mandarin Chinese (China) 1',
      gender: 'f',
    },
    {
      id: 'cmn-CN2',
      language: 'cmn-CN',
      name: 'Mandarin Chinese (China) 2',
      gender: 'm',
    },
    {
      id: 'cmn-CN3',
      language: 'cmn-CN',
      name: 'Mandarin Chinese (China) 3',
      gender: 'm',
    },
    {
      id: 'cmn-CN4',
      language: 'cmn-CN',
      name: 'Mandarin Chinese (China) 4',
      gender: 'f',
    },
    {
      id: 'cmn-TW1',
      language: 'cmn-TW',
      name: 'Mandarin Chinese (Taiwan) 1',
      gender: 'f',
    },
    {
      id: 'cmn-TW2',
      language: 'cmn-TW',
      name: 'Mandarin Chinese (Taiwan) 2',
      gender: 'm',
    },
    {
      id: 'cmn-TW3',
      language: 'cmn-TW',
      name: 'Mandarin Chinese (Taiwan) 3',
      gender: 'm',
    },
    {
      id: 'cs-CZ1',
      language: 'cs-CZ',
      name: 'Czech (Czech Republic) 1',
      gender: 'f',
    },
    {
      id: 'da-DK1',
      language: 'da-DK',
      name: 'Danish (Denmark) 1',
      gender: 'm',
    },
    {
      id: 'da-DK2',
      language: 'da-DK',
      name: 'Danish (Denmark) 2',
      gender: 'f',
    },
    {
      id: 'da-DK3',
      language: 'da-DK',
      name: 'Danish (Denmark) 3',
      gender: 'f',
    },
    {
      id: 'da-DK4',
      language: 'da-DK',
      name: 'Danish (Denmark) 4',
      gender: 'f',
    },
    {
      id: 'de-DE1',
      language: 'de-DE',
      name: 'German (Germany) 1',
      gender: 'f',
    },
    {
      id: 'de-DE2',
      language: 'de-DE',
      name: 'German (Germany) 2',
      gender: 'f',
    },
    {
      id: 'de-DE3',
      language: 'de-DE',
      name: 'German (Germany) 3',
      gender: 'm',
    },
    {
      id: 'de-DE4',
      language: 'de-DE',
      name: 'German (Germany) 4',
      gender: 'f',
    },
    {
      id: 'de-DE5',
      language: 'de-DE',
      name: 'German (Germany) 5',
      gender: 'm',
    },
    {
      id: 'de-DE6',
      language: 'de-DE',
      name: 'German (Germany) 6',
      gender: 'm',
    },
    {
      id: 'el-GR1',
      language: 'el-GR',
      name: 'Greek (Greece) 1',
      gender: 'f',
    },
    {
      id: 'en-AU1',
      language: 'en-AU',
      name: 'English (Australia) 1',
      gender: 'f',
    },
    {
      id: 'en-AU2',
      language: 'en-AU',
      name: 'English (Australia) 2',
      gender: 'm',
    },
    {
      id: 'en-AU3',
      language: 'en-AU',
      name: 'English (Australia) 3',
      gender: 'f',
    },
    {
      id: 'en-AU4',
      language: 'en-AU',
      name: 'English (Australia) 4',
      gender: 'm',
    },
    {
      id: 'en-GB1',
      language: 'en-GB',
      name: 'English (UK) 1',
      gender: 'f',
    },
    {
      id: 'en-GB2',
      language: 'en-GB',
      name: 'English (UK) 2',
      gender: 'm',
    },
    {
      id: 'en-GB3',
      language: 'en-GB',
      name: 'English (UK) 3',
      gender: 'f',
    },
    {
      id: 'en-GB4',
      language: 'en-GB',
      name: 'English (UK) 4',
      gender: 'm',
    },
    {
      id: 'en-GB5',
      language: 'en-GB',
      name: 'English (UK) 5',
      gender: 'f',
    },
    {
      id: 'en-IN1',
      language: 'en-IN',
      name: 'English (India) 1',
      gender: 'f',
    },
    {
      id: 'en-IN2',
      language: 'en-IN',
      name: 'English (India) 2',
      gender: 'f',
    },
    {
      id: 'en-IN3',
      language: 'en-IN',
      name: 'English (India) 3',
      gender: 'm',
    },
    {
      id: 'en-IN4',
      language: 'en-IN',
      name: 'English (India) 4',
      gender: 'm',
    },
    {
      id: 'en-US1',
      language: 'en-US',
      name: 'English (US) 1',
      gender: 'f',
    },
    {
      id: 'en-US10',
      language: 'en-US',
      name: 'English (US) 10',
      gender: 'f',
    },
    {
      id: 'en-US2',
      language: 'en-US',
      name: 'English (US) 2',
      gender: 'f',
    },
    {
      id: 'en-US3',
      language: 'en-US',
      name: 'English (US) 3',
      gender: 'm',
    },
    {
      id: 'en-US4',
      language: 'en-US',
      name: 'English (US) 4',
      gender: 'm',
    },
    {
      id: 'en-US5',
      language: 'en-US',
      name: 'English (US) 5',
      gender: 'm',
    },
    {
      id: 'en-US6',
      language: 'en-US',
      name: 'English (US) 6',
      gender: 'm',
    },
    {
      id: 'en-US7',
      language: 'en-US',
      name: 'English (US) 7',
      gender: 'f',
    },
    {
      id: 'en-US8',
      language: 'en-US',
      name: 'English (US) 8',
      gender: 'm',
    },
    {
      id: 'en-US9',
      language: 'en-US',
      name: 'English (US) 9',
      gender: 'f',
    },
    {
      id: 'es-ES1',
      language: 'es-ES',
      name: 'Spanish (Spain) 1',
      gender: 'f',
    },
    {
      id: 'es-ES2',
      language: 'es-ES',
      name: 'Spanish (Spain) 2',
      gender: 'f',
    },
    {
      id: 'es-ES3',
      language: 'es-ES',
      name: 'Spanish (Spain) 3',
      gender: 'm',
    },
    {
      id: 'es-US1',
      language: 'es-US',
      name: 'Spanish (US) 1',
      gender: 'f',
    },
    {
      id: 'es-US2',
      language: 'es-US',
      name: 'Spanish (US) 2',
      gender: 'm',
    },
    {
      id: 'es-US3',
      language: 'es-US',
      name: 'Spanish (US) 3',
      gender: 'm',
    },
    {
      id: 'fi-FI1',
      language: 'fi-FI',
      name: 'Finnish (Finland) 1',
      gender: 'f',
    },
    {
      id: 'fil-PH1',
      language: 'fil-PH',
      name: 'Filipino (Philippines) 1',
      gender: 'f',
    },
    {
      id: 'fil-PH2',
      language: 'fil-PH',
      name: 'Filipino (Philippines) 2',
      gender: 'f',
    },
    {
      id: 'fil-PH3',
      language: 'fil-PH',
      name: 'Filipino (Philippines) 3',
      gender: 'm',
    },
    {
      id: 'fil-PH4',
      language: 'fil-PH',
      name: 'Filipino (Philippines) 4',
      gender: 'm',
    },
    {
      id: 'fr-CA1',
      language: 'fr-CA',
      name: 'French (Canada) 1',
      gender: 'f',
    },
    {
      id: 'fr-CA2',
      language: 'fr-CA',
      name: 'French (Canada) 2',
      gender: 'm',
    },
    {
      id: 'fr-CA3',
      language: 'fr-CA',
      name: 'French (Canada) 3',
      gender: 'f',
    },
    {
      id: 'fr-CA4',
      language: 'fr-CA',
      name: 'French (Canada) 4',
      gender: 'm',
    },
    {
      id: 'fr-FR1',
      language: 'fr-FR',
      name: 'French (France) 1',
      gender: 'f',
    },
    {
      id: 'fr-FR2',
      language: 'fr-FR',
      name: 'French (France) 2',
      gender: 'f',
    },
    {
      id: 'fr-FR3',
      language: 'fr-FR',
      name: 'French (France) 3',
      gender: 'm',
    },
    {
      id: 'fr-FR4',
      language: 'fr-FR',
      name: 'French (France) 4',
      gender: 'f',
    },
    {
      id: 'fr-FR5',
      language: 'fr-FR',
      name: 'French (France) 5',
      gender: 'm',
    },
    {
      id: 'gu-IN1',
      language: 'gu-IN',
      name: 'Gujarati (India) 1',
      gender: 'f',
    },
    {
      id: 'gu-IN2',
      language: 'gu-IN',
      name: 'Gujarati (India) 2',
      gender: 'm',
    },
    {
      id: 'hi-IN1',
      language: 'hi-IN',
      name: 'Hindi (India) 1',
      gender: 'f',
    },
    {
      id: 'hi-IN2',
      language: 'hi-IN',
      name: 'Hindi (India) 2',
      gender: 'f',
    },
    {
      id: 'hi-IN3',
      language: 'hi-IN',
      name: 'Hindi (India) 3',
      gender: 'm',
    },
    {
      id: 'hi-IN4',
      language: 'hi-IN',
      name: 'Hindi (India) 4',
      gender: 'm',
    },
    {
      id: 'hu-HU1',
      language: 'hu-HU',
      name: 'Hungarian (Hungary) 1',
      gender: 'f',
    },
    {
      id: 'id-ID1',
      language: 'id-ID',
      name: 'Indonesian (Indonesia) 1',
      gender: 'f',
    },
    {
      id: 'id-ID2',
      language: 'id-ID',
      name: 'Indonesian (Indonesia) 2',
      gender: 'f',
    },
    {
      id: 'id-ID3',
      language: 'id-ID',
      name: 'Indonesian (Indonesia) 3',
      gender: 'm',
    },
    {
      id: 'id-ID4',
      language: 'id-ID',
      name: 'Indonesian (Indonesia) 4',
      gender: 'm',
    },
    {
      id: 'is-IS1',
      language: 'is-IS',
      name: 'Icelandic (Iceland) 1',
      gender: 'f',
    },
    {
      id: 'it-IT1',
      language: 'it-IT',
      name: 'Italian (Italy) 1',
      gender: 'f',
    },
    {
      id: 'it-IT2',
      language: 'it-IT',
      name: 'Italian (Italy) 2',
      gender: 'f',
    },
    {
      id: 'it-IT3',
      language: 'it-IT',
      name: 'Italian (Italy) 3',
      gender: 'm',
    },
    {
      id: 'it-IT4',
      language: 'it-IT',
      name: 'Italian (Italy) 4',
      gender: 'm',
    },
    {
      id: 'ja-JP1',
      language: 'ja-JP',
      name: 'Japanese (Japan) 1',
      gender: 'f',
    },
    {
      id: 'ja-JP2',
      language: 'ja-JP',
      name: 'Japanese (Japan) 2',
      gender: 'm',
    },
    {
      id: 'ja-JP3',
      language: 'ja-JP',
      name: 'Japanese (Japan) 3',
      gender: 'm',
    },
    {
      id: 'ja-JP4',
      language: 'ja-JP',
      name: 'Japanese (Japan) 4',
      gender: 'f',
    },
    {
      id: 'kn-IN1',
      language: 'kn-IN',
      name: 'Kannada (India) 1',
      gender: 'f',
    },
    {
      id: 'kn-IN2',
      language: 'kn-IN',
      name: 'Kannada (India) 2',
      gender: 'm',
    },
    {
      id: 'ko-KR1',
      language: 'ko-KR',
      name: 'Korean (South Korea) 1',
      gender: 'f',
    },
    {
      id: 'ko-KR2',
      language: 'ko-KR',
      name: 'Korean (South Korea) 2',
      gender: 'f',
    },
    {
      id: 'ko-KR3',
      language: 'ko-KR',
      name: 'Korean (South Korea) 3',
      gender: 'm',
    },
    {
      id: 'ko-KR4',
      language: 'ko-KR',
      name: 'Korean (South Korea) 4',
      gender: 'm',
    },
    {
      id: 'lv-LV1',
      language: 'lv-LV',
      name: 'Latvian (Latvia) 1',
      gender: 'm',
    },
    {
      id: 'ml-IN1',
      language: 'ml-IN',
      name: 'Malayalam (India) 1',
      gender: 'f',
    },
    {
      id: 'ml-IN2',
      language: 'ml-IN',
      name: 'Malayalam (India) 2',
      gender: 'm',
    },
    {
      id: 'ms-MY1',
      language: 'ms-MY',
      name: 'Malay (Malaysia) 1',
      gender: 'f',
    },
    {
      id: 'ms-MY2',
      language: 'ms-MY',
      name: 'Malay (Malaysia) 2',
      gender: 'm',
    },
    {
      id: 'ms-MY3',
      language: 'ms-MY',
      name: 'Malay (Malaysia) 3',
      gender: 'f',
    },
    {
      id: 'ms-MY4',
      language: 'ms-MY',
      name: 'Malay (Malaysia) 4',
      gender: 'm',
    },
    {
      id: 'nb-NO1',
      language: 'nb-NO',
      name: 'Norwegian (Norway) 1',
      gender: 'f',
    },
    {
      id: 'nb-NO2',
      language: 'nb-NO',
      name: 'Norwegian (Norway) 2',
      gender: 'm',
    },
    {
      id: 'nb-NO3',
      language: 'nb-NO',
      name: 'Norwegian (Norway) 3',
      gender: 'f',
    },
    {
      id: 'nb-NO4',
      language: 'nb-NO',
      name: 'Norwegian (Norway) 4',
      gender: 'm',
    },
    {
      id: 'nb-NO5',
      language: 'nb-NO',
      name: 'Norwegian (Norway) 5',
      gender: 'f',
    },
    {
      id: 'nl-BE1',
      language: 'nl-BE',
      name: 'Dutch (Belgium) 1',
      gender: 'f',
    },
    {
      id: 'nl-BE2',
      language: 'nl-BE',
      name: 'Dutch (Belgium) 2',
      gender: 'm',
    },
    {
      id: 'nl-NL1',
      language: 'nl-NL',
      name: 'Dutch (Netherlands) 1',
      gender: 'm',
    },
    {
      id: 'nl-NL2',
      language: 'nl-NL',
      name: 'Dutch (Netherlands) 2',
      gender: 'm',
    },
    {
      id: 'nl-NL3',
      language: 'nl-NL',
      name: 'Dutch (Netherlands) 3',
      gender: 'f',
    },
    {
      id: 'nl-NL4',
      language: 'nl-NL',
      name: 'Dutch (Netherlands) 4',
      gender: 'f',
    },
    {
      id: 'nl-NL5',
      language: 'nl-NL',
      name: 'Dutch (Netherlands) 5',
      gender: 'f',
    },
    {
      id: 'pa-IN1',
      language: 'pa-IN',
      name: 'Punjabi (India) 1',
      gender: 'f',
    },
    {
      id: 'pa-IN2',
      language: 'pa-IN',
      name: 'Punjabi (India) 2',
      gender: 'm',
    },
    {
      id: 'pa-IN3',
      language: 'pa-IN',
      name: 'Punjabi (India) 3',
      gender: 'f',
    },
    {
      id: 'pa-IN4',
      language: 'pa-IN',
      name: 'Punjabi (India) 4',
      gender: 'm',
    },
    {
      id: 'pl-PL1',
      language: 'pl-PL',
      name: 'Polish (Poland) 1',
      gender: 'f',
    },
    {
      id: 'pl-PL2',
      language: 'pl-PL',
      name: 'Polish (Poland) 2',
      gender: 'm',
    },
    {
      id: 'pl-PL3',
      language: 'pl-PL',
      name: 'Polish (Poland) 3',
      gender: 'm',
    },
    {
      id: 'pl-PL4',
      language: 'pl-PL',
      name: 'Polish (Poland) 4',
      gender: 'f',
    },
    {
      id: 'pl-PL5',
      language: 'pl-PL',
      name: 'Polish (Poland) 5',
      gender: 'f',
    },
    {
      id: 'pt-BR1',
      language: 'pt-BR',
      name: 'Portuguese (Brazil) 1',
      gender: 'f',
    },
    {
      id: 'pt-BR2',
      language: 'pt-BR',
      name: 'Portuguese (Brazil) 2',
      gender: 'm',
    },
    {
      id: 'pt-PT1',
      language: 'pt-PT',
      name: 'Portuguese (Portugal) 1',
      gender: 'f',
    },
    {
      id: 'pt-PT2',
      language: 'pt-PT',
      name: 'Portuguese (Portugal) 2',
      gender: 'm',
    },
    {
      id: 'pt-PT3',
      language: 'pt-PT',
      name: 'Portuguese (Portugal) 3',
      gender: 'm',
    },
    {
      id: 'pt-PT4',
      language: 'pt-PT',
      name: 'Portuguese (Portugal) 4',
      gender: 'f',
    },
    {
      id: 'ro-RO1',
      language: 'ro-RO',
      name: 'Romanian (Romania) 1',
      gender: 'f',
    },
    {
      id: 'ru-RU1',
      language: 'ru-RU',
      name: 'Russian (Russia) 1',
      gender: 'f',
    },
    {
      id: 'ru-RU2',
      language: 'ru-RU',
      name: 'Russian (Russia) 2',
      gender: 'f',
    },
    {
      id: 'ru-RU3',
      language: 'ru-RU',
      name: 'Russian (Russia) 3',
      gender: 'm',
    },
    {
      id: 'ru-RU4',
      language: 'ru-RU',
      name: 'Russian (Russia) 4',
      gender: 'f',
    },
    {
      id: 'ru-RU5',
      language: 'ru-RU',
      name: 'Russian (Russia) 5',
      gender: 'm',
    },
    {
      id: 'sk-SK1',
      language: 'sk-SK',
      name: 'Slovak (Slovakia) 1',
      gender: 'f',
    },
    {
      id: 'sr-RS1',
      language: 'sr-RS',
      name: 'Serbian (Cyrillic) 1',
      gender: 'f',
    },
    {
      id: 'sv-SE1',
      language: 'sv-SE',
      name: 'Swedish (Sweden) 1',
      gender: 'f',
    },
    {
      id: 'sv-SE2',
      language: 'sv-SE',
      name: 'Swedish (Sweden) 2',
      gender: 'f',
    },
    {
      id: 'sv-SE3',
      language: 'sv-SE',
      name: 'Swedish (Sweden) 3',
      gender: 'm',
    },
    {
      id: 'sv-SE4',
      language: 'sv-SE',
      name: 'Swedish (Sweden) 4',
      gender: 'f',
    },
    {
      id: 'sv-SE5',
      language: 'sv-SE',
      name: 'Swedish (Sweden) 5',
      gender: 'm',
    },
    {
      id: 'ta-IN1',
      language: 'ta-IN',
      name: 'Tamil (India) 1',
      gender: 'f',
    },
    {
      id: 'ta-IN2',
      language: 'ta-IN',
      name: 'Tamil (India) 2',
      gender: 'm',
    },
    {
      id: 'te-IN1',
      language: 'te-IN',
      name: 'Telugu (India) 1',
      gender: 'f',
    },
    {
      id: 'te-IN2',
      language: 'te-IN',
      name: 'Telugu (India) 2',
      gender: 'm',
    },
    {
      id: 'th-TH1',
      language: 'th-TH',
      name: 'Thai (Thailand) 1',
      gender: 'f',
    },
    {
      id: 'tr-TR1',
      language: 'tr-TR',
      name: 'Turkish (Turkey) 1',
      gender: 'm',
    },
    {
      id: 'tr-TR2',
      language: 'tr-TR',
      name: 'Turkish (Turkey) 2',
      gender: 'f',
    },
    {
      id: 'tr-TR3',
      language: 'tr-TR',
      name: 'Turkish (Turkey) 3',
      gender: 'f',
    },
    {
      id: 'tr-TR4',
      language: 'tr-TR',
      name: 'Turkish (Turkey) 4',
      gender: 'm',
    },
    {
      id: 'tr-TR5',
      language: 'tr-TR',
      name: 'Turkish (Turkey) 5',
      gender: 'f',
    },
    {
      id: 'uk-UA1',
      language: 'uk-UA',
      name: 'Ukrainian (Ukraine) 1',
      gender: 'f',
    },
    {
      id: 'vi-VN1',
      language: 'vi-VN',
      name: 'Vietnamese (Vietnam) 1',
      gender: 'f',
    },
    {
      id: 'vi-VN2',
      language: 'vi-VN',
      name: 'Vietnamese (Vietnam) 2',
      gender: 'm',
    },
    {
      id: 'vi-VN3',
      language: 'vi-VN',
      name: 'Vietnamese (Vietnam) 3',
      gender: 'f',
    },
    {
      id: 'vi-VN4',
      language: 'vi-VN',
      name: 'Vietnamese (Vietnam) 4',
      gender: 'm',
    },
    {
      id: 'yue-HK1',
      language: 'yue-HK',
      name: 'Chinese (Hong Kong) 1',
      gender: 'f',
    },
    {
      id: 'yue-HK2',
      language: 'yue-HK',
      name: 'Chinese (Hong Kong) 2',
      gender: 'm',
    },
    {
      id: 'yue-HK3',
      language: 'yue-HK',
      name: 'Chinese (Hong Kong) 3',
      gender: 'f',
    },
    {
      id: 'yue-HK4',
      language: 'yue-HK',
      name: 'Chinese (Hong Kong) 4',
      gender: 'm',
    },
  ],
}
export const { languages: transcribeLangs } = {
  languages: [
    {
      name: 'Afrikaans (South Africa)',
      speechRecognition: 'af-ZA',
      transcribe: 'af-ZA',
    },
    {
      name: 'Albanian (Albania)',
      speechRecognition: 'sq-AL',
    },
    {
      name: 'Amharic (Ethiopia)',
      speechRecognition: 'am-ET',
    },
    {
      name: 'Arabic (Algeria)',
      speechRecognition: 'ar-DZ',
      transcribe: 'ar-DZ',
    },
    {
      name: 'Arabic (Bahrain)',
      speechRecognition: 'ar-BH',
      transcribe: 'ar-BH',
    },
    {
      name: 'Arabic (Egypt)',
      speechRecognition: 'ar-EG',
      transcribe: 'ar-EG',
    },
    {
      name: 'Arabic (Iraq)',
      speechRecognition: 'ar-IQ',
      transcribe: 'ar-IQ',
    },
    {
      name: 'Arabic (Israel)',
      speechRecognition: 'ar-IL',
      transcribe: 'ar-IL',
    },
    {
      name: 'Arabic (Jordan)',
      speechRecognition: 'ar-JO',
      transcribe: 'ar-JO',
    },
    {
      name: 'Arabic (Kuwait)',
      speechRecognition: 'ar-KW',
      transcribe: 'ar-KW',
    },
    {
      name: 'Arabic (Lebanon)',
      speechRecognition: 'ar-LB',
      transcribe: 'ar-LB',
    },
    {
      name: 'Arabic (Morocco)',
      speechRecognition: 'ar-MA',
      transcribe: 'ar-MA',
    },
    {
      name: 'Arabic (Oman)',
      speechRecognition: 'ar-OM',
      transcribe: 'ar-OM',
    },
    {
      name: 'Arabic (Qatar)',
      speechRecognition: 'ar-QA',
      transcribe: 'ar-QA',
    },
    {
      name: 'Arabic (Saudi Arabia)',
      speechRecognition: 'ar-SA',
      transcribe: 'ar-SA',
    },
    {
      name: 'Arabic (State of Palestine)',
      speechRecognition: 'ar-PS',
      transcribe: 'ar-PS',
    },
    {
      name: 'Arabic (Tunisia)',
      speechRecognition: 'ar-TN',
      transcribe: 'ar-TN',
    },
    {
      name: 'Arabic (United Arab Emirates)',
      speechRecognition: 'ar-AE',
      transcribe: 'ar-AE',
    },
    {
      name: 'Arabic (Yemen)',
      speechRecognition: 'ar-YE',
      transcribe: 'ar-YE',
    },
    {
      name: 'Armenian (Armenia)',
      speechRecognition: 'hy-AM',
      transcribe: 'hy-AM',
    },
    {
      name: 'Azerbaijani (Azerbaijan)',
      speechRecognition: 'az-AZ',
      transcribe: 'az-AZ',
    },
    {
      name: 'Basque (Spain)',
      speechRecognition: 'eu-ES',
    },
    {
      name: 'Bengali (Bangladesh)',
      speechRecognition: 'bn-BD',
    },
    {
      name: 'Bengali (India)',
      speechRecognition: 'bn-IN',
    },
    {
      name: 'Bosnian (Bosnia and Herzegovina)',
      speechRecognition: 'bs-BA',
      transcribe: 'bs-BA',
    },
    {
      name: 'Bulgarian (Bulgaria)',
      speechRecognition: 'bg-BG',
      transcribe: 'bg-BG',
    },
    {
      name: 'Burmese (Myanmar)',
      speechRecognition: 'my-MM',
    },
    {
      name: 'Catalan (Spain)',
      speechRecognition: 'ca-ES',
    },
    {
      name: 'Chinese, Mandarin (Simplified, China)',
      speechRecognition: 'zh-Hans-CN',
      transcribe: 'zh-Hans-CN',
    },
    {
      name: 'Chinese, Mandarin (Traditional, Taiwan)',
      speechRecognition: 'zh-TW',
      transcribe: 'zh-TW',
    },
    {
      name: 'Croatian (Croatia)',
      speechRecognition: 'hr-HR',
      transcribe: 'hr-HR',
    },
    {
      name: 'Czech (Czech Republic)',
      speechRecognition: 'cs-CZ',
      transcribe: 'cs-CZ',
    },
    {
      name: 'Danish (Denmark)',
      speechRecognition: 'da-DK',
      transcribe: 'da-DK',
    },
    {
      name: 'Dutch (Belgium)',
      speechRecognition: 'nl-BE',
      transcribe: 'nl-BE',
    },
    {
      name: 'Dutch (Netherlands)',
      speechRecognition: 'nl-NL',
      transcribe: 'nl-NL',
    },
    {
      name: 'English (Australia)',
      speechRecognition: 'en-AU',
      transcribe: 'en-AU',
    },
    {
      name: 'English (Canada)',
      speechRecognition: 'en-CA',
      transcribe: 'en-CA',
    },
    {
      name: 'English (Ghana)',
      speechRecognition: 'en-GH',
      transcribe: 'en-GH',
    },
    {
      name: 'English (Hong Kong)',
      speechRecognition: 'en-HK',
      transcribe: 'en-HK',
    },
    {
      name: 'English (India)',
      speechRecognition: 'en-IN',
      transcribe: 'en-IN',
    },
    {
      name: 'English (Ireland)',
      speechRecognition: 'en-IE',
      transcribe: 'en-IE',
    },
    {
      name: 'English (Kenya)',
      speechRecognition: 'en-KE',
      transcribe: 'en-KE',
    },
    {
      name: 'English (New Zealand)',
      speechRecognition: 'en-NZ',
      transcribe: 'en-NZ',
    },
    {
      name: 'English (Nigeria)',
      speechRecognition: 'en-NG',
      transcribe: 'en-NG',
    },
    {
      name: 'English (Pakistan)',
      speechRecognition: 'en-PK',
      transcribe: 'en-PK',
    },
    {
      name: 'English (Philippines)',
      speechRecognition: 'en-PH',
      transcribe: 'en-PH',
    },
    {
      name: 'English (Singapore)',
      speechRecognition: 'en-SG',
      transcribe: 'en-SG',
    },
    {
      name: 'English (South Africa)',
      speechRecognition: 'en-ZA',
      transcribe: 'en-ZA',
    },
    {
      name: 'English (Tanzania)',
      speechRecognition: 'en-TZ',
      transcribe: 'en-TZ',
    },
    {
      name: 'English (United Kingdom)',
      speechRecognition: 'en-GB',
      transcribe: 'en-GB',
    },
    {
      name: 'English (United States)',
      speechRecognition: 'en-US',
      transcribe: 'en-US',
    },
    {
      name: 'Estonian (Estonia)',
      speechRecognition: 'et-EE',
      transcribe: 'et-EE',
    },
    {
      name: 'Filipino (Philippines)',
      speechRecognition: 'fil-PH',
    },
    {
      name: 'Finnish (Finland)',
      speechRecognition: 'fi-FI',
      transcribe: 'fi-FI',
    },
    {
      name: 'French (Belgium)',
      speechRecognition: 'fr-BE',
      transcribe: 'fr-BE',
    },
    {
      name: 'French (Canada)',
      speechRecognition: 'fr-CA',
      transcribe: 'fr-CA',
    },
    {
      name: 'French (France)',
      speechRecognition: 'fr-FR',
      transcribe: 'fr-FR',
    },
    {
      name: 'French (Switzerland)',
      speechRecognition: 'fr-CH',
      transcribe: 'fr-CH',
    },
    {
      name: 'Galician (Spain)',
      speechRecognition: 'gl-ES',
      transcribe: 'gl-ES',
    },
    {
      name: 'German (Austria)',
      speechRecognition: 'de-AT',
      transcribe: 'de-AT',
    },
    {
      name: 'German (Germany)',
      speechRecognition: 'de-DE',
      transcribe: 'de-DE',
    },
    {
      name: 'German (Switzerland)',
      speechRecognition: 'de-CH',
      transcribe: 'de-CH',
    },
    {
      name: 'Greek (Greece)',
      speechRecognition: 'el-GR',
      transcribe: 'el-GR',
    },
    {
      name: 'Gujarati (India)',
      speechRecognition: 'gu-IN',
    },
    {
      name: 'Hindi (India)',
      speechRecognition: 'hi-IN',
      transcribe: 'hi-IN',
    },
    {
      name: 'Hungarian (Hungary)',
      speechRecognition: 'hu-HU',
      transcribe: 'hu-HU',
    },
    {
      name: 'Icelandic (Iceland)',
      speechRecognition: 'is-IS',
      transcribe: 'is-IS',
    },
    {
      name: 'Indonesian (Indonesia)',
      speechRecognition: 'id-ID',
      transcribe: 'id-ID',
    },
    {
      name: 'Italian (Italy)',
      speechRecognition: 'it-IT',
      transcribe: 'it-IT',
    },
    {
      name: 'Italian (Switzerland)',
      speechRecognition: 'it-CH',
      transcribe: 'it-CH',
    },
    {
      name: 'Japanese (Japan)',
      speechRecognition: 'ja-JP',
      transcribe: 'ja-JP',
    },
    {
      name: 'Javanese (Indonesia)',
      speechRecognition: 'jv-ID',
      transcribe: 'jv-ID',
    },
    {
      name: 'Kannada (India)',
      speechRecognition: 'kn-IN',
      transcribe: 'kn-IN',
    },
    {
      name: 'Kazakh (Kazakhstan)',
      speechRecognition: 'kk-KZ',
      transcribe: 'kk-KZ',
    },
    {
      name: 'Khmer (Cambodia)',
      speechRecognition: 'km-KH',
    },
    {
      name: 'Korean (South Korea)',
      speechRecognition: 'ko-KR',
      transcribe: 'ko-KR',
    },
    {
      name: 'Lao (Laos)',
      speechRecognition: 'lo-LA',
    },
    {
      name: 'Latvian (Latvia)',
      speechRecognition: 'lv-LV',
      transcribe: 'lv-LV',
    },
    {
      name: 'Lithuanian (Lithuania)',
      speechRecognition: 'lt-LT',
      transcribe: 'lt-LT',
    },
    {
      name: 'Macedonian (North Macedonia)',
      speechRecognition: 'mk-MK',
      transcribe: 'mk-MK',
    },
    {
      name: 'Malay (Malaysia)',
      speechRecognition: 'ms-MY',
      transcribe: 'ms-MY',
    },
    {
      name: 'Malayalam (India)',
      speechRecognition: 'ml-IN',
    },
    {
      name: 'Marathi (India)',
      speechRecognition: 'mr-IN',
      transcribe: 'mr-IN',
    },
    {
      name: 'Mongolian (Mongolia)',
      speechRecognition: 'mn-MN',
    },
    {
      name: 'Nepali (Nepal)',
      speechRecognition: 'ne-NP',
      transcribe: 'ne-NP',
    },
    {
      name: 'Norwegian Bokmål (Norway)',
      speechRecognition: 'no-NO',
      transcribe: 'no-NO',
    },
    {
      name: 'Persian (Iran)',
      speechRecognition: 'fa-IR',
      transcribe: 'fa-IR',
    },
    {
      name: 'Polish (Poland)',
      speechRecognition: 'pl-PL',
      transcribe: 'pl-PL',
    },
    {
      name: 'Portuguese (Brazil)',
      speechRecognition: 'pt-BR',
      transcribe: 'pt-BR',
    },
    {
      name: 'Portuguese (Portugal)',
      speechRecognition: 'pt-PT',
      transcribe: 'pt-PT',
    },
    {
      name: 'Punjabi (Gurmukhi India)',
      speechRecognition: 'pa-Guru-IN',
    },
    {
      name: 'Romanian (Romania)',
      speechRecognition: 'ro-RO',
      transcribe: 'ro-RO',
    },
    {
      name: 'Russian (Russia)',
      speechRecognition: 'ru-RU',
      transcribe: 'ru-RU',
    },
    {
      name: 'Serbian (Serbia)',
      speechRecognition: 'sr-RS',
      transcribe: 'sr-RS',
    },
    {
      name: 'Sinhala (Sri Lanka)',
      speechRecognition: 'si-LK',
    },
    {
      name: 'Slovak (Slovakia)',
      speechRecognition: 'sk-SK',
      transcribe: 'sk-SK',
    },
    {
      name: 'Slovenian (Slovenia)',
      speechRecognition: 'sl-SI',
      transcribe: 'sl-SI',
    },
    {
      name: 'Spanish (Argentina)',
      speechRecognition: 'es-AR',
      transcribe: 'es-AR',
    },
    {
      name: 'Spanish (Bolivia)',
      speechRecognition: 'es-BO',
      transcribe: 'es-BO',
    },
    {
      name: 'Spanish (Chile)',
      speechRecognition: 'es-CL',
      transcribe: 'es-CL',
    },
    {
      name: 'Spanish (Colombia)',
      speechRecognition: 'es-CO',
      transcribe: 'es-CO',
    },
    {
      name: 'Spanish (Costa Rica)',
      speechRecognition: 'es-CR',
      transcribe: 'es-CR',
    },
    {
      name: 'Spanish (Dominican Republic)',
      speechRecognition: 'es-DO',
      transcribe: 'es-DO',
    },
    {
      name: 'Spanish (Ecuador)',
      speechRecognition: 'es-EC',
      transcribe: 'es-EC',
    },
    {
      name: 'Spanish (El Salvador)',
      speechRecognition: 'es-SV',
      transcribe: 'es-SV',
    },
    {
      name: 'Spanish (Guatemala)',
      speechRecognition: 'es-GT',
      transcribe: 'es-GT',
    },
    {
      name: 'Spanish (Honduras)',
      speechRecognition: 'es-HN',
      transcribe: 'es-HN',
    },
    {
      name: 'Spanish (Mexico)',
      speechRecognition: 'es-MX',
      transcribe: 'es-MX',
    },
    {
      name: 'Spanish (Nicaragua)',
      speechRecognition: 'es-NI',
      transcribe: 'es-NI',
    },
    {
      name: 'Spanish (Panama)',
      speechRecognition: 'es-PA',
      transcribe: 'es-PA',
    },
    {
      name: 'Spanish (Paraguay)',
      speechRecognition: 'es-PY',
      transcribe: 'es-PY',
    },
    {
      name: 'Spanish (Peru)',
      speechRecognition: 'es-PE',
      transcribe: 'es-PE',
    },
    {
      name: 'Spanish (Puerto Rico)',
      speechRecognition: 'es-PR',
      transcribe: 'es-PR',
    },
    {
      name: 'Spanish (Spain)',
      speechRecognition: 'es-ES',
      transcribe: 'es-ES',
    },
    {
      name: 'Spanish (United States)',
      speechRecognition: 'es-US',
      transcribe: 'es-US',
    },
    {
      name: 'Spanish (Uruguay)',
      speechRecognition: 'es-UY',
      transcribe: 'es-UY',
    },
    {
      name: 'Spanish (Venezuela)',
      speechRecognition: 'es-VE',
      transcribe: 'es-VE',
    },
    {
      name: 'Sundanese (Indonesia)',
      speechRecognition: 'su-ID',
    },
    {
      name: 'Swahili (Kenya)',
      speechRecognition: 'sw-KE',
      transcribe: 'sw-KE',
    },
    {
      name: 'Swahili (Tanzania)',
      speechRecognition: 'sw-TZ',
      transcribe: 'sw-TZ',
    },
    {
      name: 'Swedish (Sweden)',
      speechRecognition: 'sv-SE',
      transcribe: 'sv-SE',
    },
    {
      name: 'Tamil (India)',
      speechRecognition: 'ta-IN',
      transcribe: 'ta-IN',
    },
    {
      name: 'Tamil (Malaysia)',
      speechRecognition: 'ta-MY',
      transcribe: 'ta-MY',
    },
    {
      name: 'Tamil (Singapore)',
      speechRecognition: 'ta-SG',
      transcribe: 'ta-SG',
    },
    {
      name: 'Tamil (Sri Lanka)',
      speechRecognition: 'ta-LK',
      transcribe: 'ta-LK',
    },
    {
      name: 'Telugu (India)',
      speechRecognition: 'te-IN',
    },
    {
      name: 'Thai (Thailand)',
      speechRecognition: 'th-TH',
      transcribe: 'th-TH',
    },
    {
      name: 'Turkish (Turkey)',
      speechRecognition: 'tr-TR',
      transcribe: 'tr-TR',
    },
    {
      name: 'Ukrainian (Ukraine)',
      speechRecognition: 'uk-UA',
      transcribe: 'uk-UA',
    },
    {
      name: 'Urdu (India)',
      speechRecognition: 'ur-IN',
      transcribe: 'ur-IN',
    },
    {
      name: 'Urdu (Pakistan)',
      speechRecognition: 'ur-PK',
      transcribe: 'ur-PK',
    },
    {
      name: 'Uzbek (Uzbekistan)',
      speechRecognition: 'uz-UZ',
    },
    {
      name: 'Vietnamese (Vietnam)',
      speechRecognition: 'vi-VN',
      transcribe: 'vi-VN',
    },
    {
      name: 'Zulu (South Africa)',
      speechRecognition: 'zu-ZA',
    },
  ],
}
