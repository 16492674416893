import TooltipTab from '@/app/component/atom/tooltip-tab'
import ConfirmationDialog from '@/app/module/campaigns/component/item/manage/subscriptions/confirmation-dialog'
import { BaseLanguageType } from '@/app/module/campaigns/helpers'
import { Language, PersonalizationType, VoiceMessage, VoiceProps, VoiceQuestion } from '@/app/module/campaigns/types'
import { downloadFile } from '@/app/service/util/download'
import Tabs from '@mui/material/Tabs'
import moment from 'moment'
import { path } from 'ramda'
import React, { PureComponent } from 'react'
import ContentAudio from './content-audio'
import LanguageWrapper from './language-wrapper'
import TextToSpeech from './text-to-speech'

type Props = Pick<
  VoiceProps,
  | 'defaultUpload'
  | 'files'
  | 'filterType'
  | 'loading'
  | 'playId'
  | 'getFile'
  | 'onMicAccessError'
  | 'saveFileHandler'
  | 'setDefaultUpload'
  | 'setPlaying'
> & {
  debounceChange?: boolean
  defaultLanguage: string
  enableTTS?: boolean
  enableTranscription: boolean
  info: string
  item: Partial<VoiceMessage | VoiceQuestion>
  languages?: Language[]
  personalizationList: PersonalizationType
  voiceList?: BaseLanguageType[]

  changeHandler: (item: any) => void
}

enum VoiceTab {
  Audio = 'playfile',
  TTS = 'say',
}

type State = {
  activeLanguage?: string
  confirmationDialog: {
    display: boolean
    tab: VoiceTab | ''
  }
  downloading: boolean
  filter: string
  newFile: any
  playing: boolean
  recording: boolean
  selectedVoice: string
  tab: VoiceTab
  uploading: boolean
}

export default class CampaignContentVoice extends PureComponent<Props, State> {
  state = {
    activeLanguage: '',
    confirmationDialog: {
      display: false,
      tab: '' as '',
    },
    downloading: false,
    filter: 'optimize',
    newFile: null,
    playing: false,
    recording: false,
    selectedVoice: '',
    tab: VoiceTab.Audio,
    uploading: false,
  }

  uploadRef: React.RefObject<any>

  constructor(props: Props) {
    super(props)
    this.uploadRef = React.createRef()

    this.state.selectedVoice = props.item.audio?.voice || ''
    this.state.tab = isTTS(props.item, props.languages) ? VoiceTab.TTS : VoiceTab.Audio
  }

  downloadAudioFile = async (url: string, file: string) => {
    this.setState({
      downloading: true,
    })
    try {
      await downloadFile(url, file, true)
    } finally {
      this.setState({
        downloading: false,
      })
    }
  }

  setDefaultContent = (tab: string) => {
    const { item, changeHandler } = this.props

    const defaultPlayfile = {
      playfile: '',
      transcription: '',
      translations: {},
    }
    const defaultSay = {
      say: '',
      transcription: '',
      translations: {},
    }

    changeHandler({
      ...item,
      audio: tab === VoiceTab.Audio ? defaultPlayfile : defaultSay,
    })
  }

  handleTabChange = (tab: VoiceTab) => {
    const { item, languages } = this.props

    switch (tab) {
      case VoiceTab.Audio: {
        if (item.audio?.say || item.audio?.voice) {
          this.setState({
            confirmationDialog: {
              display: true,
              tab,
            },
          })
          return
        }
        if (languages?.length) {
          for (let i = 0; i < languages.length; i += 1) {
            const language = languages[i]
            const translation = item.audio?.translations?.[language.value]
            if (translation?.say || translation?.voice) {
              this.setState({
                confirmationDialog: {
                  display: true,
                  tab,
                },
              })
              return
            }
          }
        }
        break
      }

      case VoiceTab.TTS: {
        if (item.audio?.playfile) {
          this.setState({
            confirmationDialog: {
              display: true,
              tab,
            },
          })
          return
        }
        if (languages?.length) {
          for (let i = 0; i < languages.length; i += 1) {
            const language = languages[i]
            const translation = item.audio?.translations?.[language.value]
            if (translation?.playfile) {
              this.setState({
                confirmationDialog: {
                  display: true,
                  tab,
                },
              })
              return
            }
          }
        }
        break
      }

      default:
        break
    }

    this.setState(
      {
        tab,
      },
      () => {
        this.setDefaultContent(tab)
      },
    )
  }

  handleMessageTypeSwitch = (tab: VoiceTab) => {
    this.setState(
      {
        confirmationDialog: {
          display: false,
          tab: '',
        },
        selectedVoice: '',
        tab,
      },
      () => {
        this.setDefaultContent(tab)
      },
    )
  }

  handleDownloadClick = async (audio: string) => {
    const file = await this.props.getFile(audio)
    const { url } = file
    if (url) {
      this.downloadAudioFile(url, audio)
    }
  }

  handlePlayToggle = (val: boolean, audio: string) => {
    const { files, item, getFile, setPlaying } = this.props
    const id = item.id || ''

    setPlaying(val ? id : '')
    this.setState({
      playing: val,
    })
    if (val && !files?.[audio]?.url) {
      getFile(audio)
    }
  }

  handleRecordFinish = async (newFile: File, activeLanguage: string) => {
    const { defaultLanguage, filterType, item, changeHandler, saveFileHandler } = this.props
    this.setState({ recording: false, uploading: true })
    try {
      const res = await saveFileHandler({
        file: newFile,
        filename: `recording-${moment(moment.now()).format('YYYY-MM-DD')}.mp3`,
        filter: filterMap('record', filterType),
      })
      if (res instanceof Error) {
        return
      }
      if (!activeLanguage || activeLanguage === defaultLanguage) {
        changeHandler({
          ...item,
          audio: {
            ...item.audio,
            playfile: res.filename,
            transcription: '',
          },
        })
        return
      }

      changeHandler({
        ...item,
        audio: {
          ...item.audio,
          translations: {
            ...item.audio?.translations,
            [activeLanguage]: {
              ...item.audio?.translations?.[activeLanguage],
              playfile: res.filename,
              transcription: '',
            },
          },
        },
      })
    } finally {
      this.setState({ uploading: false })
    }
  }

  handleUpload = async (newFile: File, activeLanguage: string) => {
    const { defaultLanguage, filterType, item, changeHandler, saveFileHandler } = this.props
    this.setState({
      uploading: true,
    })
    try {
      const res = await saveFileHandler({
        file: newFile,
        filename: newFile.name,
        filter: filterMap(this.state.filter, filterType),
      })
      if (res instanceof Error) {
        return
      }
      if (!activeLanguage || activeLanguage === defaultLanguage) {
        changeHandler({
          ...item,
          audio: {
            ...item.audio,
            playfile: res.filename,
            transcription: '',
          },
        })
        return
      }

      changeHandler({
        ...item,
        audio: {
          ...item.audio,
          translations: {
            ...item.audio?.translations,
            [activeLanguage]: {
              ...item.audio?.translations?.[activeLanguage],
              playfile: res.filename,
              transcription: '',
            },
          },
        },
      })
    } finally {
      this.setState({
        uploading: false,
      })
    }
  }

  handleTranscriptChange = (transcription: string, activeLanguage: string) => {
    const { defaultLanguage, item, changeHandler } = this.props

    if (!activeLanguage || activeLanguage === defaultLanguage) {
      return changeHandler({
        ...item,
        audio: {
          ...item.audio,
          transcription,
        },
      })
    }

    return changeHandler({
      ...item,
      audio: {
        ...item.audio,
        translations: {
          ...item.audio?.translations,
          [activeLanguage]: {
            ...item.audio?.translations?.[activeLanguage],
            transcription,
          },
        },
      },
    })
  }

  handleAudioDelete = (activeLanguage: string) => {
    const { defaultLanguage, item, changeHandler } = this.props

    if (!activeLanguage || activeLanguage === defaultLanguage) {
      return changeHandler({
        ...item,
        audio: {
          ...item.audio,
          playfile: '',
          transcription: '',
        },
      })
    }

    return changeHandler({
      ...item,
      audio: {
        ...item.audio,
        translations: {
          ...item.audio?.translations,
          [activeLanguage]: {
            ...item.audio?.translations?.[activeLanguage],
            playfile: '',
            transcription: '',
          },
        },
      },
    })
  }

  renderAudio = (audio: string, activeLanguage: string) => {
    const {
      defaultLanguage,
      defaultUpload,
      enableTTS,
      enableTranscription,
      files,
      item,
      loading,
      playId,
      getFile,
      onMicAccessError,
      setPlaying,
      setDefaultUpload,
    } = this.props
    const { downloading, recording, uploading } = this.state
    const id = item.id || ''
    const file = audio ? files?.[audio] : undefined

    return (
      <ContentAudio
        audio={audio}
        audioFile={file}
        downloading={downloading}
        defaultUpload={defaultUpload}
        enableTTS={enableTTS}
        enableTranscription={enableTranscription}
        id={id}
        loading={loading}
        playId={playId || ''}
        recording={recording}
        transcription={getTranscription(item, activeLanguage, defaultLanguage)}
        uploadRef={this.uploadRef}
        uploading={uploading}
        onDelete={() => this.handleAudioDelete(activeLanguage)}
        onDownload={() => this.handleDownloadClick(audio)}
        onPlayToggle={(val: boolean) => {
          setPlaying(val ? item.id || '' : '')
          this.setState({
            playing: val,
          })
          if (val && !path([audio, 'url'], files)) {
            getFile(audio)
          }
        }}
        onRecordClick={() => this.setState((s) => ({ recording: !s.recording }))}
        onRecordDisabled={() => {
          this.setState({
            recording: false,
          })
          onMicAccessError()
        }}
        onRecordFinish={(newFile: File) => this.handleRecordFinish(newFile, activeLanguage)}
        onRecordStart={() => this.setState({ recording: true })}
        onStop={() => this.setState({ playing: false })}
        onTranscriptChange={(transcription) => this.handleTranscriptChange(transcription, activeLanguage)}
        onUpload={(newFile: File) => this.handleUpload(newFile, activeLanguage)}
        onUploadStart={(filter: string) => {
          this.setState({
            filter,
          })
          this.uploadRef.current?.open()
        }}
        setDefaultUpload={setDefaultUpload}
      />
    )
  }

  handleLanguageChange = (language: string) => {
    const { defaultLanguage, item } = this.props

    const isDefault = language === defaultLanguage
    if (isDefault) {
      this.setState({ selectedVoice: item.audio?.voice || '' })
    } else {
      this.setState({ selectedVoice: item.audio?.translations?.[language]?.voice || '' })
    }
  }

  handleVoiceChange = (voice: string) => {
    this.setState({
      selectedVoice: voice,
    })
  }

  getTTSTabTooltip = () => {
    const { downloading, recording, playing, uploading } = this.state

    if (recording) {
      return 'Please stop recording before switching to Text-to-Speech.'
    }
    if (playing) {
      return 'Please stop playing the audio before switching to Text-to-Speech.'
    }
    if (downloading) {
      return 'Please wait for the download to finish before switching to Text-to-Speech.'
    }
    if (uploading) {
      return 'Please wait for the upload to finish before switching to Text-to-Speech.'
    }
    return ''
  }

  render() {
    const {
      debounceChange,
      defaultLanguage,
      enableTTS,
      info,
      item,
      languages,
      personalizationList,
      voiceList,
      changeHandler,
    } = this.props
    const { confirmationDialog, downloading, playing, recording, selectedVoice, tab, uploading } = this.state

    return (
      <LanguageWrapper
        content={tab as any}
        disabled={recording || playing}
        info={info}
        defaultLanguage={defaultLanguage}
        item={item}
        languages={languages}
        selectedVoice={selectedVoice}
        type="audio"
        onChange={changeHandler}
        onLanguageChange={this.handleLanguageChange}
      >
        {(
          content: string,
          onContentChange: (content: { message: string }) => void,
          error: string,
          activeLanguage: string,
        ) => {
          if (!enableTTS) {
            return this.renderAudio(content, activeLanguage)
          }

          return (
            <div data-testid="campaign-content-voice-tabs">
              <Tabs indicatorColor="primary" value={tab} onChange={(_, t) => this.handleTabChange(t)}>
                <TooltipTab label="Audio" style={{ zIndex: 2 }} value="playfile" wrapped />
                <TooltipTab
                  disabled={downloading || recording || playing || uploading}
                  label="Text-to-Speech"
                  style={{ zIndex: 2 }}
                  tooltip={this.getTTSTabTooltip()}
                  value="say"
                />
              </Tabs>
              {tab === VoiceTab.Audio && <>{this.renderAudio(content, activeLanguage)}</>}
              {tab === VoiceTab.TTS && (
                <TextToSpeech
                  activeLanguage={activeLanguage}
                  autoFocus={item.autoFocus}
                  debounceChange={debounceChange}
                  message={content}
                  personalizationList={personalizationList}
                  selectedVoice={selectedVoice}
                  voiceList={voiceList || []}
                  onChange={onContentChange}
                  onVoiceChange={this.handleVoiceChange}
                />
              )}
              <ConfirmationDialog
                id="voice-message-type-switch-dialog"
                text="Switching message types will discard your existing content. Do you want to continue?"
                onClose={() =>
                  this.setState({
                    confirmationDialog: {
                      display: false,
                      tab: '',
                    },
                  })
                }
                onConfirm={() =>
                  confirmationDialog.tab && this.handleMessageTypeSwitch(confirmationDialog.tab as VoiceTab)
                }
                isOpen={confirmationDialog.display}
                deleteButtonText="Confirm"
                title="Change Voice message type"
                icon="sms"
              />
            </div>
          )
        }}
      </LanguageWrapper>
    )
  }
}

const whatsappFilterMap: Record<string, string> = {
  optimizeVolume: 'opus,mono,norm',
  optimizeAndTrim: 'opus,mono,norm,trim',
  asIs: 'opus,mono',
  record: 'opus,mono,norm',
}
const voiceFilterMap: Record<string, string> = {
  optimizeVolume: 'ogg,mono,norm',
  optimizeAndTrim: 'ogg,mono,norm,trim',
  asIs: 'ogg,mono',
  record: 'ogg,mono,norm',
}

const filterMap = (filter: string, filterType: string) => {
  if (filterType === 'whatsapp') {
    return whatsappFilterMap[filter]
  }
  return voiceFilterMap[filter]
}

export const isTTS = (item: Partial<VoiceMessage | VoiceQuestion>, languages?: Language[]) => {
  if (item.audio?.playfile) {
    // if the default language has playfile attribute, then it's not TTS
    return false
  }

  const hasTTSAttribute = item.audio?.say !== undefined || item.audio?.voice !== undefined
  if (!languages?.length) {
    // if there are no languages, then it's TTS if the default language has say or voice attribute
    return hasTTSAttribute
  }

  for (let i = 0; i < languages.length; i += 1) {
    const language = languages[i]

    // if any of the languages has playfile attribute, then it's not TTS
    if (item.audio?.translations?.[language.value]?.playfile) {
      return false
    }

    if (
      item.audio?.translations?.[language.value]?.say !== undefined ||
      item.audio?.translations?.[language.value]?.voice !== undefined
    ) {
      return true
    }
  }

  return hasTTSAttribute
}

export const getTranscription = (
  item: Partial<VoiceMessage | VoiceQuestion>,
  activeLanguage: string,
  defaultLanguage: string,
) => {
  if (!activeLanguage || activeLanguage === defaultLanguage) {
    return item.audio?.transcription || ''
  }

  return item.audio?.translations?.[activeLanguage]?.transcription || ''
}
