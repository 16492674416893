import React from 'react'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import { selectInvitationsCount, selectOrgPlan } from '@/app/module/user/store/ts/selectors'

type Props = {}

const UserAccountsUsage: React.FC<Props> = () => {
  const orgPlan = useSelector(selectOrgPlan)
  const invitationCounts = useSelector(selectInvitationsCount)

  if (!orgPlan) {
    return null
  }

  return (
    <Typography color="textSecondary" variant="subtitle1">
      <b>{orgPlan.usersNow + invitationCounts}</b>/<b>{orgPlan.usersIncluded}</b> users and invitations
    </Typography>
  )
}

export default UserAccountsUsage
