import { Checkbox } from '@/app/component/atom/form'
import { WhatsAppTab } from '@/app/component/atom/whatsapp/helpers'
import { useDebouncedFn } from '@/app/helpers'
import { CampaignAddButton } from '@/app/module/campaigns/component/helpers'
import ConfirmationDialog from '@/app/module/campaigns/component/item/manage/subscriptions/confirmation-dialog'
import {
  CAMPAIGN_CHANGE_DELAY,
  CAMPAIGN_RETRIES,
  DRAGTYPE,
  MESSAGE_PERSONALIZATION,
  SMS_ACTION_DEFAULT_SENDERID,
} from '@/app/module/campaigns/definitions'
import {
  CampaignTemplateType,
  LocalAction,
  PartFamily,
  TWhatsAppList,
  WhatsAppQuestion,
} from '@/app/module/campaigns/types'
import { emptyAction } from '@/app/module/campaigns/utils/actions/wrap'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { append, remove, update } from 'ramda'
import React from 'react'
import { v1 as uuid } from 'uuid'
import QuestionBox from './question'
import { AnswerType, QuestionTextProps } from './question-text-props'
import QuestionNumberInput from './question/number'
import QuestionTimerInput from './question/timer'
import ResponseTextOpenEnded from './response-text-open-ended'
import ResponseSpecific from './response-text-specific'
import ResponseWhatsappList from './response-whatsapp-list'
import ResponseWhatsappTabs from './response-whatsapp-tabs'
import {
  backToProps,
  checkTabSwitchConfirmation,
  fromProps,
  generateContent,
  getPartActions,
  State,
  TabSwitchConfirmation,
} from './response-whatsapp-utils'
import WhatsappButtonItem from './whatsapp-button-item'

type Props = QuestionTextProps & {
  item: WhatsAppQuestion
  type: CampaignTemplateType // campaign type
  /**
   * The whatsapp content tab
   */
  tab: WhatsAppTab

  changeHandler: (item: WhatsAppQuestion) => void
}

export default function ResponseWhatsapp(props: Props) {
  const {
    item,
    id,
    personalizationList,
    senderIds,
    whatsappSenderIds,
    getCampaigns,
    orgId,
    token,
    campaignIds,
    getImageProps,
    getVoiceProps,
    files = {},
    timezone,
    timezones,
    countryCode,
    countriesTimezones,
    getSnippets,
    saveSnippet,
    snippets,
    languages,
    defaultLanguage,
    tab,
    transcribe,
    useAi,
    changeHandler,
  } = props

  const [state, setState] = React.useState(fromProps(item))
  const [nextTabConfirmation, setNextTabConfirmation] = React.useState<TabSwitchConfirmation | undefined>(undefined)

  const itemRef = React.useRef<WhatsAppQuestion | null>()
  const changeHandlerRef = React.useRef(changeHandler)

  const { buttonActions, timeoutActions, invalidActions, failureActions } = React.useMemo(
    () => getPartActions({ campaignType: props.type }),
    [props.type],
  )

  React.useEffect(
    () => () => {
      if (itemRef.current) {
        changeHandlerRef.current?.(itemRef.current)
      }
    },
    [],
  )

  React.useEffect(() => {
    setState(fromProps(item))
  }, [item])

  const textPersonalization = React.useMemo(
    () => ({
      ...MESSAGE_PERSONALIZATION,
      ...personalizationList,
    }),
    [personalizationList],
  )

  const handleChange = (newItem: Partial<State>) => {
    const newState = {
      ...state,
      ...newItem,
    }

    const returnToProps = backToProps(newState)

    changeHandler({
      ...item,
      ...returnToProps,
    })

    setState(newState)
  }

  const debounced = useDebouncedFn((updatedItem: WhatsAppQuestion) => {
    changeHandler(updatedItem)
    itemRef.current = null
  }, CAMPAIGN_CHANGE_DELAY)
  const debouncedHandleChange = (newItem: Partial<State>) => {
    const returnToProps = backToProps({
      ...state,
      ...newItem,
    })
    const updatedItem = {
      ...item,
      ...returnToProps,
    }
    itemRef.current = updatedItem
    debounced(updatedItem)
  }

  const addOptionDisabled =
    (state.onButtons && state.onButtons.buttons.length >= 3) || (state.onList && state.onList.items.length >= 10)

  const actionProps = React.useMemo(
    () => ({
      campaignIds,
      countriesTimezones,
      countryCode,
      defaultLanguage,
      files,
      index: props.index,
      languages,
      personalizationList: textPersonalization,
      senderIds,
      timezone,
      timezones,
      transcribeEnabled: item.transcribe,
      whatsappSenderIds,
      getCampaigns,
      getImageProps,
      getVoiceProps,
    }),
    [
      campaignIds,
      countriesTimezones,
      countryCode,
      defaultLanguage,
      files,
      item.transcribe,
      languages,
      props.index,
      senderIds,
      textPersonalization,
      timezone,
      timezones,
      whatsappSenderIds,
      getCampaigns,
      getImageProps,
      getVoiceProps,
    ],
  )
  const apiProps = React.useMemo(() => ({ orgId, token }), [orgId, token])
  const snippetProps = React.useMemo(
    () => ({ snippets, getSnippets, saveSnippet }),
    [snippets, getSnippets, saveSnippet],
  )

  const handleWAListChange = React.useCallback(
    (value: TWhatsAppList) =>
      changeHandler({
        ...item,
        onList: value,
      }),
    [item, changeHandler],
  )

  return (
    <div
      className="text-question-block"
      style={{
        marginTop: '2rem',
      }}
    >
      {nextTabConfirmation && (
        <ConfirmationDialog
          deleteButtonText="Confirm"
          icon="announcement"
          isOpen
          text={nextTabConfirmation.text}
          title={nextTabConfirmation.title}
          onClose={() => setNextTabConfirmation(undefined)}
          onConfirm={() => {
            if (!nextTabConfirmation) {
              return
            }
            const nextTab = nextTabConfirmation.tab
            handleChange({
              ...generateContent(nextTab),
              answerType: nextTab,
            })
            setNextTabConfirmation(undefined)
          }}
        />
      )}
      <Divider
        style={{
          margin: '1rem -24px',
        }}
      />
      <ResponseWhatsappTabs
        answerType={state.answerType}
        tab={tab}
        onChange={(val) => {
          const confirmation = checkTabSwitchConfirmation(state, val)
          if (confirmation) {
            setNextTabConfirmation(confirmation)
          } else {
            handleChange({
              ...generateContent(val),
              answerType: val,
            })
          }
        }}
      />
      <Divider
        style={{
          margin: '-1px -24px 1rem',
        }}
      />
      {state.answerType === AnswerType.OpenEnded && state.openEnded && (
        <ResponseTextOpenEnded
          actionProps={actionProps}
          apiProps={apiProps}
          campaignType={props.type}
          id={id}
          openEnded={state.openEnded}
          partType={item.type}
          snippetProps={snippetProps}
          transcribe={transcribe || false}
          onChange={handleChange}
        />
      )}
      {state.answerType === AnswerType.Text && (
        <ResponseSpecific
          actionProps={actionProps}
          apiProps={apiProps}
          campaignType={props.type}
          id={id}
          onAnswer={state.onAnswer}
          partType={item.type}
          snippetProps={snippetProps}
          transcribe={transcribe || false}
          useAi={useAi}
          onChange={handleChange}
        />
      )}
      {state.answerType === AnswerType.WhatsAppList && 'onList' in item && item.onList && (
        <ResponseWhatsappList
          actionProps={actionProps}
          apiProps={apiProps}
          campaignType={props.type}
          onList={item.onList}
          snippetProps={snippetProps}
          onChange={handleWAListChange}
        />
      )}
      {state.answerType === AnswerType.WhatsAppButtons && state.onButtons && (
        <div className="campaign-whatsapp-buttons-tab">
          <Typography>WhatsApp Buttons</Typography>
          <div>
            <Typography color="textSecondary" variant="caption" gutterBottom={true}>
              Specify up to 3 buttons that the contact can select and what actions we should take for each button.
            </Typography>

            {state.onButtons.buttons.map((button, i) => (
              <WhatsappButtonItem
                {...props}
                actionsValues={buttonActions}
                defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.smsQuestion}
                index={props.index}
                isCloseDisabled={state.onButtons?.buttons?.length === 1}
                key={button.id}
                value={button}
                onChange={(value) => {
                  if (!state.onButtons) {
                    return
                  }
                  handleChange({
                    onButtons: {
                      ...state.onButtons,
                      buttons: update(i, value, state.onButtons.buttons),
                    },
                  })
                }}
                onClose={() => {
                  if (!state.onButtons) {
                    return
                  }
                  handleChange({
                    onButtons: {
                      ...state.onButtons,
                      buttons: remove(i, 1, state.onButtons.buttons),
                    },
                  })
                }}
              />
            ))}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CampaignAddButton
                disabled={addOptionDisabled}
                icon="add"
                text="Add button"
                tooltip={
                  addOptionDisabled
                    ? 'You can only specify up to 3 buttons. Use WhatsApp List to set up to 10 answer choices.'
                    : 'Add button'
                }
                onClick={() => {
                  if (!state.onButtons || state.onButtons.buttons.length >= 3) {
                    return
                  }

                  handleChange({
                    onButtons: {
                      ...state.onButtons,
                      buttons: append(
                        {
                          id: uuid(),
                          actions: [emptyAction],
                          label: '',
                          title: '',
                        },
                        state.onButtons.buttons,
                      ),
                    },
                  })
                }}
              />
            </div>
          </div>
        </div>
      )}
      <Divider
        style={{
          margin: '1rem -24px',
        }}
      />
      <div style={{ display: 'flex' }}>
        <Typography color="secondary" component="div" variant="subtitle1" gutterBottom={true}>
          <b>Important:</b> If there's no reply within 24 hours, the question will be marked as done, and the survey
          will proceed to the next question (or execute "Failure" actions you specify below). Adjust the timeout in the
          "Timeout / no reply" section below.
        </Typography>
      </div>
      {state.answerType !== AnswerType.OpenEnded && (
        <>
          <Divider
            style={{
              margin: '1rem -24px',
            }}
          />
          <div className="campaign-question-invalid-box">
            <Checkbox
              name="invalid-reply"
              label="Invalid reply"
              value={state.checkInvalid.toString()}
              editable={true}
              onChange={({ value }: { value: string }) => {
                const changeRetries = (value !== 'true' && !state.checkTimeout) || !state.retries
                handleChange({
                  checkInvalid: value === 'true',
                  ...(changeRetries && {
                    retries: value === 'true' || state.checkTimeout ? CAMPAIGN_RETRIES : 0,
                  }),
                })
              }}
            />
            <div
              style={{
                marginLeft: '24px',
              }}
            >
              <Typography color="textSecondary" variant="caption" gutterBottom={true}>
                Specify what we should do if the contact's reply is not one of the choices. Afterwards, we will wait for
                the contact to reply with a valid choice—until they run out of chances.
              </Typography>
              {state.checkInvalid && (
                <QuestionBox
                  dragContext={`${DRAGTYPE}-invalid`}
                  actionsList={state.invalidActions}
                  actionsValues={invalidActions}
                  changeHandler={({ actionsList }: { actionsList: LocalAction[] }) =>
                    handleChange({
                      invalidActions: actionsList,
                    })
                  }
                  personalizationList={textPersonalization}
                  question={false}
                  index={props.index}
                  invalid={true}
                  senderIds={senderIds}
                  whatsappSenderIds={whatsappSenderIds}
                  defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.smsQuestion}
                  getCampaigns={getCampaigns}
                  imageId={`${id}-invalid`}
                  voiceId={`${id}-invalid`}
                  getImageProps={getImageProps}
                  getVoiceProps={getVoiceProps}
                  files={files}
                  orgId={orgId}
                  token={token}
                  campaignIds={campaignIds}
                  timezones={timezones}
                  timezone={timezone}
                  countriesTimezones={countriesTimezones}
                  countryCode={countryCode}
                  getSnippets={getSnippets}
                  snippets={snippets}
                  saveSnippet={saveSnippet}
                  languages={languages}
                  defaultLanguage={defaultLanguage}
                />
              )}
            </div>
          </div>
        </>
      )}
      <>
        <Divider
          style={{
            margin: '1rem -24px',
          }}
        />
        <div className="campaign-question-timeout-box">
          <Checkbox
            name="timeout-no-reply"
            label="Timeout / no reply"
            value={state.checkTimeout.toString()}
            editable={true}
            onChange={({ value }: { value: string }) => {
              const shouldCheckTimeout = value === 'true'
              const shouldChangeRetries = (!shouldCheckTimeout && !state.checkInvalid) || !state.retries
              const shouldSetDefault = shouldCheckTimeout && !state.timeoutDelay?.value
              handleChange({
                checkTimeout: shouldCheckTimeout,
                ...(shouldChangeRetries && {
                  retries: shouldCheckTimeout || state.checkInvalid ? CAMPAIGN_RETRIES : 0,
                }),
                ...(shouldSetDefault && {
                  timeoutDelay: {
                    unit: 'minute',
                    value: 10,
                  },
                }),
              })
            }}
          />
          <div
            style={{
              marginLeft: '24px',
            }}
          >
            <Typography color="textSecondary" variant="caption" gutterBottom={true}>
              Specify what we should do if the contact does not reply within a period of time that you specify.
              Afterwards, we will wait for the contact to reply—until they run out of chances.
            </Typography>
            {state.checkTimeout && (
              <QuestionBox
                dragContext={`${DRAGTYPE}-timeout`}
                actionsList={state.timeoutActions}
                actionsValues={timeoutActions}
                questionType={PartFamily.WhatsApp}
                changeHandler={({ actionsList }: { actionsList: LocalAction[] }) =>
                  handleChange({
                    timeoutActions: actionsList,
                  })
                }
                personalizationList={personalizationList}
                senderIds={senderIds}
                whatsappSenderIds={whatsappSenderIds}
                defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.smsQuestion}
                getCampaigns={getCampaigns}
                imageId={`${id}-timeout`}
                index={props.index}
                voiceId={`${id}-timeout`}
                getImageProps={getImageProps}
                getVoiceProps={getVoiceProps}
                files={files}
                orgId={orgId}
                token={token}
                question={false}
                campaignIds={campaignIds}
                timezones={timezones}
                timezone={timezone}
                countriesTimezones={countriesTimezones}
                countryCode={countryCode}
                getSnippets={getSnippets}
                snippets={snippets}
                saveSnippet={saveSnippet}
                languages={languages}
                defaultLanguage={defaultLanguage}
                info={
                  <Typography color="inherit" component="div" style={{ display: 'flex', alignItems: 'center' }}>
                    {'Take the above actions if the contact does not reply to this question after'}
                    <div style={{ width: 4 }} />
                    <QuestionTimerInput
                      {...state.timeoutDelay}
                      min={1}
                      values={timeoutValues}
                      onChange={(timeoutDelay) =>
                        debouncedHandleChange({
                          timeoutDelay,
                        })
                      }
                    />
                  </Typography>
                }
              />
            )}
          </div>
        </div>
      </>
      <Divider
        style={{
          margin: '1rem -24px',
        }}
      />
      <React.Fragment>
        <div className="campaign-question-failure-retries">
          <Typography color="textSecondary" component="div" variant="caption" gutterBottom={true}>
            <QuestionNumberInput
              min={0}
              beforeText="Specify the number of times that we should allow the Contact to reply invalidly or time out before we
                  take the Failure actions you specify below:"
              afterText="additional chances."
              editable={state.checkInvalid || state.checkTimeout}
              value={state.retries.toString()}
              onChange={({ value }: { value: string }) => {
                debouncedHandleChange({
                  retries: Number(value),
                })
              }}
            />
          </Typography>
          {!(state.checkInvalid || state.checkTimeout) && (
            <Typography color="textSecondary" component="div" variant="caption" gutterBottom={true}>
              Note: To set the number of chances, please first select and specify Actions for Invalid Reply or Timeout.
            </Typography>
          )}
          {(state.checkInvalid || state.checkTimeout) && (
            <Typography color="textSecondary" component="div" variant="caption" gutterBottom={true}>
              Note: after we take the Failure actions you specify, the survey will continue, unless you add an "End
              Campaign for Contact" action in the Failure section.
            </Typography>
          )}
        </div>
      </React.Fragment>
      <Divider
        style={{
          margin: '1rem -24px',
        }}
      />
      <div className="campaign-question-failure-box">
        <Checkbox
          name="failure"
          label="Failure"
          value={state.checkFailure.toString()}
          editable={true}
          onChange={({ value }: { value: string }) => {
            handleChange({
              checkFailure: value === 'true',
            })
          }}
        />
        <div
          style={{
            marginLeft: '24px',
          }}
        >
          <Typography color="textSecondary" variant="caption" gutterBottom={true}>
            Specify what we should do after the contact runs out of chances.
          </Typography>
          {state.checkFailure && (
            <QuestionBox
              dragContext={`${DRAGTYPE}-failure`}
              actionsList={state.failureActions}
              actionsValues={failureActions}
              changeHandler={({ actionsList }: { actionsList: LocalAction[] }) =>
                handleChange({
                  failureActions: actionsList,
                })
              }
              personalizationList={textPersonalization}
              senderIds={senderIds}
              whatsappSenderIds={whatsappSenderIds}
              defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.smsQuestion}
              getCampaigns={getCampaigns}
              imageId={`${id}-failure`}
              voiceId={`${id}-failure`}
              getImageProps={getImageProps}
              getVoiceProps={getVoiceProps}
              files={files}
              orgId={orgId}
              token={token}
              question={false}
              campaignIds={campaignIds}
              index={props.index}
              timezones={timezones}
              timezone={timezone}
              countriesTimezones={countriesTimezones}
              countryCode={countryCode}
              getSnippets={getSnippets}
              snippets={snippets}
              saveSnippet={saveSnippet}
              languages={languages}
              defaultLanguage={defaultLanguage}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const timeoutValues: { label: string; value: string }[] = [
  {
    label: 'seconds',
    value: 'second',
  },
  {
    label: 'minutes',
    value: 'minute',
  },
  {
    label: 'hours',
    value: 'hour',
  },
  {
    label: 'days',
    value: 'day',
  },
]
