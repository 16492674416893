import IconText from '@/app/component/layout/icon-text'
import { ContentAudio, VoiceProps } from '@/app/module/campaigns/types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Icon,
  IconButton,
  Typography,
} from '@mui/material'
import React from 'react'
import { hasContent } from '@/app/module/campaigns/helpers'
import CampaignContentVoice from './voice'

type Props = Omit<DialogProps, 'onClose'> & {
  defaultLanguage: string
  id: string
  processingReply?: ContentAudio

  getVoiceProps: (qid: string) => VoiceProps
  onClose: () => void
  onSubmit: (value: ContentAudio) => void
}

/**
 * Audio waiting message configuration, only used when useAi toggle is on for voice question speech recognition or
 * when transcribe toggle is on for voice question spoken response
 */
const VoiceProcessingDialog: React.FC<Props> = ({
  defaultLanguage,
  id,
  processingReply,
  getVoiceProps,
  onClose,
  onSubmit,
  ...props
}) => {
  const [audio, setAudio] = React.useState<ContentAudio | undefined>(processingReply)
  const item = React.useMemo(
    () => ({
      audio,
    }),
    [audio],
  )
  const voiceProps = React.useMemo(() => getVoiceProps(`${id}-processing-reply`), [id, getVoiceProps])

  const handleAudioChange = React.useCallback((value: { audio: ContentAudio }) => setAudio(value.audio), [setAudio])
  const handleSubmit = React.useCallback(() => {
    if (!audio) {
      return
    }

    onSubmit(audio)
    onClose()
  }, [audio, onClose, onSubmit])

  const hasAudioContent = React.useMemo(
    () => hasContent(audio, 'playfile') || (hasContent(audio, 'say') && hasContent(audio, 'voice')),
    [audio],
  )

  return (
    <Dialog {...props}>
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconText>
            <Icon>queue_music</Icon>
            <Typography variant="h6">Waiting Message</Typography>
          </IconText>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Specify the audio message to play after the contact has stopped speaking while the system is checking if the
          reply is valid.
        </DialogContentText>
        <br />
        <CampaignContentVoice
          {...voiceProps}
          debounceChange
          defaultLanguage={defaultLanguage}
          enableTranscription
          info=""
          item={item}
          changeHandler={handleAudioChange}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" disabled={!hasAudioContent} variant="contained" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default VoiceProcessingDialog
