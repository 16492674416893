import { path, pathOr } from 'ramda'
import config from '@/config'
import http from '@/config/http'
import { DEFAULT_SENDER_ID_VALUE } from '@/app/definitions'
import { getPageSize, setPageSize } from '@/app/service/storage'
import { createAsyncActions, preventParallel, toQuery } from '@/app/service/util'
import { isFailed } from '@/app/service/util/error'
import { processCampaignErrors, processErrors } from '@/app/service/util/errorCodes'
import { getContactsItem } from '@/app/module/contacts/store/actions'
import { getFilesItem, saveFile } from '@/app/module/files/store/actions'
import { createNotification } from '@/app/module/notifications/store/actions'
import request from '@/app/module/request'
import {
  ACTION_ABORT_CAMPAIGN_FAILURE,
  ACTION_ABORT_CAMPAIGN_REQUEST,
  ACTION_ABORT_CAMPAIGN_SUCCESS,
  ACTION_ARCHIVE_CAMPAIGN_FAILURE,
  ACTION_ARCHIVE_CAMPAIGN_REQUEST,
  ACTION_ARCHIVE_CAMPAIGN_SUCCESS,
  ACTION_CAMPAIGNS_NAVIGATE,
  ACTION_CAMPAIGNS_PAGE_SIZE,
  ACTION_CAMPAIGNS_SET_CHANGED,
  ACTION_CAMPAIGN_FILE_RESET,
  ACTION_CAMPAIGN_ITEM_CONTACTS_SET,
  ACTION_COPY_CAMPAIGN_FAILURE,
  ACTION_COPY_CAMPAIGN_REQUEST,
  ACTION_COPY_CAMPAIGN_SUCCESS,
  ACTION_EXPORT_CAMPAIGN_REPORT,
  ACTION_GET_CAMPAIGNS_FAILURE,
  ACTION_GET_CAMPAIGNS_REQUEST,
  ACTION_GET_CAMPAIGNS_SUCCESS,
  ACTION_GET_CAMPAIGN_FILE_FAILURE,
  ACTION_GET_CAMPAIGN_FILE_REQUEST,
  ACTION_GET_CAMPAIGN_FILE_SUCCESS,
  ACTION_GET_CAMPAIGN_ITEM_FAILURE,
  ACTION_GET_CAMPAIGN_ITEM_REQUEST,
  ACTION_GET_CAMPAIGN_ITEM_SUCCESS,
  ACTION_GET_CAMPAIGN_SUMMARY_FAILURE,
  ACTION_GET_CAMPAIGN_SUMMARY_REQUEST,
  ACTION_GET_CAMPAIGN_SUMMARY_SUCCESS,
  ACTION_OPEN_CAMPAIGN_FAILURE,
  ACTION_OPEN_CAMPAIGN_REQUEST,
  ACTION_OPEN_CAMPAIGN_SUCCESS,
  ACTION_PUBLISH_CAMPAIGN_FAILURE,
  ACTION_PUBLISH_CAMPAIGN_REQUEST,
  ACTION_PUBLISH_CAMPAIGN_SUCCESS,
  ACTION_SAVE_CAMPAIGN_FAILURE,
  ACTION_SAVE_CAMPAIGN_FILE_FAILURE,
  ACTION_SAVE_CAMPAIGN_FILE_REQUEST,
  ACTION_SAVE_CAMPAIGN_FILE_SUCCESS,
  ACTION_SAVE_CAMPAIGN_REQUEST,
  ACTION_SAVE_CAMPAIGN_SUCCESS,
  ACTION_SUBSCRIBE_CONTACT_TO_CAMPAIGN_FAILURE,
  ACTION_SUBSCRIBE_CONTACT_TO_CAMPAIGN_REQUEST,
  ACTION_SUBSCRIBE_CONTACT_TO_CAMPAIGN_SUCCESS,
  ACTION_SUBSCRIBE_GROUP_TO_CAMPAIGN_FAILURE,
  ACTION_SUBSCRIBE_GROUP_TO_CAMPAIGN_REQUEST,
  ACTION_SUBSCRIBE_GROUP_TO_CAMPAIGN_SUCCESS,
  ACTION_SUBSCRIBE_SEGMENT_TO_CAMPAIGN_FAILURE,
  ACTION_SUBSCRIBE_SEGMENT_TO_CAMPAIGN_REQUEST,
  ACTION_SUBSCRIBE_SEGMENT_TO_CAMPAIGN_SUCCESS,
  ACTION_UNSUBSCRIBE_FROM_CAMPAIGN_FAILURE,
  ACTION_UNSUBSCRIBE_FROM_CAMPAIGN_REQUEST,
  ACTION_UNSUBSCRIBE_FROM_CAMPAIGN_SUCCESS,
  ACTION_UPDATE_CAMPAIGN_ITEM,
  ERROR_ABORT_CAMPAIGN_SERVER,
  ERROR_ARCHIVE_CAMPAIGN_SERVER,
  ERROR_COPY_CAMPAIGN_SERVER,
  ERROR_EXPORT_CAMPAIGN_REPORT_SERVER,
  ERROR_GET_CAMPAIGNS_SERVER,
  ERROR_GET_CAMPAIGN_ITEM_NOT_FOUND,
  ERROR_GET_CAMPAIGN_ITEM_SERVER,
  ERROR_GET_CAMPAIGN_SUMMARY_SERVER,
  ERROR_OPEN_CAMPAIGN_SERVER,
  ERROR_PUBLISH_CAMPAIGN_SERVER,
  ERROR_SAVE_CAMPAIGN_SERVER,
  ERROR_SUBSCRIBE_GROUP_TO_CAMPAIGN_SERVER,
  ERROR_SUBSCRIBE_SEGMENT_TO_CAMPAIGN_SERVER,
  ERROR_SUBSCRIBE_TO_CAMPAIGN_SERVER,
  EXPORT_CAMPAIGN_REPORT_PENDING,
} from '@/app/module/campaigns/definitions'
import isCampaignClass from '@/app/module/campaigns/models/guard'
import { processActionForSaving, processVariables } from './selectors'

const {
  request: getCampaignsRequest,
  success: getCampaignsSuccess,
  failure: getCampaignsFailure,
} = createAsyncActions({
  request: ACTION_GET_CAMPAIGNS_REQUEST,
  success: ACTION_GET_CAMPAIGNS_SUCCESS,
  failure: ACTION_GET_CAMPAIGNS_FAILURE,
})

const {
  request: getCampaignItemRequest,
  success: getCampaignItemSuccess,
  failure: getCampaignItemFailure,
} = createAsyncActions({
  request: ACTION_GET_CAMPAIGN_ITEM_REQUEST,
  success: ACTION_GET_CAMPAIGN_ITEM_SUCCESS,
  failure: ACTION_GET_CAMPAIGN_ITEM_FAILURE,
})

const {
  request: getCampaignSummaryRequest,
  success: getCampaignSummarySuccess,
  failure: getCampaignSummaryFailure,
} = createAsyncActions({
  request: ACTION_GET_CAMPAIGN_SUMMARY_REQUEST,
  success: ACTION_GET_CAMPAIGN_SUMMARY_SUCCESS,
  failure: ACTION_GET_CAMPAIGN_SUMMARY_FAILURE,
})

const {
  request: saveCampaignRequest,
  success: saveCampaignSuccess,
  failure: saveCampaignFailure,
} = createAsyncActions({
  request: ACTION_SAVE_CAMPAIGN_REQUEST,
  success: ACTION_SAVE_CAMPAIGN_SUCCESS,
  failure: ACTION_SAVE_CAMPAIGN_FAILURE,
})

const {
  request: publishCampaignRequest,
  success: publishCampaignSuccess,
  failure: publishCampaignFailure,
} = createAsyncActions({
  request: ACTION_PUBLISH_CAMPAIGN_REQUEST,
  success: ACTION_PUBLISH_CAMPAIGN_SUCCESS,
  failure: ACTION_PUBLISH_CAMPAIGN_FAILURE,
})

const {
  request: openCampaignRequest,
  success: openCampaignSuccess,
  failure: openCampaignFailure,
} = createAsyncActions({
  request: ACTION_OPEN_CAMPAIGN_REQUEST,
  success: ACTION_OPEN_CAMPAIGN_SUCCESS,
  failure: ACTION_OPEN_CAMPAIGN_FAILURE,
})

const {
  request: abortCampaignRequest,
  success: abortCampaignSuccess,
  failure: abortCampaignFailure,
} = createAsyncActions({
  request: ACTION_ABORT_CAMPAIGN_REQUEST,
  success: ACTION_ABORT_CAMPAIGN_SUCCESS,
  failure: ACTION_ABORT_CAMPAIGN_FAILURE,
})

const {
  request: archiveCampaignRequest,
  success: archiveCampaignSuccess,
  failure: archiveCampaignFailure,
} = createAsyncActions({
  request: ACTION_ARCHIVE_CAMPAIGN_REQUEST,
  success: ACTION_ARCHIVE_CAMPAIGN_SUCCESS,
  failure: ACTION_ARCHIVE_CAMPAIGN_FAILURE,
})

const {
  request: unsubscribeFromCampaignRequest,
  success: unsubscribeFromCampaignSuccess,
  failure: unsubscribeFromCampaignFailure,
} = createAsyncActions({
  request: ACTION_UNSUBSCRIBE_FROM_CAMPAIGN_REQUEST,
  success: ACTION_UNSUBSCRIBE_FROM_CAMPAIGN_SUCCESS,
  failure: ACTION_UNSUBSCRIBE_FROM_CAMPAIGN_FAILURE,
})

const {
  request: subscribeToCampaignRequest,
  success: subscribeToCampaignSuccess,
  failure: subscribeToCampaignFailure,
} = createAsyncActions({
  request: ACTION_SUBSCRIBE_CONTACT_TO_CAMPAIGN_REQUEST,
  success: ACTION_SUBSCRIBE_CONTACT_TO_CAMPAIGN_SUCCESS,
  failure: ACTION_SUBSCRIBE_CONTACT_TO_CAMPAIGN_FAILURE,
})

const {
  request: subscribeGroupToCampaignRequest,
  success: subscribeGroupToCampaignSuccess,
  failure: subscribeGroupToCampaignFailure,
} = createAsyncActions({
  request: ACTION_SUBSCRIBE_GROUP_TO_CAMPAIGN_REQUEST,
  success: ACTION_SUBSCRIBE_GROUP_TO_CAMPAIGN_SUCCESS,
  failure: ACTION_SUBSCRIBE_GROUP_TO_CAMPAIGN_FAILURE,
})

const {
  request: subscribeSegmentToCampaignRequest,
  success: subscribeSegmentToCampaignSuccess,
  failure: subscribeSegmentToCampaignFailure,
} = createAsyncActions({
  request: ACTION_SUBSCRIBE_SEGMENT_TO_CAMPAIGN_REQUEST,
  success: ACTION_SUBSCRIBE_SEGMENT_TO_CAMPAIGN_SUCCESS,
  failure: ACTION_SUBSCRIBE_SEGMENT_TO_CAMPAIGN_FAILURE,
})

const {
  request: copyCampaignRequest,
  success: copyCampaignSuccess,
  failure: copyCampaignFailure,
} = createAsyncActions({
  request: ACTION_COPY_CAMPAIGN_REQUEST,
  success: ACTION_COPY_CAMPAIGN_SUCCESS,
  failure: ACTION_COPY_CAMPAIGN_FAILURE,
})

export const {
  request: getFileRequest,
  success: getFileSuccess,
  failure: getFileFailure,
} = createAsyncActions({
  request: ACTION_GET_CAMPAIGN_FILE_REQUEST,
  success: ACTION_GET_CAMPAIGN_FILE_SUCCESS,
  failure: ACTION_GET_CAMPAIGN_FILE_FAILURE,
})

const {
  request: saveFileRequest,
  success: saveFileSuccess,
  failure: saveFileFailure,
} = createAsyncActions({
  request: ACTION_SAVE_CAMPAIGN_FILE_REQUEST,
  success: ACTION_SAVE_CAMPAIGN_FILE_SUCCESS,
  failure: ACTION_SAVE_CAMPAIGN_FILE_FAILURE,
})

export {
  getCampaignsFailure,
  getCampaignsRequest,
  getCampaignsSuccess,
  saveCampaignFailure,
  saveCampaignRequest,
  saveCampaignSuccess,
}

const defaultPageSize = path(['modules', 'campaigns', 'pageSize', 'default'], config)

export const getCampaigns = preventParallel(
  ({ orgId, query = {} }) => `${orgId}-${toQuery(query)}`,
  ({ token, orgId, query = {} }) =>
    (dispatch) => {
      const {
        page = 1,
        size = defaultPageSize,
        templates = '',
        search,
        archived = false,
        aborted,
        open,
        published,
        launched,
      } = query
      dispatch(getCampaignsRequest({ query, filters: { archived, aborted, open, published, launched } }))
      return request.campaigns
        .getList({
          token,
          orgId,
          query,
          aborted,
          archived: archived === 'true' ? null : 'false',
          search,
          launched,
          open,
          published,
          templates,
        })
        .then(({ engagements = [], total = 0 } = {}) => {
          dispatch(
            getCampaignsSuccess({
              values: engagements,
              page,
              size,
              total,
            }),
          )
        })
        .catch((err) => {
          dispatch(
            getCampaignsFailure({
              status: err.status,
              message: ERROR_GET_CAMPAIGNS_SERVER,
            }),
          )
        })
    },
)

export const getCampaignItem =
  ({ token, orgId, itemId, history }) =>
  (dispatch) => {
    dispatch(getCampaignItemRequest({ id: itemId }))
    return request.campaigns
      .getItem({ token, orgId, engId: itemId })
      .then((res) => {
        dispatch(getCampaignItemSuccess(res))
      })
      .catch((err) => {
        dispatch(
          getCampaignItemFailure({
            status: err.status,
            message: ERROR_GET_CAMPAIGN_ITEM_SERVER,
          }),
        )
        const isNotFound = err.status === 404
        dispatch(
          createNotification({
            'get-campaign-item-failure': {
              type: 'error',
              disableAutoHide: true,
              message: isNotFound ? ERROR_GET_CAMPAIGN_ITEM_NOT_FOUND : ERROR_GET_CAMPAIGN_ITEM_SERVER,
            },
          }),
        )
        if (history && isNotFound) {
          history.push('/campaigns?page=1')
        }
      })
  }

export const getCampaignSummary =
  ({ token, orgId, itemId }) =>
  (dispatch) => {
    dispatch(getCampaignSummaryRequest({ id: itemId }))
    return request.campaigns
      .getSummary({ token, orgId, engId: itemId })
      .then((res) => {
        dispatch(getCampaignSummarySuccess(res))
      })
      .catch((err) => {
        dispatch(
          getCampaignSummaryFailure({
            status: err.status,
            message: ERROR_GET_CAMPAIGN_SUMMARY_SERVER,
          }),
        )
      })
  }

export const updateItem = (newItem) => ({
  type: ACTION_UPDATE_CAMPAIGN_ITEM,
  value: newItem,
})

export const setContactsSelection = (selection) => ({
  type: ACTION_CAMPAIGN_ITEM_CONTACTS_SET,
  value: selection,
})

export const setPublishCampaign =
  ({ token, orgId, itemId, isPublished, item }) =>
  (dispatch) => {
    dispatch(publishCampaignRequest({ id: itemId }))
    return request.campaigns
      .setPublishItem({
        token,
        orgId,
        engId: itemId,
        item: {
          published: isPublished,
        },
      })
      .then(() => {
        dispatch(
          publishCampaignSuccess({
            id: itemId,
            published: isPublished,
          }),
        )
        dispatch(
          createNotification({
            'publish-campaign': {
              type: 'success',
              message: isPublished ? 'Campaign published successfully.' : 'Campaign returned to draft successfully.',
            },
          }),
        )
        return { id: itemId, published: isPublished }
      })
      .catch((err) => {
        const errorMessage = processCampaignErrors(err, processErrors(err, ERROR_PUBLISH_CAMPAIGN_SERVER), item)
        dispatch(publishCampaignFailure({ id: itemId }))
        dispatch(
          createNotification({
            'publish-campaign': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const setOpenCampaign =
  ({ token, orgId, itemId, isOpen = true }) =>
  (dispatch) => {
    dispatch(openCampaignRequest({ id: itemId }))
    return request.campaigns
      .setOpenItem({ token, orgId, engId: itemId, item: { open: isOpen } })
      .then(() => {
        dispatch(
          openCampaignSuccess({
            id: itemId,
            open: isOpen,
          }),
        )
        return isOpen
      })
      .catch((err) => {
        dispatch(openCampaignFailure({ id: itemId }))
        dispatch(
          createNotification({
            'open-campaign': {
              type: 'error',
              message: processErrors(err, ERROR_OPEN_CAMPAIGN_SERVER),
            },
          }),
        )
        return err
      })
  }

export const setAbortCampaign =
  ({ token, orgId, itemId }) =>
  (dispatch) => {
    dispatch(abortCampaignRequest({ id: itemId }))
    return request.campaigns
      .setAbortItem({ token, orgId, engId: itemId })
      .then(() => {
        dispatch(
          abortCampaignSuccess({
            id: itemId,
            aborted: true,
          }),
        )
        dispatch(
          createNotification({
            'abort-campaign': {
              type: 'success',
              message: 'The campaign has been stopped.',
            },
          }),
        )
      })
      .catch((err) => {
        dispatch(abortCampaignFailure({ id: itemId }))
        dispatch(
          createNotification({
            'abort-campaign': {
              type: 'error',
              message: processErrors(err, ERROR_ABORT_CAMPAIGN_SERVER),
            },
          }),
        )
        return err
      })
  }

export const setArchivedCampaign =
  ({ token, orgId, itemId, isArchived }) =>
  (dispatch) => {
    dispatch(archiveCampaignRequest({ id: itemId }))
    return request.campaigns
      .setArchivedItem({
        token,
        orgId,
        engId: itemId,
        item: {
          archived: isArchived,
        },
      })
      .then(() => {
        dispatch(
          archiveCampaignSuccess({
            id: itemId,
            archived: isArchived,
          }),
        )
        dispatch(
          createNotification({
            'archive-campaign': {
              type: 'success',
              message: isArchived ? 'Campaign archived successfully.' : 'Campaign unarchived successfully.',
            },
          }),
        )
        return { id: itemId, archived: isArchived }
      })
      .catch((err) => {
        dispatch(archiveCampaignFailure({ id: itemId }))
        dispatch(
          createNotification({
            'archive-campaign': {
              type: 'error',
              message: processErrors(err, ERROR_ARCHIVE_CAMPAIGN_SERVER),
            },
          }),
        )
        return err
      })
  }

export const saveCampaign =
  ({ token, orgId, item = {}, toBePublished = false }) =>
  (dispatch) => {
    const { uiStore } = item
    let campaignItem = {
      ...item,
    }
    if (isCampaignClass(item)) {
      campaignItem = item.toDTO()
    } else {
      const variables = processVariables(item, processActionForSaving)
      campaignItem = {
        ...item,
        variables: {
          ...path(['variables'], item),
          senderId:
            pathOr('', ['variables', 'senderId'], item) === DEFAULT_SENDER_ID_VALUE
              ? ''
              : pathOr('', ['variables', 'senderId'], item),
          ...variables,
        },
        uiStore: {
          ...(uiStore || {}),
          newItems: [],
        },
      }
    }

    dispatch(saveCampaignRequest(campaignItem))
    return request.campaigns
      .saveItem({ token, orgId, item: campaignItem, engId: campaignItem.id })
      .then((res) => {
        if (isFailed(res)) {
          const errorMessage = processErrors(res, ERROR_SAVE_CAMPAIGN_SERVER)
          dispatch(
            saveCampaignFailure({
              status: res.status,
              message: errorMessage,
            }),
          )
          dispatch(
            createNotification({
              'save-campaign': {
                type: 'error',
                message: errorMessage,
              },
            }),
          )
          return new Error(errorMessage)
        }
        if (!toBePublished) {
          dispatch(
            createNotification({
              'save-campaign': {
                type: 'success',
                message: 'Campaign saved successfully.',
              },
            }),
          )
        }
        dispatch(saveCampaignSuccess(res))
        return res
      })
      .catch((err) => {
        const errorMessage = processCampaignErrors(err, processErrors(err, ERROR_SAVE_CAMPAIGN_SERVER), item)
        dispatch(
          saveCampaignFailure({
            status: err.status,
            message: ERROR_SAVE_CAMPAIGN_SERVER,
          }),
        )
        dispatch(
          createNotification({
            'save-campaign': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const subscribeToCampaign =
  ({ token, orgId, itemId, contactId, delay, startAt, t0, t0timezone }) =>
  (dispatch) => {
    dispatch(subscribeToCampaignRequest({ id: itemId, contactId }))
    return request.campaigns
      .subscribeToItem({
        token,
        orgId,
        engId: itemId,
        item: {
          contacts: {
            includedContactIds: [contactId],
          },
          allowResubscribe: true,
          delay,
          args: {
            startAt,
            t0,
            t0timezone,
          },
        },
      })
      .then(() => {
        dispatch(
          createNotification({
            'campaign-subscribe': {
              type: 'success',
              message: 'Contact successfully subscribed.',
            },
          }),
        )
        return dispatch(getContactsItem({ token, orgId, itemId: contactId, campaignId: itemId }))
      })
      .then(() => {
        dispatch(subscribeToCampaignSuccess({ id: itemId, contactId }))
        return { itemId, contactId }
      })
      .catch((err) => {
        dispatch(
          subscribeToCampaignFailure({
            id: itemId,
            contactId,
            status: err.status,
            message: ERROR_SUBSCRIBE_TO_CAMPAIGN_SERVER,
          }),
        )
        dispatch(
          createNotification({
            'campaign-subscribe': {
              type: 'error',
              message: processErrors(err, ERROR_SUBSCRIBE_TO_CAMPAIGN_SERVER),
            },
          }),
        )
        return err
      })
  }

export const unsubscribeFromCampaign =
  ({ token, orgId, itemId, item }) =>
  (dispatch) => {
    dispatch(unsubscribeFromCampaignRequest({ id: itemId, ...item }))
    const { includedGroupIds = [], includedContactIds = [] } = item

    return request.campaigns
      .unsubscribe({ token, orgId, engId: itemId, item: { contacts: item } })
      .then(() => {
        dispatch(
          createNotification({
            'unsubscribe-campaign': {
              type: 'success',
              message: `${includedGroupIds.length > 0 ? 'Group' : 'Contact'} unsubscribed from campaign successfully.`,
            },
          }),
        )
        dispatch(unsubscribeFromCampaignSuccess({ id: itemId, ...item }))
        if (includedContactIds.length > 0) {
          return dispatch(getContactsItem({ token, orgId, itemId: includedContactIds[0], campaignId: itemId }))
        }
        return { itemId, ...item }
      })
      .catch((err) => {
        dispatch(
          createNotification({
            'unsubscribe-campaign': {
              type: 'error',
              message: `A problem occured with trying to unsubscribe the ${
                includedGroupIds.length > 0 ? 'Group' : 'Contact'
              } from the campaign. Please try again later.`,
            },
          }),
        )
        dispatch(
          unsubscribeFromCampaignFailure({
            id: itemId,
            ...item,
          }),
        )
        return err
      })
  }

export const unsubscribeAllFromCampaign =
  ({ token, orgId, itemId }) =>
  (dispatch) => {
    dispatch(unsubscribeFromCampaignRequest({ id: itemId }))
    return request.campaigns
      .unsubscribe({
        token,
        orgId,
        engId: itemId,
        item: {
          contacts: {
            allContactsAreSelected: true,
          },
        },
      })
      .then(() => {
        dispatch(
          createNotification({
            'unsubscribe-campaign': {
              type: 'success',
              message: 'All contacts unsubscribed from campaign successfully.',
            },
          }),
        )
        dispatch(unsubscribeFromCampaignSuccess({ id: itemId }))
        return { id: itemId }
      })
      .catch((err) => {
        dispatch(
          createNotification({
            'unsubscribe-campaign': {
              type: 'error',
              message:
                'A problem occured with trying to unsubscribe all contacts from the campaign. Please try again later.',
            },
          }),
        )
        dispatch(
          unsubscribeFromCampaignFailure({
            id: itemId,
          }),
        )
        return err
      })
  }

export const subscribeGroupToCampaign =
  ({ token, orgId, itemId, groupId, allowResubscribe, delay, startAt, t0, t0timezone }) =>
  (dispatch) => {
    dispatch(subscribeGroupToCampaignRequest({ id: itemId, groupId }))
    return request.campaigns
      .subscribeToItem({
        token,
        orgId,
        engId: itemId,
        item: {
          contacts: {
            includedGroupIds: [groupId],
          },
          allowResubscribe,
          delay,
          args: {
            startAt,
            t0,
            t0timezone,
          },
        },
      })
      .then(() => {
        dispatch(subscribeGroupToCampaignSuccess({ id: itemId, groupId }))
        dispatch(
          createNotification({
            'campaign-subscribe': {
              type: 'success',
              message: 'Group successfully subscribed.',
            },
          }),
        )
        return { itemId, groupId }
      })
      .catch((err) => {
        dispatch(
          subscribeGroupToCampaignFailure({
            id: itemId,
            groupId,
            status: err.status,
            message: ERROR_SUBSCRIBE_GROUP_TO_CAMPAIGN_SERVER,
          }),
        )
        dispatch(
          createNotification({
            'campaign-subscribe': {
              type: 'error',
              message: processErrors(err, ERROR_SUBSCRIBE_GROUP_TO_CAMPAIGN_SERVER),
            },
          }),
        )
        return err
      })
  }

export const subscribeSegmentToCampaign =
  ({ token, orgId, itemId, segmentId, allowResubscribe, delay, startAt, t0, t0timezone }) =>
  (dispatch) => {
    dispatch(subscribeSegmentToCampaignRequest({ id: itemId, segmentId }))
    return request.campaigns
      .subscribeToItem({
        token,
        orgId,
        engId: itemId,
        item: {
          contacts: {
            includedGroupIds: [segmentId],
          },
          allowResubscribe,
          delay,
          args: {
            startAt,
            t0,
            t0timezone,
          },
        },
      })
      .then(() => {
        dispatch(subscribeSegmentToCampaignSuccess({ id: itemId, segmentId }))
        dispatch(
          createNotification({
            'campaign-subscribe': {
              type: 'success',
              message: 'Segment successfully subscribed.',
            },
          }),
        )
        return { itemId, segmentId }
      })
      .catch((err) => {
        dispatch(
          subscribeSegmentToCampaignFailure({
            id: itemId,
            segmentId,
            status: err.status,
            message: ERROR_SUBSCRIBE_SEGMENT_TO_CAMPAIGN_SERVER,
          }),
        )
        dispatch(
          createNotification({
            'campaign-subscribe': {
              type: 'error',
              message: processErrors(err, ERROR_SUBSCRIBE_SEGMENT_TO_CAMPAIGN_SERVER),
            },
          }),
        )
        return err
      })
  }

export const copyCampaign =
  ({ token, orgId, itemId }) =>
  (dispatch) => {
    dispatch(copyCampaignRequest({ id: itemId }))
    return request.campaigns
      .copyItem({ token, orgId, engId: itemId })
      .then(({ engagementId }) => {
        dispatch(copyCampaignSuccess({ id: engagementId }))
        return { id: engagementId }
      })
      .catch((err) => {
        dispatch(
          copyCampaignFailure({
            status: err.status,
            message: processErrors(err, ERROR_COPY_CAMPAIGN_SERVER),
          }),
        )
        dispatch(
          createNotification({
            'abort-campaign': {
              type: 'error',
              message: processErrors(err, ERROR_COPY_CAMPAIGN_SERVER),
            },
          }),
        )
        return err
      })
  }

export const navigateCampaigns = ({ history, page = 1, archived = 'false' } = {}) => {
  if (history) {
    history.push(`/campaigns?page=${page}&archived=${archived}`)
  }

  return {
    type: ACTION_CAMPAIGNS_NAVIGATE,
    value: {
      page,
      archived,
    },
  }
}

export const exportCampaignReport =
  ({ token, orgId, itemId, tz, fields, labels }) =>
  (dispatch) => {
    dispatch({
      type: ACTION_EXPORT_CAMPAIGN_REPORT,
      value: {
        engId: itemId,
        orgId,
      },
    })
    return request.campaigns
      .exportReport({ token, orgId, engId: itemId, tz, fields, labels })
      .then(() => {
        dispatch(
          createNotification({
            'export-campaign-report-pending': {
              type: 'success',
              message: EXPORT_CAMPAIGN_REPORT_PENDING,
            },
          }),
        )
      })
      .catch((err) => {
        dispatch(
          createNotification({
            'export-campaign-report-error': {
              type: 'error',
              message: processErrors(err, ERROR_EXPORT_CAMPAIGN_REPORT_SERVER),
            },
          }),
        )
        return err
      })
  }

export const getCampaignsFile =
  ({ token, orgId, msgId, file }) =>
  (dispatch) => {
    dispatch(getFileRequest({ id: msgId, file }))

    return getFilesItem({ token, orgId, name: file })(dispatch)
      .then((res) => {
        if (res instanceof Error) {
          throw res
        }
        dispatch(
          getFileSuccess({
            id: msgId,
            filename: file,
            url: res.url,
          }),
        )
        return res
      })
      .catch((err) => {
        getFileFailure({
          id: msgId,
          status: err.status,
          message: err.message,
        })
        return err
      })
  }

export const saveCampaignsFile =
  ({ token, orgId, msgId, item }) =>
  (dispatch) => {
    dispatch(saveFileRequest({ id: msgId }))

    return saveFile({
      token,
      orgId,
      item: {
        ...item,
      },
    })(dispatch)
      .then((res = {}) => {
        if (res instanceof Error) {
          throw res
        }
        const dlPath = http.files.get.getSignablePath({ orgId, name: res.filename })
        const data = {
          paths: [dlPath],
        }
        return Promise.all([res, dlPath, request.auth.sign({ token, item: data })])
      })
      .then(([{ filename, title }, signedPath, signedRes]) => {
        const result = {
          id: msgId,
          filename,
          title,
          url: http.files.get.getPath({
            signedPath,
            urlToken: signedRes[signedPath],
          }),
        }

        dispatch(saveFileSuccess(result))
        return result
      })
      .catch((err) => {
        dispatch(
          saveFileFailure({
            id: msgId,
            status: err.status,
            message: err.message,
          }),
        )
        return err
      })
  }

export const clearCampaignFiles = (id) => ({
  type: ACTION_CAMPAIGN_FILE_RESET,
  value: { id },
})

export const getCampaignsPageSize = () => (dispatch) => {
  const size = getPageSize({ defaultValue: defaultPageSize, namespace: 'campaigns' })

  return dispatch({
    type: ACTION_CAMPAIGNS_PAGE_SIZE,
    value: size,
  })
}

export const setCampaignsPageSize =
  (size = defaultPageSize) =>
  (dispatch) => {
    setPageSize({ namespace: 'campaigns', value: size })

    return dispatch({
      type: ACTION_CAMPAIGNS_PAGE_SIZE,
      value: size,
    })
  }

export const setChanged = (changed) => (dispatch) =>
  dispatch({
    type: ACTION_CAMPAIGNS_SET_CHANGED,
    value: changed,
  })
