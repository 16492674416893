import { compose, map, path, pathOr } from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import config from '@/config'
import { MIC_ACCESS_ERROR } from '@/app/definitions'
import { selectToken } from '@/app/module/auth/store/selectors'
import { getContacts, navigateContacts } from '@/app/module/contacts/store/actions'
import { selectOrdered as selectContactsOrdered } from '@/app/module/contacts/store/selectors'
import { getCustomFields } from '@/app/module/custom-fields/store/actions'
import { getGroups, navigateGroups } from '@/app/module/groups/store/actions'
import {
  selectPage as selectAllGroupTypes,
  selectOrdered as selectAllGroupTypesOrdered,
  selectItemConfig as selectGroupTypesItemConfig,
} from '@/app/module/groups/store/selectors'
import { getSenderIdList } from '@/app/module/logs/store/actions'
import { selectPersonalizationList } from '@/app/module/logs/store/selectors'
import { createNotification } from '@/app/module/notifications/store/actions'
import { getSegments, navigateSegments } from '@/app/module/segments/store/actions'
import {
  selectPage as selectAllSegmentTypes,
  selectOrdered as selectAllSegmentTypesOrdered,
  selectItemConfig as selectSegmentTypesItemConfig,
} from '@/app/module/segments/store/selectors'
import { getSnippets, saveSnippet } from '@/app/module/user/store/actions'
import { selectOrgId, selectUserId, selectUserTimezone } from '@/app/module/user/store/selectors'
import {
  selectCountriesTimezones,
  selectDefaultCountryCode,
  selectLanguages,
  selectSpeechLanguages,
  selectVoices,
} from '@/app/module/utils/store/selectors'
import Campaigns from './component'
import Item from './component/item'
import Manage from './component/item/manage'
import Summary from './component/item/summary'
import {
  clearCampaignFiles,
  copyCampaign,
  exportCampaignReport,
  getCampaignItem,
  getCampaignSummary,
  getCampaigns,
  getCampaignsFile,
  navigateCampaigns,
  saveCampaign,
  saveCampaignsFile,
  setAbortCampaign,
  setArchivedCampaign,
  setCampaignsPageSize,
  setChanged,
  setContactsSelection,
  setOpenCampaign,
  setPublishCampaign,
  subscribeGroupToCampaign,
  subscribeSegmentToCampaign,
  subscribeToCampaign,
  unsubscribeAllFromCampaign,
  unsubscribeFromCampaign,
  updateItem,
} from './store/actions'
import {
  selectCampaignContactConfig,
  selectCampaignIds,
  selectCampaignItem,
  selectCampaignItemConfig,
  selectCampaignSummary,
  selectCampaigns,
  selectContactsHeaderIcon,
  selectFilesState,
  selectGroupsHeaders,
  selectHeaders,
  selectManageCampaignContactConfig,
  selectMessagesState,
  selectOrdered,
  selectSegmentsHeaders,
} from './store/selectors'
import defaultState from './store/state'
import { resumeSubscription } from './store/ts/actions'
import { selectContactsTableData, selectContactsHeaders, selectManageContactsHeaders } from './store/ts/selectors'

const pageSizeDefault = path(['modules', 'campaigns', 'pageSize', 'default'], config)
const pageSizeValues = compose(
  map((val) => ({ label: val, value: val })),
  path(['modules', 'campaigns', 'pageSize', 'values']),
)(config)

const selector = ({ auth, customFields, campaigns, user, utils }) => ({
  hydrateProps: ['token', 'orgId'],
  error: {
    ...user.details.error,
    ...campaigns.list.error,
  },
  loading:
    !campaigns.list.initialized ||
    campaigns.list.loading ||
    user.details.loading ||
    user.organization.loading ||
    customFields.loading,
  timezone: selectUserTimezone({ user, utils }),
  timezones: pathOr({}, ['countriesTimezonesList', 'data'], utils),
  countriesTimezones: selectCountriesTimezones({ utils }),
  loadingItems: pathOr([], ['list', 'loadingItems'], campaigns),
  token: selectToken({ auth }),
  orgId: selectOrgId({ user }),
  userId: selectUserId({ user }),
  total: pathOr(0, ['list', 'data', 'total'], campaigns),
  query: {
    page: pathOr(1, ['list', 'data', 'page'], campaigns),
    size: pathOr(pageSizeDefault, ['list', 'data', 'size'], campaigns),
    aborted: pathOr('true', ['list', 'data', 'aborted'], campaigns),
    archived: pathOr('false', ['list', 'data', 'archived'], campaigns),
    launched: pathOr('true', ['list', 'data', 'launched'], campaigns),
    open: pathOr('true', ['list', 'data', 'open'], campaigns),
    published: pathOr('true', ['list', 'data', 'published'], campaigns),
    search: pathOr('', ['list', 'data', 'search'], campaigns),
    templates: pathOr('true', ['list', 'data', 'templates'], campaigns),
  },
  pageSizeValues,
  headers: selectHeaders(),
  ordered: selectOrdered({ campaigns }),
  data: selectCampaigns({ campaigns }),
  specs: selectCampaignItemConfig({ user, utils }),
  modErrors: {},
  personalizationList: selectPersonalizationList({ customFields }),
})

const dispatcher = (dispatch) =>
  bindActionCreators(
    {
      hydrate: getCampaigns,
      getCampaigns,
      createNotification,
      setPublishItem: setPublishCampaign,
      setOpenItem: setOpenCampaign,
      setAbortItem: setAbortCampaign,
      setArchivedItem: setArchivedCampaign,
      copyItem: copyCampaign,
      downloadCampaignReport: exportCampaignReport,
      navigate: navigateCampaigns,
      setPageSize: setCampaignsPageSize,
      getCustomFields,
      unsubscribeAll: unsubscribeAllFromCampaign,
    },
    dispatch,
  )

export default connect(selector, dispatcher)(withRouter(Campaigns))

const itemSelector = ({ auth, user, campaigns, contacts, customFields, logs, groups, segments, utils }) => ({
  hydrateProps: ['token', 'orgId', 'itemId'],
  campaignHydrateProps: ['token', 'orgId'],
  error: {
    ...user.details.error,
    ...campaigns.item.error,
  },
  loading: pathOr(true, ['item', 'loading'], campaigns),
  token: selectToken({ auth }),
  orgId: selectOrgId({ user }),
  page: pathOr(1, ['list', 'data', 'page'], campaigns),
  item: selectCampaignItem({ campaigns }),
  changed: pathOr(false, ['item', 'changed'], campaigns),
  timezone: selectUserTimezone({ user, utils }),
  timezones: pathOr({}, ['countriesTimezonesList', 'data'], utils),
  countriesTimezones: selectCountriesTimezones({ utils }),
  countryCode: selectDefaultCountryCode({ utils }),
  campaignIds: selectCampaignIds({ campaigns }),
  headerIcon: selectContactsHeaderIcon({ campaigns, contacts }),
  messages: selectMessagesState({ customFields, logs }),
  contacts: {
    loading: !contacts.list.initialized || contacts.list.loading,
    error: contacts.list.error,
    data: selectContactsTableData({ contacts }),
    headers: selectContactsHeaders(),
    ordered: selectContactsOrdered({ contacts }),
    specs: selectCampaignContactConfig(),
    total: pathOr(0, ['list', 'data', 'total'], contacts),
    query: {
      page: pathOr(1, ['list', 'data', 'page'], contacts),
      size: pathOr(0, ['list', 'data', 'size'], contacts),
      searchTerm: pathOr('', ['list', 'data', 'searches', 'active'], contacts),
    },
  },
  groups: {
    loading: pathOr(false, ['list', 'loading'], groups),
    error: pathOr({}, ['list', 'error'], groups),
    data: selectAllGroupTypes({ groups, user }),
    headers: selectGroupsHeaders(),
    ordered: selectAllGroupTypesOrdered({ groups }),
    specs: selectGroupTypesItemConfig({ user }),
    total: pathOr(0, ['list', 'data', 'total'], groups),
    query: {
      page: pathOr(1, ['list', 'data', 'page'], groups),
      size: pathOr(0, ['list', 'data', 'size'], groups),
      searchTerm: pathOr('', ['list', 'data', 'search'], groups),
    },
  },
  segments: {
    loading: pathOr(false, ['list', 'loading'], segments),
    error: pathOr({}, ['list', 'error'], segments),
    data: selectAllSegmentTypes({ segments, user }),
    headers: selectSegmentsHeaders(),
    ordered: selectAllSegmentTypesOrdered({ segments }),
    specs: selectSegmentTypesItemConfig({ user }),
    total: pathOr(0, ['list', 'data', 'total'], segments),
    query: {
      page: pathOr(1, ['list', 'data', 'page'], segments),
      size: pathOr(0, ['list', 'data', 'size'], segments),
      searchTerm: pathOr('', ['list', 'data', 'search'], segments),
    },
  },
  files: selectFilesState({ campaigns }),
  snippets: pathOr({}, ['snippets'], user),
  languageList: selectLanguages({ utils }),
  voiceList: selectVoices({ utils }),
  speechLanguageList: selectSpeechLanguages({ utils }),
})

const itemDispatcher = (dispatch) =>
  bindActionCreators(
    {
      hydrate:
        ({ token, orgId, itemId, history }) =>
        () => {
          dispatch(updateItem())
          return Promise.all([dispatch(getCampaignItem({ token, orgId, itemId, history }))])
        },
      campaignHydrate:
        ({ token, orgId }) =>
        () => {
          dispatch(navigateContacts({}))
          dispatch(navigateGroups({}))
          dispatch(navigateSegments({}))
          dispatch(getCustomFields({ token, orgId }))
          return Promise.all([
            dispatch(getCustomFields({ token, orgId })),
            dispatch(getSenderIdList({ token, orgId })),
            dispatch(getContacts({ token, orgId })),
            dispatch(getSnippets({ token, orgId })),
            dispatch(
              getGroups({
                token,
                orgId,
                query: {
                  page: 1,
                  size: path(['modules', 'groups', 'pageSize', 'default'], config),
                },
              }),
            ),
            dispatch(
              getSegments({
                token,
                orgId,
                query: {
                  page: 1,
                  size: path(['modules', 'segments', 'pageSize', 'default'], config),
                },
              }),
            ),
          ])
        },
      saveCampaign,
      setPublishItem: setPublishCampaign,
      updateItem,
      clearItem: () => () => {
        dispatch(updateItem(defaultState.item))
        dispatch(navigateContacts({}))
      },
      getCustomFields,
      getContacts,
      navigateContacts,
      getGroups,
      navigateGroups,
      getSegments,
      navigateSegments,
      setContactsSelection,
      createNotification,
      getFile: getCampaignsFile,
      saveFile: saveCampaignsFile,
      getCampaigns,
      getSnippets,
      saveSnippet,
      onMicAccessError: () =>
        dispatch(
          createNotification({
            'mic-access': {
              type: 'error',
              message: MIC_ACCESS_ERROR,
            },
          }),
        ),
      setChanged,
    },
    dispatch,
  )

export const CampaignsItem = connect(itemSelector, itemDispatcher)(Item)

const manageSelector = ({ auth, user, campaigns, contacts, files, groups, segments, utils }) => ({
  hydrateProps: ['token', 'orgId', 'itemId'],
  error: {
    ...user.details.error,
    ...campaigns.item.error,
  },
  loading: pathOr(true, ['item', 'loading'], campaigns),
  token: selectToken({ auth }),
  orgId: selectOrgId({ user }),
  timezone: selectUserTimezone({ user, utils }),
  timezones: pathOr({}, ['countriesTimezonesList', 'data'], utils),
  countriesTimezones: selectCountriesTimezones({ utils }),
  countryCode: selectDefaultCountryCode({ utils }),
  item: selectCampaignItem({ campaigns }),
  page: pathOr(1, ['list', 'data', 'page'], campaigns),
  contacts: {
    loading: contacts.list.loading,
    loadingItems: campaigns.contacts.loadingItems,
    error: contacts.list.error,
    data: selectContactsTableData({ contacts }),
    headers: selectManageContactsHeaders(),
    ordered: selectContactsOrdered({ contacts }),
    specs: selectManageCampaignContactConfig(),
    total: pathOr(0, ['list', 'data', 'total'], contacts),
    query: {
      page: pathOr(1, ['list', 'data', 'page'], contacts),
      size: path(['modules', 'contacts', 'pageSize', 'default'], config),
      searchTerm: pathOr('', ['list', 'data', 'searches', 'active'], contacts),
      subscriptionFilter: path(['list', 'data', 'subscriptionFilter'], contacts),
      subscriptionStatus: path(['list', 'data', 'subscriptionStatus'], contacts),
    },
  },
  groups: {
    loading: pathOr(false, ['list', 'loading'], groups),
    loadingItems: campaigns.groups.loadingItems,
    error: pathOr({}, ['list', 'error'], groups),
    data: selectAllGroupTypes({ groups, user }),
    headers: selectGroupsHeaders(),
    ordered: selectAllGroupTypesOrdered({ groups }),
    specs: selectGroupTypesItemConfig({ user }),
    total: pathOr(0, ['list', 'data', 'total'], groups),
    query: {
      page: pathOr(1, ['list', 'data', 'page'], groups),
      size: 30,
      searchTerm: pathOr('', ['list', 'data', 'search'], groups),
    },
  },
  segments: {
    loading: pathOr(false, ['list', 'loading'], segments),
    loadingItems: campaigns.segments.loadingItems,
    error: pathOr({}, ['list', 'error'], segments),
    data: selectAllSegmentTypes({ segments, user }),
    headers: selectSegmentsHeaders(),
    ordered: selectAllSegmentTypesOrdered({ segments }),
    specs: selectSegmentTypesItemConfig({ user }),
    total: pathOr(0, ['list', 'data', 'total'], segments),
    query: {
      page: pathOr(1, ['list', 'data', 'page'], segments),
      size: 30,
      searchTerm: pathOr('', ['list', 'data', 'search'], segments),
    },
  },
  upload: {
    loading: pathOr(false, ['item', 'loading'], files),
    error: pathOr({}, ['item', 'error'], files),
    data: pathOr({}, ['item', 'data'], files),
  },
})

const manageDispatcher = (dispatch) =>
  bindActionCreators(
    {
      hydrate:
        ({ token, orgId, itemId, history }) =>
        () =>
          Promise.all([
            dispatch(getCampaignItem({ token, orgId, itemId, history })),
            dispatch(getCustomFields({ token, orgId })),
            dispatch(getSenderIdList({ token, orgId })),
          ]),
      clearItem: () => () => {
        dispatch(updateItem(defaultState.item))
        dispatch(clearCampaignFiles())
        dispatch(navigateContacts({}))
        dispatch(navigateGroups({}))
        dispatch(navigateSegments({}))
      },
      resumeSubscription,
      subscribeContact: subscribeToCampaign,
      subscribeGroup: subscribeGroupToCampaign,
      subscribeSegment: subscribeSegmentToCampaign,
      unsubscribe: unsubscribeFromCampaign,
      getContacts,
      getGroups,
      getSegments,
      navigateGroups,
      navigateSegments,
      navigateContacts,
      createNotification,
    },
    dispatch,
  )

export const CampaignManager = connect(manageSelector, manageDispatcher)(Manage)

const summarySelector = ({ auth, user, campaigns, contacts, files, groups, segments, utils }) => ({
  hydrateProps: ['token', 'orgId', 'itemId'],
  error: {
    ...user.details.error,
    ...campaigns.item.error,
  },
  loading: pathOr(true, ['item', 'loading'], campaigns),
  token: selectToken({ auth }),
  orgId: selectOrgId({ user }),
  timezone: selectUserTimezone({ user, utils }),
  timezones: selectCountriesTimezones({ utils }),
  item: selectCampaignItem({ campaigns }),
  itemSummary: selectCampaignSummary({ campaigns }),
  page: pathOr(1, ['list', 'data', 'page'], campaigns),
  contacts: {
    loading: contacts.list.loading,
    loadingItems: campaigns.contacts.loadingItems,
    error: contacts.list.error,
    data: selectContactsTableData({ contacts }),
    headers: selectManageContactsHeaders(),
    ordered: selectContactsOrdered({ contacts }),
    specs: selectManageCampaignContactConfig(),
    total: pathOr(0, ['list', 'data', 'total'], contacts),
    query: {
      page: pathOr(1, ['list', 'data', 'page'], contacts),
      size: pathOr(0, ['list', 'data', 'size'], contacts),
      searchTerm: pathOr('', ['list', 'data', 'searches', 'active'], contacts),
      subscriptionFilter: path(['list', 'data', 'subscriptionFilter'], contacts),
      subscriptionStatus: path(['list', 'data', 'subscriptionStatus'], contacts),
    },
  },
  groups: {
    loading: pathOr(false, ['list', 'loading'], groups),
    loadingItems: campaigns.groups.loadingItems,
    error: pathOr({}, ['list', 'error'], groups),
    data: selectAllGroupTypes({ groups, user }),
    headers: selectGroupsHeaders(),
    ordered: selectAllGroupTypesOrdered({ groups }),
    specs: selectGroupTypesItemConfig({ user }),
    total: pathOr(0, ['list', 'data', 'total'], groups),
    query: {
      page: pathOr(1, ['list', 'data', 'page'], groups),
      size: 30,
      searchTerm: pathOr('', ['list', 'data', 'search'], groups),
    },
  },
  segments: {
    loading: pathOr(false, ['list', 'loading'], segments),
    loadingItems: campaigns.segments.loadingItems,
    error: pathOr({}, ['list', 'error'], segments),
    data: selectAllSegmentTypes({ segments, user }),
    headers: selectSegmentsHeaders(),
    ordered: selectAllSegmentTypesOrdered({ segments }),
    specs: selectSegmentTypesItemConfig({ user }),
    total: pathOr(0, ['list', 'data', 'total'], segments),
    query: {
      page: pathOr(1, ['list', 'data', 'page'], segments),
      size: 30,
      searchTerm: pathOr('', ['list', 'data', 'search'], segments),
    },
  },
  upload: {
    loading: pathOr(false, ['item', 'loading'], files),
    error: pathOr({}, ['item', 'error'], files),
    data: pathOr({}, ['item', 'data'], files),
  },
})

const summaryDispatcher = (dispatch) =>
  bindActionCreators(
    {
      hydrate:
        ({ token, orgId, itemId, history }) =>
        () =>
          Promise.all([
            dispatch(getCampaignItem({ token, orgId, itemId, history })),
            dispatch(getCampaignSummary({ token, orgId, itemId })),
            dispatch(getCustomFields({ token, orgId })),
            dispatch(getSenderIdList({ token, orgId })),
            dispatch(getContacts({ token, orgId })),
            dispatch(
              getGroups({
                token,
                orgId,
                query: {
                  page: 1,
                  size: path(['modules', 'groups', 'pageSize', 'default'], config),
                },
              }),
            ),
            dispatch(
              getSegments({
                token,
                orgId,
                query: {
                  page: 1,
                  size: path(['modules', 'segments', 'pageSize', 'default'], config),
                },
              }),
            ),
          ]),
      clearItem: () => () => {
        dispatch(updateItem(defaultState.item))
        dispatch(clearCampaignFiles())
        dispatch(navigateContacts({}))
        dispatch(navigateGroups({}))
        dispatch(navigateSegments({}))
      },
      resumeSubscription,
      subscribeContact: subscribeToCampaign,
      subscribeGroup: subscribeGroupToCampaign,
      subscribeSegment: subscribeSegmentToCampaign,
      unsubscribe: unsubscribeFromCampaign,
      getCampaignSummary,
      getCampaignItem,
      getContacts,
      getGroups,
      getSegments,
      navigateGroups,
      navigateSegments,
      navigateContacts,
      createNotification,
    },
    dispatch,
  )

export const CampaignSummary = connect(summarySelector, summaryDispatcher)(Summary)
