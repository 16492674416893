import EditIcon from '@mui/icons-material/Edit'
import SpeakerIcon from '@mui/icons-material/VolumeUp'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import AudioPlayer from '@/app/component/atom/audio-player'
import Tooltip from '@/app/component/atom/tooltip'
import Message from '@/app/module/logs/component/send/message'
import { BaseLanguageType } from '@/app/module/campaigns/helpers'
import { PersonalizationType } from '@/app/module/campaigns/types'
import { useDebouncedFn } from '@/app/helpers'
import { SearchableSelect } from '@/app/component/atom/form'
import { SelectOption } from '@/app/types'
import { useCreateSpeechPreview } from '@/app/module/campaigns/store/ts/hooks'

type Props = {
  activeLanguage?: string
  autoFocus?: boolean
  debounceChange?: boolean
  message: string
  personalizationList?: PersonalizationType
  selectedVoice: string
  voiceList: BaseLanguageType[]

  onChange: (message: any) => void
  onVoiceChange: (voice: string) => void
}

const CampaignContentTTS: React.FC<Props> = ({
  activeLanguage,
  autoFocus,
  debounceChange = true,
  message,
  personalizationList = {},
  selectedVoice,
  voiceList,
  onChange,
  onVoiceChange,
}) => {
  const [localMessage, setLocalMessage] = useState(message)
  const [previewing, setPreviewing] = useState(false)
  const { classes } = useStyles()

  const [createSpeechPreview, { data, isLoading }] = useCreateSpeechPreview()

  const voiceOptions = useMemo(
    () => [
      ...voiceList.map((voice) => ({
        label: voice.name,
        value: voice.value,
      })),
    ],
    [voiceList],
  )

  const icon = useMemo(() => {
    if (isLoading) {
      return <CircularProgress size={20} />
    }

    if (previewing) {
      return <EditIcon />
    }

    return <SpeakerIcon />
  }, [isLoading, previewing])

  const buttonDisabled = (!previewing && (!message || !selectedVoice)) || isLoading

  const previewUrl = useMemo(() => data && URL.createObjectURL(data), [data])
  const displayPreview = previewing && !isLoading && !!previewUrl

  useEffect(() => {
    setPreviewing((s) => {
      if (s) {
        return false
      }
      return s
    })
  }, [activeLanguage])

  useEffect(() => {
    setLocalMessage((s) => {
      if (s !== message) {
        return message
      }
      return s
    })
  }, [message])

  const itemRef = React.useRef<{ message: string } | null>(null)
  const onChangeRef = React.useRef(onChange) // using ref so I can ignore it in the useEffect's deps below

  useEffect(
    () => () => {
      if (itemRef.current) {
        onChangeRef.current?.(itemRef.current)
      }
    },
    [],
  )

  const change = React.useCallback(
    (value: { message: string }) => {
      onChange(value)
      itemRef.current = null
    },
    [onChange],
  )
  const debouncedOnChange = useDebouncedFn(change, 300)
  const handleTextChange = React.useCallback(
    (value: { message: string }) => {
      setLocalMessage(value.message)
      if (debounceChange) {
        itemRef.current = value
        debouncedOnChange(value)
      } else {
        onChange(value)
      }
    },
    [debounceChange, debouncedOnChange, onChange],
  )
  const handlePreviewToggle = React.useCallback(async () => {
    if (previewing) {
      setPreviewing(false)
      return
    }
    const success = await createSpeechPreview(selectedVoice, localMessage)
    setPreviewing(success)
  }, [localMessage, previewing, selectedVoice, createSpeechPreview])

  const handleVoiceChange = React.useCallback((value: string) => onVoiceChange(value), [onVoiceChange])

  return (
    <div data-testid="campaign-content-text-to-speech">
      <SearchableSelect
        className="tts-voice"
        disabled={previewing}
        hideRemoveSelection={!selectedVoice}
        label="Select voice to speak your message"
        showAll
        value={selectedVoice}
        options={voiceOptions}
        onChange={handleVoiceChange}
        keyPropFn={(option: SelectOption<string>) => option.value}
        valuePropFn={(option: SelectOption<string>) => option.label}
      />
      <Message
        additionalButtons={
          <div className={classes.row}>
            <Tooltip title={previewing ? 'Edit' : 'Play'}>
              <div>
                <Button
                  className={classes.button}
                  color="primary"
                  data-testid="tts-play-button"
                  disabled={buttonDisabled}
                  variant="outlined"
                  onClick={handlePreviewToggle}
                >
                  {icon}
                </Button>
              </div>
            </Tooltip>
            {displayPreview && <AudioPlayer playId={previewUrl} source={previewUrl} />}
          </div>
        }
        autoFocus={autoFocus}
        editable={!previewing}
        error=""
        hideInfo
        label="Message"
        message={localMessage}
        personalizationList={personalizationList}
        onChange={handleTextChange}
      />
    </div>
  )
}

const useStyles = makeStyles()({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    minWidth: 0,
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    padding: 0,
  },
})

export default CampaignContentTTS
