import { createSelector } from 'reselect'
import { CommonUserType, UserDetailsState } from '@/app/module/user/types'
import { selectUserDetails } from '@/app/module/user/store/selectors'
import { OrgPlan } from '@/app/module/plans/types'
import { RootState } from '@/app/store'

export const selectUserDetailsState = (state: { user: { details: UserDetailsState } }) => state.user.details

export const selectUserInit = createSelector(selectUserDetailsState, (details: UserDetailsState) => details.init)

export const selectUserLoading = createSelector(selectUserDetailsState, (details: UserDetailsState) => details.loading)

export const selectSkippingTours = createSelector(selectUserDetails, (user: CommonUserType | string) => {
  if (typeof user === 'string') {
    return {}
  }

  return user.uiStore?.skippingTours || {}
})

export const selectOrgPlan = (state: RootState): OrgPlan | undefined => state.plans.item.data
export const selectInvitationsCount = (state: RootState): number => state.user.invitations.data?.length || 0

export const selectCanInviteMoreUsers = createSelector(
  selectOrgPlan,
  selectInvitationsCount,
  (plan, invitations) => plan && plan.usersNow + invitations < plan.usersIncluded,
)
