import { ContentAudio, LocalOnAnswerType, OnAnswerType, SpeechSettings } from '@/app/module/campaigns/types'
import { unwrapActions, wrapActions } from '@/app/module/campaigns/utils/actions/wrap'

export type SpeechValue = {
  onAnswer: OnAnswerType[]
  processingReply?: ContentAudio
  speechSettings: SpeechSettings
  useAi: boolean
}

export type SpeechValueState = {
  onAnswer: LocalOnAnswerType[]
  processingReply?: ContentAudio
  speechSettings: SpeechSettings
  useAi: boolean
}

const fromOnAnswer = (onAnswer: OnAnswerType[]): LocalOnAnswerType[] =>
  onAnswer.map((answer) => ({
    ...answer,
    actions: unwrapActions(answer.actions || []),
  }))

const toOnAnswer = (onAnswer: LocalOnAnswerType[]): OnAnswerType[] =>
  onAnswer.map((answer) => ({
    ...answer,
    actions: wrapActions(answer.actions),
  }))

export const fromSpeechValue = (value: SpeechValue): SpeechValueState => ({
  onAnswer: fromOnAnswer(value.onAnswer),
  processingReply: value.processingReply,
  speechSettings: value.speechSettings,
  useAi: value.useAi,
})

export const toSpeechValue = (state: SpeechValueState): SpeechValue => ({
  onAnswer: toOnAnswer(state.onAnswer),
  processingReply: state.useAi ? state.processingReply : undefined, // Clear processing reply if not using AI
  speechSettings: state.speechSettings,
  useAi: state.useAi,
})
