import { DRAGTYPE, SMS_ACTION_DEFAULT_SENDERID } from '@/app/module/campaigns/definitions'
import { ChoiceListType, LocalAction, LocalOnAnswerType, RangeType } from '@/app/module/campaigns/types'
import { getActionOptionsForCampaign } from '@/app/module/campaigns/utils/actions/options'
import React from 'react'
import { ActionProps, ApiProps, SnippetProps } from './part-config-props'
import CampaignContentQuestionBox from './question'
import InputChoice from './question/choice/input-choice'

type Props = {
  actionOptions: ReturnType<typeof getActionOptionsForCampaign>
  actionProps: ActionProps
  actions: LocalAction[]
  apiProps: ApiProps
  choiceIndex: number
  hasTranscribe: boolean
  id: string
  isCloseDisabled: boolean
  label?: string
  ranges: RangeType[]
  replies: string[]
  snippetProps: SnippetProps
  transcribeEnabled: boolean

  onChange: (value: LocalOnAnswerType, index: number) => void
  onRemove: (index: number) => void
}

const ResponseTextChoiceMultiple: React.FC<Props> = ({
  actionOptions,
  actionProps,
  actions,
  apiProps,
  choiceIndex,
  hasTranscribe,
  id,
  isCloseDisabled,
  label,
  ranges,
  replies,
  snippetProps,
  transcribeEnabled,
  onChange,
  onRemove,
}) => {
  const handleChange = React.useCallback(
    ({
      choiceList = {},
      actionsList,
      label: choiceLabel,
    }: {
      choiceList: ChoiceListType
      actionsList: LocalAction[]
      label: string
    }) => {
      onChange(
        {
          ranges: choiceList.ranges,
          replies: choiceList.replies,
          actions: actionsList,
          label: choiceLabel,
        },
        choiceIndex,
      )
    },
    [choiceIndex, onChange],
  )
  const handleRemove = React.useCallback(() => onRemove(choiceIndex), [choiceIndex, onRemove])

  return (
    <CampaignContentQuestionBox
      key={`text-${choiceIndex}`}
      ChoiceTag={InputChoice}
      actionsList={actions}
      actionsValues={actionOptions}
      choiceLabel="List of choices"
      choiceList={{ ranges, replies }}
      defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.smsQuestion}
      dragContext={`${DRAGTYPE}-choice-${choiceIndex}`}
      hasTranscribe={hasTranscribe}
      imageId={`${id}-${choiceIndex}`}
      isCloseDisabled={isCloseDisabled}
      label={label}
      question={true}
      transcribeEnabled={transcribeEnabled}
      voiceId={`${id}-${choiceIndex}`}
      changeHandler={handleChange}
      onClose={handleRemove}
      {...actionProps}
      {...apiProps}
      {...snippetProps}
    />
  )
}

export default React.memo(ResponseTextChoiceMultiple)
