import { Checkbox } from '@/app/component/atom/form'
import { Typography } from '@mui/material'
import React from 'react'
import ActionsBox from '@/app/module/campaigns/component/actions/actions-box'
import { DRAGTYPE, SMS_ACTION_DEFAULT_SENDERID } from '@/app/module/campaigns/definitions'
import { CampaignTemplateType, LocalAction, LocalOnRetriesExhausted, PartFamily } from '@/app/module/campaigns/types'
import { getActionOptionsForCampaign } from '@/app/module/campaigns/utils/actions/options'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'
import { emptyAction } from '@/app/module/campaigns/utils/actions/wrap'
import { ChangeEvent } from '@/app/types'
import { ActionProps, ApiProps, SnippetProps } from './part-config-props'
import { ActionsBoxContainer } from './response-configs-components'

type Props = {
  actionProps: ActionProps
  apiProps: ApiProps
  campaignType: CampaignTemplateType
  failure: LocalOnRetriesExhausted
  id: string
  info: string
  snippetProps: SnippetProps

  onChange: (value: Partial<LocalOnRetriesExhausted>) => void
}

const ResponseVoiceFailure: React.FC<Props> = ({
  actionProps,
  apiProps,
  campaignType,
  failure,
  id,
  info,
  snippetProps,
  onChange,
}) => {
  const actionOptions = React.useMemo(
    () =>
      getActionOptionsForCampaign({
        campaignType,
        partType: PartFamily.Voice,
        actionNamespace: ActionContext.OnFailure,
      }),
    [campaignType],
  )

  const handleToggle = React.useCallback(
    ({ value }: ChangeEvent<string>) => {
      const isEnabled = value === 'true'
      onChange({
        actions: isEnabled ? [emptyAction] : [],
        enabled: isEnabled,
      })
    },
    [onChange],
  )
  const handleActionsChange = React.useCallback(
    (actions: LocalAction[]) =>
      onChange({
        actions,
      }),
    [onChange],
  )

  return (
    <div className="campaign-question-failure-box">
      <Checkbox
        name="failure"
        label="Failure"
        value={(failure.enabled || false).toString()}
        editable={true}
        onChange={handleToggle}
      />
      <ActionsBoxContainer>
        <Typography color="textSecondary" variant="caption" gutterBottom>
          {info}
        </Typography>
        {failure.enabled && (
          <ActionsBox
            {...actionProps}
            {...apiProps}
            {...snippetProps}
            actions={failure.actions}
            actionOptions={actionOptions}
            defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.voiceQuestion}
            dragContext={`${DRAGTYPE}-failure`}
            hasTranscribe={false}
            transcribeEnabled={false}
            updateContactConfig={updateContactConfig}
            voiceId={`${id}-failure`}
            onChange={handleActionsChange}
          />
        )}
      </ActionsBoxContainer>
    </div>
  )
}

const updateContactConfig = {
  invalid: false,
  question: false,
  openResponse: false,
}

export default React.memo(ResponseVoiceFailure)
