import { canUseAi, canVoiceTranscribe } from '@/app/module/campaigns/ai-helpers'
import { CampaignAddButton } from '@/app/module/campaigns/component/helpers'
import { CampaignTemplateType, LocalOnAnswerType, PartFamily } from '@/app/module/campaigns/types'
import { getActionOptionsForCampaign } from '@/app/module/campaigns/utils/actions/options'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'
import { emptyAction } from '@/app/module/campaigns/utils/actions/wrap'
import { Typography } from '@mui/material'
import { append, remove, update } from 'ramda'
import React from 'react'
import { ActionProps, ApiProps, SnippetProps } from './part-config-props'
import ResponseTextChoiceMultiple from './response-text-choice-multiple'
import ResponseTextChoiceSingle from './response-text-choice-single'
import UseAiToggle from './use-ai-toggle'
import WhatsAppTranscribeToggle from './whatsapp-transcribe-toggle'
import { emptyRanges, emptyReplies } from './types/multimessage/definitions'

type Props = {
  actionProps: ActionProps
  apiProps: ApiProps
  campaignType: CampaignTemplateType
  id: string
  onAnswer: LocalOnAnswerType[]
  partType: PartFamily
  snippetProps: SnippetProps
  transcribe: boolean
  useAi: boolean

  onChange: (value: { onAnswer?: LocalOnAnswerType[]; transcribe?: boolean; useAi?: boolean }) => void
}

const ResponseSpecific: React.FC<Props> = ({
  actionProps,
  apiProps,
  campaignType,
  id,
  onAnswer,
  partType,
  snippetProps,
  transcribe,
  useAi,
  onChange,
}) => {
  const answerActions = React.useMemo(
    () =>
      getActionOptionsForCampaign({
        campaignType,
        partType,
        actionNamespace: ActionContext.OnAnswer,
      }),
    [campaignType, partType],
  )

  const handleChangeSingle = React.useCallback(
    (value: LocalOnAnswerType, choiceIndex: number) => {
      const currentChoice = onAnswer[choiceIndex]
      onChange({
        onAnswer: update(
          choiceIndex,
          {
            ...currentChoice,
            actions: value.actions || currentChoice.actions || [emptyAction],
            label: typeof value.label === 'string' ? value.label : currentChoice.label || '',
            reply: typeof value.reply === 'string' ? value.reply : currentChoice.reply || '',
          },
          onAnswer,
        ),
      })
    },
    [onAnswer, onChange],
  )
  const handleChangeMultiple = React.useCallback(
    (value: LocalOnAnswerType, choiceIndex: number) => {
      const currentChoice = onAnswer[choiceIndex]
      onChange({
        onAnswer: update(
          choiceIndex,
          {
            ...currentChoice,
            actions: value.actions || currentChoice.actions || [emptyAction],
            label: typeof value.label === 'string' ? value.label : currentChoice.label || '',
            ranges: value.ranges || currentChoice.ranges || [],
            replies: value.replies || currentChoice.replies || [],
          },
          onAnswer,
        ),
      })
    },
    [onAnswer, onChange],
  )
  const handleRemove = React.useCallback(
    (choiceIndex: number) => onChange({ onAnswer: remove(choiceIndex, 1, onAnswer) }),
    [onAnswer, onChange],
  )

  return (
    <div className="campaign-specific-tab-content">
      <Typography>Choices</Typography>
      <div>
        <Typography color="textSecondary" variant="caption" gutterBottom={true}>
          Specify the valid answer choices to this question and what actions we should take for each choice.
          {partType === PartFamily.WhatsApp && (
            <Typography color="primary" variant="caption" sx={{ fontWeight: 'bold' }}>
              <br />
              Pro Tip: Use WhatsApp List or WhatsApp Buttons instead of Specific Response to increase response rates.
            </Typography>
          )}
        </Typography>
        {canVoiceTranscribe(partType) && (
          <WhatsAppTranscribeToggle
            context={ActionContext.OnAnswer}
            transcribe={transcribe}
            onChange={(v) => onChange({ transcribe: v })}
          />
        )}
        {canUseAi(campaignType) && <UseAiToggle useAi={useAi} onChange={(v) => onChange({ useAi: v })} />}
        {onAnswer.map(({ ranges = emptyRanges, replies = emptyReplies, reply, actions, label = '' }, i: number) => {
          if (campaignType === CampaignTemplateType.CATI) {
            return (
              <ResponseTextChoiceSingle
                key={`text-${i}`}
                actionOptions={answerActions}
                actionProps={actionProps}
                actions={actions}
                apiProps={apiProps}
                choiceIndex={i}
                id={id}
                isCloseDisabled={onAnswer.length === 1}
                label={label}
                reply={reply || ''}
                snippetProps={snippetProps}
                onChange={handleChangeSingle}
                onRemove={handleRemove}
              />
            )
          }

          return (
            <ResponseTextChoiceMultiple
              key={`text-${i}`}
              actionOptions={answerActions}
              actionProps={actionProps}
              actions={actions}
              apiProps={apiProps}
              choiceIndex={i}
              hasTranscribe={canVoiceTranscribe(partType)}
              id={id}
              isCloseDisabled={onAnswer.length === 1}
              label={label}
              ranges={ranges}
              replies={replies}
              snippetProps={snippetProps}
              transcribeEnabled={transcribe}
              onChange={handleChangeMultiple}
              onRemove={handleRemove}
            />
          )
        })}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CampaignAddButton
            tooltip="Add choice"
            onClick={() => {
              onChange({
                onAnswer: append(
                  {
                    replies: [],
                    reply: '',
                    ranges: [],
                    actions: [emptyAction],
                    label: '',
                  },
                  onAnswer,
                ),
              })
            }}
            text="Add choice"
            icon="add"
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(ResponseSpecific)
