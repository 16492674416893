import { useCampaignDebouncedState } from '@/app/module/campaigns/component/hooks/use-campaign-debounced-state'
import { CAMPAIGN_RETRIES } from '@/app/module/campaigns/definitions'
import { CampaignTemplateType, LocalOnRetriesExhausted, LocalOnTimeout } from '@/app/module/campaigns/types'
import { Typography } from '@mui/material'
import React from 'react'
import { ActionProps, ApiProps, SnippetProps } from './part-config-props'
import QuestionNumberInput from './question/number'
import { ConfigDivider } from './response-configs-components'
import ResponseVoiceFailure from './response-voice-failure'
import { fromConfigs, SpeechConfigs, SpeechConfigsState, toConfigs } from './response-voice-speech-configs-utils'
import ResponseVoiceTimeout from './response-voice-timeout'

type Props = {
  actionProps: ActionProps
  apiProps: ApiProps
  campaignType: CampaignTemplateType
  configs: SpeechConfigs
  id: string
  snippetProps: SnippetProps
  useAi: boolean

  onChange: (value: SpeechConfigs) => void
}

const ResponseVoiceSpeechConfigs: React.FC<Props> = ({
  actionProps,
  apiProps,
  campaignType,
  configs,
  id,
  snippetProps,
  useAi,
  onChange,
}) => {
  const initialState = React.useMemo(() => fromConfigs(configs), [configs])
  const handleChange = React.useCallback((value: SpeechConfigsState) => onChange(toConfigs(value)), [onChange])
  const [state, setState] = useCampaignDebouncedState(initialState, handleChange)

  const handleSpeechTimeoutChange = React.useCallback(
    (value: Partial<LocalOnTimeout>) =>
      setState((s) => {
        const shouldUpdateRetries = 'enabled' in value
        if (!shouldUpdateRetries) {
          return { ...s, onTimeout: { ...s.onTimeout, ...value } }
        }

        const defaultRetries = typeof s.retries === 'number' ? s.retries : CAMPAIGN_RETRIES
        return {
          ...s,
          onTimeout: { ...s.onTimeout, ...value },
          retries: value.enabled ? defaultRetries : 0,
        }
      }),
    [setState],
  )
  const handleRetriesChange = React.useCallback(
    ({ value }: { value: string }) => setState((s) => ({ ...s, retries: Number(value) })),
    [setState],
  )
  const handleFailureChange = React.useCallback(
    (value: Partial<LocalOnRetriesExhausted>) =>
      setState((s) => ({ ...s, onRetriesExhausted: { ...s.onRetriesExhausted, ...value } })),
    [setState],
  )

  const retriesEnabled = state.onTimeout.enabled

  return (
    <>
      <ResponseVoiceTimeout
        actionProps={actionProps}
        apiProps={apiProps}
        campaignType={campaignType}
        hideTimeoutSeconds={useAi}
        id={id}
        info="Specify what we should do if the contact does not speak anything which contains a choice within a period
                of time that you specify. Afterwards, we will wait for the contact to speak until they run out of
                chances."
        inputAfterText="seconds"
        inputBeforeText="Take these actions if the contact doesn't speak anything which contains a choice: "
        snippetProps={snippetProps}
        timeout={state.onTimeout}
        onChange={handleSpeechTimeoutChange}
      />
      <ConfigDivider />
      <div>
        <Typography color="textSecondary" component="div" variant="caption" gutterBottom={true}>
          <QuestionNumberInput
            data-testid="retries-input"
            editable={retriesEnabled}
            beforeText="Specify the number of times that we should allow the Contact to time out before we take the Failure actions you specify below: "
            afterText="additional chances."
            min={0}
            value={`${state.retries}`}
            onChange={handleRetriesChange}
          />
        </Typography>
        {!retriesEnabled && (
          <Typography color="textSecondary" component="div" gutterBottom variant="caption">
            Note: To set the number of chances, please first select and specify Actions for Timeout.
          </Typography>
        )}
        {retriesEnabled && (
          <Typography color="textSecondary" component="div" gutterBottom variant="caption">
            Note: after we take the Failure actions you specify, the survey will continue, unless you add a "Hangup"
            action in the Failure section.
          </Typography>
        )}
      </div>
      <ConfigDivider />
      <ResponseVoiceFailure
        actionProps={actionProps}
        apiProps={apiProps}
        campaignType={campaignType}
        failure={state.onRetriesExhausted}
        id={id}
        info="Specify what we should do after the contact runs out of chances."
        snippetProps={snippetProps}
        onChange={handleFailureChange}
      />
    </>
  )
}

export default ResponseVoiceSpeechConfigs

export type { Props as ResponseVoiceSpeechConfigsProps }
