import { ContentAudio, LocalSpoken, Spoken } from '@/app/module/campaigns/types'
import { unwrapActions, wrapActions } from '@/app/module/campaigns/utils/actions/wrap'

export type SpokenValue = {
  processingReply?: ContentAudio
  spoken: Spoken
}

export type SpokenValueState = {
  processingReply?: ContentAudio
  spoken: LocalSpoken
}

export const fromSpokenValue = (spoken: SpokenValue): SpokenValueState => ({
  processingReply: spoken.processingReply,
  spoken: {
    ...spoken.spoken,
    actions: unwrapActions(spoken.spoken.actions),
  },
})

export const toSpokenValue = (state: SpokenValueState): SpokenValue => ({
  processingReply: state.spoken.transcribe ? state.processingReply : undefined, // Clear processing reply if not using AI
  spoken: {
    ...state.spoken,
    actions: wrapActions(state.spoken.actions),
  },
})
