import { Typography } from '@mui/material'
import React from 'react'
import { DRAGTYPE, SMS_ACTION_DEFAULT_SENDERID } from '@/app/module/campaigns/definitions'
import { CampaignTemplateType, LocalAction, LocalOpenEnded, PartFamily } from '@/app/module/campaigns/types'
import { getActionOptionsForCampaign } from '@/app/module/campaigns/utils/actions/options'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'
import { canCategorize, canVoiceTranscribe } from '@/app/module/campaigns/ai-helpers'
import { emptyAction } from '@/app/module/campaigns/utils/actions/wrap'
import ResponseTextCategories from './response-text-categories'
import { ActionProps, ApiProps, SnippetProps } from './part-config-props'
import CampaignContentQuestionBox from './question'
import WhatsAppTranscribeToggle from './whatsapp-transcribe-toggle'

type Props = {
  actionProps: ActionProps
  apiProps: ApiProps
  campaignType: CampaignTemplateType
  id: string
  openEnded: LocalOpenEnded
  partType: PartFamily
  snippetProps: SnippetProps
  transcribe: boolean

  onChange: (value: { openEnded?: LocalOpenEnded; transcribe?: boolean }) => void
}

const ResponseTextOpenEnded: React.FC<Props> = ({
  actionProps,
  apiProps,
  campaignType,
  id,
  openEnded,
  partType,
  snippetProps,
  transcribe,
  onChange,
}) => {
  const openEndedActions = React.useMemo(
    () =>
      getActionOptionsForCampaign({
        campaignType,
        partType,
        actionNamespace: ActionContext.OpenEnded,
      }),
    [campaignType, partType],
  )
  const openEndedCategoryActions = React.useMemo(
    () =>
      getActionOptionsForCampaign({
        campaignType,
        partType,
        actionNamespace: ActionContext.OpenEndedCategory,
      }),
    [campaignType, partType],
  )

  const handleActionsChange = React.useCallback(
    ({ actionsList }: { actionsList: LocalAction[] }) => {
      onChange({
        openEnded: {
          ...openEnded,
          actions: actionsList || openEnded.actions || [emptyAction],
        },
      })
    },
    [openEnded, onChange],
  )

  return (
    <div className="campaign-open-ended-tab-content">
      <Typography>Open Response</Typography>
      <div>
        <Typography color="textSecondary" variant="caption" gutterBottom={true}>
          Specify what we should do after the contact responds.
        </Typography>
        {canVoiceTranscribe(partType) && (
          <WhatsAppTranscribeToggle
            context={ActionContext.OnAnswer}
            transcribe={transcribe}
            onChange={(v) => onChange({ transcribe: v })}
          />
        )}

        <CampaignContentQuestionBox
          dragContext={`${DRAGTYPE}-choice-openEnded`}
          actionsList={openEnded.actions || []}
          actionsValues={openEndedActions}
          defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.smsQuestion}
          hasTranscribe={canVoiceTranscribe(partType)}
          imageId={`${id}-${-1}`}
          isCloseDisabled
          openEnded={openEnded}
          openResponse
          transcribeEnabled={transcribe}
          voiceId={`${id}-${-1}`}
          changeHandler={handleActionsChange}
          {...actionProps}
          {...apiProps}
          {...snippetProps}
        />
        {canCategorize(campaignType) && (
          <ResponseTextCategories
            actionOptions={openEndedCategoryActions}
            actionProps={actionProps}
            apiProps={apiProps}
            id={id}
            openEnded={openEnded}
            snippetProps={snippetProps}
            onChange={onChange}
          />
        )}
      </div>
    </div>
  )
}

export default React.memo(ResponseTextOpenEnded)
