import { SearchableSelect } from '@/app/component/atom/form'
import { LanguageListItem, LanguageType } from '@/app/module/campaigns/helpers'
import { createFilterOptions } from '@mui/material'
import { prop } from 'ramda'
import React from 'react'

type SpeechLanguageOption = LanguageListItem & {
  disabled?: boolean
}

type Props = {
  defaultLanguageSpeech?: LanguageType
  isDefaultSpeechLanguage: boolean
  language: LanguageType
  languageIndex: number
  speechLanguages: SpeechLanguageOption[]

  onChange: (index: number, selected: string) => void
}

const SpeechLanguageSelect: React.FC<Props> = ({
  defaultLanguageSpeech,
  isDefaultSpeechLanguage,
  language,
  languageIndex,
  speechLanguages,
  onChange,
}) => {
  const currentSpeechLanguages = React.useMemo(
    () =>
      speechLanguages.map((l) => {
        if (l.value !== 'default') {
          return l
        }
        return {
          ...l,
          disabled: isDefaultSpeechLanguage || !defaultLanguageSpeech,
        }
      }),
    [defaultLanguageSpeech, isDefaultSpeechLanguage, speechLanguages],
  )

  const handleChange = React.useCallback(
    (selected: string) => onChange(languageIndex, selected),
    [languageIndex, onChange],
  )

  return (
    <SearchableSelect
      clearOnBlur
      hideRemoveSelection
      id={`speech-language-${language.value}`}
      options={currentSpeechLanguages}
      showAll
      value={language.speechLanguage?.value || ''}
      filterOptions={filterOption}
      getOptionLabel={getOpionLabelFn}
      keyPropFn={keyPropFn}
      onChange={handleChange}
      valuePropFn={valuePropFn}
    />
  )
}

const filterOption = createFilterOptions<SpeechLanguageOption>()
const keyPropFn = prop('value')
const valuePropFn = prop('label')
const getOpionLabelFn = (option: SpeechLanguageOption | string) => {
  if (typeof option === 'string') {
    return option
  }
  return option.label
}

export default SpeechLanguageSelect
