import { SpeechSettings } from '@/app/module/campaigns/types'
import { Grid, styled, TextField, Typography } from '@mui/material'
import React from 'react'
import Tooltip from '@/app/component/atom/tooltip'
import { Select } from '@/app/component/atom/form'
import { repeatQuestionOptions } from '@/app/module/campaigns/definitions'

type Props = {
  speechSettings: SpeechSettings

  onChange: (value: Partial<SpeechSettings>) => void
}

const inputLabelProps = {
  shrink: true,
}

const SpeechTranscribeSettings: React.FC<Props> = ({ speechSettings, onChange }) => {
  const handleChangeMaxSeconds = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      onChange({
        maxSeconds: e.currentTarget.value ? Number(e.currentTarget.value) : undefined,
      }),
    [onChange],
  )
  const handleChangeStopKey = React.useCallback(
    ({ value }: { value: string }) =>
      onChange({
        stopKey: value,
      }),
    [onChange],
  )
  const handleChangeSilence = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      onChange({
        silence: e.currentTarget.value ? Number(e.currentTarget.value) : undefined,
      }),
    [onChange],
  )

  return (
    <Container container spacing={0}>
      <Grid item xs={2}>
        <Title>Configuration</Title>
      </Grid>
      <Grid container item spacing={2} xs={10}>
        <Grid item xs={4}>
          <Tooltip title="How much time the contact has to speak their response. If the contact presses the stop key, the question will end, even if there is still time left in the duration. If the contact is silent for 3 seconds, we'll consider them done speaking, even if they haven't pressed the Stop Key.">
            <TextField
              InputLabelProps={inputLabelProps}
              fullWidth
              helperText="How much time the contact has to speak their response. "
              label="Response time limit (seconds)"
              name="maxSeconds"
              type="number"
              variant="standard"
              value={speechSettings.maxSeconds}
              onChange={handleChangeMaxSeconds}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Select
            editable
            info="The key the contact should press to end the recording."
            label="Select stop key"
            name="stopKey"
            value={speechSettings.stopKey}
            values={repeatQuestionOptions}
            onChange={handleChangeStopKey}
          />
        </Grid>
        <Grid item xs={4}>
          <Tooltip title="How many seconds of silence should we wait before stopping recording?">
            <TextField
              fullWidth
              helperText="How long to wait before stopping recording."
              label="Silence (seconds)"
              name="silence"
              type="number"
              variant="standard"
              InputLabelProps={inputLabelProps}
              value={speechSettings.silence}
              onChange={handleChangeSilence}
            />
          </Tooltip>
        </Grid>
      </Grid>
    </Container>
  )
}

const Container = styled(Grid)({
  backgroundColor: 'rgb(245, 245, 245)',
  padding: 8,
})

const Title = styled(Typography)({
  marginTop: '16px',
})

export default SpeechTranscribeSettings
