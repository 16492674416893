import { SearchableSelect } from '@/app/component/atom/form'
import Loader from '@/app/component/atom/loader'
import CustomTooltip from '@/app/component/atom/tooltip'
import IconText from '@/app/component/layout/icon-text'
import { useTranscribeAudio } from '@/app/module/campaigns/component/hooks/use-transcribe-audio'
import { useUpdateFile } from '@/app/module/campaigns/component/hooks/use-update-file'
import { LanguageListItem } from '@/app/module/campaigns/helpers'
import { selectTranscribeLanguages } from '@/app/module/utils/store/ts/selectors'
import { FileType } from '@/app/types'
import {
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Icon,
  IconButton,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

type Props = Omit<DialogProps, 'onClose' | 'onSubmit'> & {
  file: FileType
  messageId: string // to store the file new transcript after update
  transcription?: string
  onClose: () => void
  onSubmit: (transcription: string) => void
}

const TranscriptDialog: React.FC<Props> = ({ file, messageId, transcription, onClose, onSubmit, ...props }) => {
  const languageList: LanguageListItem[] = useSelector(selectTranscribeLanguages)

  const [selectedLanguage, setSelectedLanguage] = useState<string>('en-US')
  const [transcript, setTranscript] = useState(transcription || '')
  const [transcribe, { data, isLoading: transcribeLoading }] = useTranscribeAudio()
  const [update, { isLoading: updateLoading }] = useUpdateFile(messageId)

  React.useEffect(() => {
    if (data?.transcript) {
      setTranscript(data.transcript)
    }
  }, [data?.transcript])

  const handleTranscribeClick = React.useCallback(
    () => transcribe(file.filename, selectedLanguage),
    [file.filename, selectedLanguage, transcribe],
  )
  const handleSubmit = React.useCallback(async () => {
    onSubmit(transcript)
    const success = await update(file.filename, transcript)
    if (!success) {
      return
    }
    onClose()
  }, [file.filename, transcript, onClose, onSubmit, update])

  const loading = transcribeLoading || updateLoading

  return (
    <Dialog {...props}>
      {loading && <Loader />}
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconText>
            <Icon>transcribe</Icon>
            <Typography variant="h6">Transcribe Audio</Typography>
          </IconText>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please provide a transcript for the audio file. We use this transcript for AI-enhanced features. You can also
          use the AI-generated transcript but please double check the results.
        </DialogContentText>
        <br />
        <Field>
          <TextField
            disabled={loading}
            fullWidth
            id="transcript-input"
            label="Transcript"
            multiline
            rows={5}
            value={transcript}
            variant="standard"
            onChange={(e) => setTranscript(e.target.value)}
          />
        </Field>
        <Row>
          <Field>
            <SearchableSelect
              clearOnBlur
              disabled={loading}
              hideRemoveSelection
              id="transcript-language-select"
              label="Select a language to generate the transcript with AI"
              options={languageList}
              showAll
              value={selectedLanguage}
              filterOptions={filterOption}
              getOptionLabel={getOpionLabelFn}
              keyPropFn={keyPropFn}
              onChange={setSelectedLanguage}
              valuePropFn={valuePropFn}
            />
          </Field>
          <CustomTooltip title="Generate the transcript with AI">
            <GenerateContainer>
              <Button
                disabled={loading || !selectedLanguage}
                size="small"
                variant="contained"
                onClick={handleTranscribeClick}
              >
                Generate with AI
              </Button>
            </GenerateContainer>
          </CustomTooltip>
        </Row>
      </DialogContent>
      <DialogActions>
        <Buttons>
          <RightButtons>
            <CustomTooltip title="This will save the transcript to the file">
              <div>
                <Button color="primary" disabled={loading} variant="contained" onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            </CustomTooltip>
          </RightButtons>
        </Buttons>
      </DialogActions>
    </Dialog>
  )
}

const filterOption = createFilterOptions<LanguageListItem>()
const keyPropFn = (option: LanguageListItem) => option.value
const valuePropFn = (option: LanguageListItem) => option.label
const getOpionLabelFn = (option: LanguageListItem) => option.label

const Row = styled('div')({
  alignItems: 'center',
  display: 'flex',
})

const Field = styled('div')({
  flex: 1,
  marginBottom: 16,
})

const GenerateContainer = styled('div')({
  marginLeft: 8,
})

const Buttons = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flex: 1,
})

const RightButtons = styled('div')({
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
})

export default TranscriptDialog
