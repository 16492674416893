import React from 'react'
import { LocalActionType, LocalNonEmptyAction } from '@/app/module/campaigns/types'
import { cannot } from '@/app/helpers'
import { GetVoiceProps } from '@/app/module/campaigns/component/item/steps/content/part-config-props'
import { DEFAULT_SENDER_ID_NAME, DEFAULT_SENDER_ID_VALUE } from '@/app/definitions'
import { useCampaignParts } from '@/app/module/campaigns/component/hooks/use-campaign-parts'
import APIDialog from '@/app/module/campaigns/component/item/steps/content/question/dialog/api'
import JumpDialog from '@/app/module/campaigns/component/item/steps/content/question/dialog/jumpToQuestion'
import VoiceDialog from '@/app/module/campaigns/component/item/steps/content/question/dialog/voice'
import ResumeSubscriptionDialog from '@/app/module/campaigns/component/item/steps/content/question/dialog/resume-subscription'
import SendEmailDialog from '@/app/module/campaigns/component/item/steps/content/question/dialog/sendEmail'
import SendSMSDialog from '@/app/module/campaigns/component/item/steps/content/question/dialog/sms'
import SendSMSToPhoneDialog from '@/app/module/campaigns/component/item/steps/content/question/dialog/smsToPhoneNumbers'
import SendWhatsAppDialog from '@/app/module/campaigns/component/item/steps/content/question/dialog/whatsapp'
import SubscribeToCampaignDialog from '@/app/module/campaigns/component/item/steps/content/question/dialog/subscribeToCampaign'
import TopupDialog from '@/app/module/campaigns/component/item/steps/content/question/dialog/topup'
import TransferDialog from '@/app/module/campaigns/component/item/steps/content/question/dialog/transfer'
import UpdateContactDialog from '@/app/module/campaigns/component/item/steps/content/question/dialog/update-contact'
import { ActionsBoxProps } from './actions-box-props'

type Props = Pick<
  ActionsBoxProps,
  | 'campaignIds'
  | 'countriesTimezones'
  | 'countryCode'
  | 'defaultLanguage'
  | 'defaultSenderId'
  | 'hasTranscribe'
  | 'imageId'
  | 'index'
  | 'languages'
  | 'orgId'
  | 'personalizationList'
  | 'phoneNumber'
  | 'senderIds'
  | 'timezone'
  | 'timezones'
  | 'token'
  | 'transcribeEnabled'
  | 'updateContactConfig'
  | 'voiceId'
  | 'getCampaigns'
  | 'getImageProps'
  | 'getVoiceProps'
> & {
  action: LocalNonEmptyAction

  getVoiceProps: GetVoiceProps
  onClose: () => void
  onSave: (action: Partial<LocalNonEmptyAction>) => void
}

const ActionDialogWrapper: React.FC<Props> = ({
  action,
  campaignIds,
  countriesTimezones,
  countryCode,
  defaultLanguage,
  defaultSenderId,
  hasTranscribe,
  imageId,
  index,
  languages,
  orgId,
  personalizationList,
  phoneNumber,
  senderIds,
  timezone,
  timezones,
  token,
  transcribeEnabled,
  updateContactConfig,
  voiceId,
  getCampaigns,
  getImageProps,
  getVoiceProps,
  onClose,
  onSave,
}) => {
  const parts = useCampaignParts()

  const commonProps = { onClose, onSave }

  const imageProps = React.useMemo(() => getImageProps(`${imageId}-${index}`), [index, imageId, getImageProps])
  const voiceProps = React.useMemo(() => getVoiceProps(`${voiceId}-${index}`), [index, voiceId, getVoiceProps])

  const smsSenderIds = React.useMemo(
    () => [
      {
        label: DEFAULT_SENDER_ID_NAME,
        value: DEFAULT_SENDER_ID_VALUE,
      },
      ...senderIds,
    ],
    [senderIds],
  )

  switch (action.type) {
    case LocalActionType.API:
      return <APIDialog {...commonProps} personalizationList={personalizationList} value={action} />

    case LocalActionType.Jump:
      return <JumpDialog {...commonProps} index={index} parts={parts} value={action} />

    case LocalActionType.PlayAudio:
      return (
        <VoiceDialog
          {...commonProps}
          defaultLanguage={defaultLanguage}
          languages={languages}
          value={action}
          voiceProps={voiceProps}
        />
      )

    case LocalActionType.ResumeSubscription:
      return (
        <ResumeSubscriptionDialog
          {...commonProps}
          campaignIds={campaignIds}
          orgId={orgId}
          token={token}
          value={action}
          getCampaigns={getCampaigns}
        />
      )

    case LocalActionType.SendEmail:
      return <SendEmailDialog {...commonProps} personalizationList={personalizationList} value={action} />

    case LocalActionType.SendSMS:
      return (
        <SendSMSDialog
          {...commonProps}
          defaultLanguage={defaultLanguage}
          defaultSenderId={defaultSenderId}
          languages={languages}
          personalizationList={personalizationList}
          phoneNumber={phoneNumber}
          senderIds={smsSenderIds}
          value={action}
        />
      )

    case LocalActionType.SendSMSToPhoneNumbers:
      return (
        <SendSMSToPhoneDialog
          {...commonProps}
          defaultLanguage={defaultLanguage}
          defaultSenderId={defaultSenderId}
          languages={languages}
          personalizationList={personalizationList}
          phoneNumber={phoneNumber}
          senderIds={smsSenderIds}
          value={action}
        />
      )

    case LocalActionType.SendWhatsApp:
      return (
        <SendWhatsAppDialog
          {...commonProps}
          defaultLanguage={defaultLanguage}
          imageProps={imageProps}
          personalizationList={personalizationList}
          value={action}
          voiceProps={voiceProps}
        />
      )

    case LocalActionType.SubscribeToCampaign:
      return (
        <SubscribeToCampaignDialog
          {...commonProps}
          campaignIds={campaignIds}
          countriesTimezones={countriesTimezones}
          countryCode={countryCode}
          orgId={orgId}
          timezone={timezone}
          timezones={timezones}
          token={token}
          value={action}
          getCampaigns={getCampaigns}
        />
      )

    case LocalActionType.Topup:
      return <TopupDialog {...commonProps} personalizationList={personalizationList} value={action} />

    case LocalActionType.Transfer:
      return (
        <TransferDialog
          {...commonProps}
          countriesTimezones={countriesTimezones}
          countryCode={countryCode}
          value={action}
        />
      )

    case LocalActionType.UpdateContact:
      return (
        <UpdateContactDialog
          {...commonProps}
          invalid={updateContactConfig?.invalid || false}
          openResponse={updateContactConfig?.openResponse || false}
          question={updateContactConfig?.question || false}
          transcribeAvailable={hasTranscribe}
          transcribeEnabled={transcribeEnabled}
          type={updateContactConfig?.type}
          personalizationList={personalizationList}
          value={action}
        />
      )

    case LocalActionType.FinishFlow:
    case LocalActionType.Hangup:
    case LocalActionType.PauseSubscription:
    case LocalActionType.Replay:
    case LocalActionType.Resend:
    case LocalActionType.Unsubscribe:
    case LocalActionType.UnsubscribeAll: // those actions do not have dialog
      return null

    default:
      return cannot(action)
  }
}

export default ActionDialogWrapper
