import { AppDispatch } from '@/app/store'
import { createNotification } from '@/app/module/notifications/store/actions'
import { getContacts, getUploadsInvalid } from './actions'

const getErrorMessage = (error: string) => {
  switch (error) {
    case 'plan limit reached':
      return 'This upload exceeds your plan limits. Please upgrade your plan.'

    default:
      return error
  }
}

export const processBulkUploadResult = (
  {
    token,
    orgId,
    query,
    uploadKey,
  }: {
    token: string
    orgId: number
    query: any
    uploadKey: string
  },
  {
    validRows,
    invalidRows,
    error,
  }: {
    validRows: number
    invalidRows: number
    error: string
  },
  dispatch: AppDispatch,
) => {
  if (error) {
    dispatch(
      createNotification({
        [`upload-${uploadKey}`]: {
          type: 'error',
          message: getErrorMessage(error),
        },
      }),
    )
    return
  }

  getContacts({ token, orgId, query })(dispatch)
  const isInvalid = invalidRows > 0
  const validMessage = `${validRows} ${validRows === 1 ? 'contact' : 'contacts'} uploaded successfully.`
  const invalidMessage = !isInvalid
    ? ''
    : ` ${invalidRows} invalid ${invalidRows === 1 ? 'contact' : 'contacts'} found. {{link1}} {{link2}}`
  dispatch(
    createNotification({
      [`upload-${uploadKey}`]: {
        type: isInvalid ? 'warning' : 'success',
        message: validMessage + invalidMessage,
        links: isInvalid
          ? [
              {
                name: '{{link1}}',
                title: 'Download list of invalid contacts!',
                onClick: () => getUploadsInvalid({ token, orgId, uploadKey })(dispatch),
              },
              {
                name: '{{link2}}',
                title: 'Click here to learn how to fix errors!',
                onClick: () => {
                  window.open('https://www.engagespark.com/support/contact-upload-errors-explained/', '_blank')
                },
              },
            ]
          : [],
        autoHide: isInvalid ? undefined : 5000,
      },
    }),
  )
}
