import { comparator, compose, find, includes, map, path, pathOr, propEq, sort } from 'ramda'
import { createSelector } from 'reselect'
import { ROLES } from '@/app/definitions'
import { formatBooleanVal, formatDate, formatName, formatRole } from '@/app/service/util/format'
import { selectCountries, selectCountryNames, selectDefaultTimezone } from '@/app/module/utils/store/selectors'

export const selectUserDetails = (user) => pathOr('', ['user', 'details', 'data'], user)
const selectOrganizationData = (user) => pathOr({}, ['user', 'organization', 'data'], user)
const selectUserActiveOrganizations = (user) => pathOr({}, ['user', 'details', 'data', 'orgs'], user)
export const selectInvitations = (user) => pathOr({}, ['user', 'invitations', 'data'], user)
export const selectUsers = (user) => pathOr([], ['user', 'users', 'data'], user)

export const selectUserId = createSelector(selectUserDetails, pathOr('', ['id']))

export const selectOrgs = createSelector(selectUserDetails, pathOr([], ['orgs']))

export const selectOrgId = createSelector(selectUserDetails, selectOrgs, (details, orgs) => {
  if (!orgs?.length) {
    return 0
  }

  return pathOr(0, ['activeOrgId'], details)
})

export const selectActiveOrg = createSelector(selectOrgs, selectOrgId, (orgs, orgId) =>
  find(propEq('orgId', orgId), orgs),
)

export const selectUserRole = createSelector(selectOrgId, selectUserActiveOrganizations, (activeOrgId, data) =>
  pathOr('', ['role'], find(propEq('orgId', activeOrgId), data)),
)

export const selectUserPermissions = createSelector(selectOrgId, selectUserActiveOrganizations, (activeOrgId, data) =>
  pathOr({}, ['permissions'], find(propEq('orgId', activeOrgId), data)),
)

export const selectSelectedOrgId = createSelector(
  selectOrgId,
  path(['user', 'organization', 'selectedId']),
  (orgId, selectedId) => selectedId || orgId,
)

export const selectSelectedOrgUserRole = createSelector(
  selectSelectedOrgId,
  selectUserActiveOrganizations,
  (selectedOrgId, data) => pathOr('', ['role'], find(propEq('orgId', selectedOrgId), data)),
)

export const selectOrganization = createSelector(
  selectOrganizationData,
  selectSelectedOrgId,
  selectUserRole,
  (data, id, role) => (data[id] ? { ...data[id], role } : {}),
)

export const selectUserTimezone = createSelector(
  selectDefaultTimezone,
  selectUserDetails,
  (defaultTz, user) => user.timezone || defaultTz,
)

export const selectUserCountry = createSelector(selectUserDetails, selectCountries, (details, countries) =>
  find(propEq('label', path(['country'], details)), countries),
)

export const selectUserOrganizations = createSelector(
  selectUserDetails,
  compose(
    map(({ name, orgId }) => ({
      label: `${name} (${orgId})`,
      value: orgId,
    })),
    sort(comparator((before, after) => before.name.toLowerCase() < after.name.toLowerCase())),
    pathOr([], ['orgs']),
  ),
)

export const selectUserTOTP = pathOr(false, ['details', 'data', 'totpEnabled'])

export const selectOrganizationTypes = () => [
  {
    label: 'Business',
    value: 'B',
  },
  {
    label: 'Non-Profit',
    value: 'N',
  },
  {
    label: 'Other',
    value: 'O',
  },
]

export const organizationSpecs = (countryList, countryNames, editable) => ({
  id: {
    'data-testid': 'id',
    type: 'hidden',
    label: 'Id',
  },
  name: {
    'data-testid': 'name',
    type: 'text',
    editable,
    label: 'Name',
  },
  website: {
    'data-testid': 'website',
    type: 'text',
    editable,
    label: 'Website',
  },
  phone: {
    'data-testid': 'phone',
    type: 'phone',
    editable,
    label: 'Phone number',
    countries: countryList,
  },
  address: {
    'data-testid': 'address',
    type: 'text',
    editable,
    label: 'Address',
  },
  country: {
    'data-testid': 'country',
    type: 'autocomplete',
    editable,
    label: 'Country',
    values: countryNames,
  },
  location: {
    'data-testid': 'location',
    type: 'text',
    editable,
    label: 'Location',
  },
})

export const selectFormattedInvitations = createSelector(selectInvitations, (invitations) => {
  const formattedInvitaionsData = invitations.map((invitationData) => {
    const formattedName = formatName(invitationData?.firstName, invitationData?.lastName)
    const formattedInvitationData = {
      ...invitationData,
      id: invitationData.inviteId,
      role: formatRole(invitationData.role),
      name: formattedName,
      twoFactorAuthenticated: formatBooleanVal(null),
      fullAccess: formatBooleanVal(null),
      invitationSentDate: formatDate(invitationData.created),
    }
    return formattedInvitationData
  })
  return formattedInvitaionsData
})

export const selectFormattedUsers = createSelector(selectUsers, (users) => {
  const formattedUsersData = users.map((userData) => {
    if (userData.role === 'customer_service') {
      return {
        firstName: '',
        lastName: '',
        id: Date.now(),
        email: '',
        invitationSentDate: '',
        role: formatRole(userData.role),
        twoFactorAuthenticated: '',
        fullAccess: '',
        notify: '',
      }
    }
    const formattedUserData = {
      ...userData,
      role: formatRole(userData.role),
      email: userData.username,
      twoFactorAuthenticated: formatBooleanVal(userData.totp),
      fullAccess: formatBooleanVal(userData.fullAccess),
      invitationSentDate: '',
      notify: formatBooleanVal(userData.notify),
    }
    return formattedUserData
  })
  return formattedUsersData
})

export const selectShowOrgWarning = createSelector(
  selectActiveOrg,
  (activeOrg) => activeOrg?.permissions?.manageUsers && activeOrg?.totpAll === false,
)

export const selectShowTotpWarning = createSelector(
  selectActiveOrg,
  (activeOrg) => !['cati', 'customer_service'].includes(activeOrg?.role),
)

export const selectUserName = createSelector(selectUserDetails, ({ firstName = '', lastName = '' }) => {
  if (!firstName) {
    return lastName
  }

  if (!lastName) {
    return firstName
  }

  return `${firstName} ${lastName}`
})
export const selectProfileSpecs = createSelector(
  selectCountries,
  selectCountryNames,
  selectUserCountry,
  (countryList, countryNames, userCountry) => ({
    username: {
      type: 'text',
      display: 'text',
      editable: false,
      label: 'Email',
      inputProps: {
        type: 'email',
      },
    },
    firstName: {
      type: 'text',
      display: 'text',
      label: 'First name',
      autoFocus: true,
    },
    lastName: {
      type: 'text',
      display: 'text',
      label: 'Last name',
    },
    phone: {
      type: 'phone',
      editable: true,
      label: 'Phone number',
      countries: countryList,
      defaultCountryCode: pathOr('', ['value'], userCountry),
    },
    country: {
      type: 'autocomplete',
      editable: true,
      label: 'Country',
      values: countryNames,
    },
    language: {
      type: 'text',
      editable: false,
      label: 'Language',
    },
    timezone: {
      type: 'text',
      editable: false,
      label: 'Timezone',
    },
    currentPassword: {
      type: 'text',
      editable: true,
      label: 'Current password',
      inputProps: {
        type: 'password',
      },
    },
    newPassword: {
      type: 'text',
      editable: true,
      label: 'New password',
      inputProps: {
        type: 'password',
      },
    },
    newPasswordConfirm: {
      type: 'text',
      editable: true,
      label: 'Confirm password',
      inputProps: {
        type: 'password',
      },
    },
  }),
)

export const selectNewOrganizationSpecs = createSelector(
  selectCountries,
  selectCountryNames,
  (countryList, countryNames) => organizationSpecs(countryList, countryNames, true),
)

export const selectOrganizationSpecs = createSelector(
  selectCountries,
  selectCountryNames,
  selectSelectedOrgUserRole,
  (countryList, countryNames, role) => {
    const editable = includes(role, ROLES.OWNER)

    return organizationSpecs(countryList, countryNames, editable)
  },
)

export const selectProfileInfoHeaders = () => [
  {
    title: 'Email',
    fields: ['username'],
  },
  {
    title: 'First name',
    fields: ['firstName'],
  },
  {
    title: 'Last name',
    fields: ['lastName'],
  },
  {
    title: 'Phone number',
    fields: ['phone'],
  },
]

export const selectProfilePreferenceHeaders = () => [
  {
    title: 'Language',
    fields: ['language'],
  },
  {
    title: 'Timezone',
    fields: ['timezone'],
  },
]

export const selectPasswordResetHeaders = () => [
  {
    title: 'Current password',
    fields: ['currentPassword'],
  },
  {
    title: 'New password',
    fields: ['newPassword'],
  },
  {
    title: 'Confirm new password',
    fields: ['newPasswordConfirm'],
  },
]

export const selectOrganizationHeaders = () => [
  {
    title: 'Name',
    fields: ['name'],
  },
  {
    title: 'Website',
    fields: ['website'],
  },
  {
    title: 'Phone number',
    fields: ['phone'],
  },
  {
    title: 'Address',
    fields: ['address'],
  },
  {
    title: 'Country',
    fields: ['country'],
  },
  {
    title: 'Location',
    fields: ['location'],
  },
]

export const selectNotificationsHeaders = () => [
  {
    title: 'Maximum Daily Spend',
    fields: ['maxDailySpend'],
  },
  {
    title: 'Low Balance Notification',
    fields: ['balanceNotification'],
  },
  {
    title: 'Daily Spend Notification',
    fields: ['spendNotification'],
  },
]

export const selectNotificationsSpecs = createSelector(selectUserRole, (role) => {
  const isOwner = ROLES.OWNER.includes(role)
  const isOwnerOrManager = isOwner || ROLES.MANAGER.includes(role)

  return {
    balanceNotification: {
      type: 'number',
      editable: isOwnerOrManager,
      label: 'Low Balance Notification',
      info: "We'll notify you when your account balance drops to this amount, so you can top up and avoid campaign disruptions.",
      disabledText: 'Only the account owner or manager can change this setting.',
      showLabel: true,
      showInfoTop: true,
    },
    spendNotification: {
      type: 'number',
      editable: isOwnerOrManager,
      label: 'Daily Spend Notification',
      info: "We'll notify you when you've spent this amount in a day, so you notice if anything seems out of the ordinary. (Days start at midnight UTC. Set to 0 to disable.)",
      disabledText: 'Only the account owner or manager can change this setting.',
      showLabel: true,
      showInfoTop: true,
    },
    maxDailySpend: {
      type: 'number',
      editable: isOwner,
      label: 'Maximum Daily Spend',
      info: 'The maximum your account can spend on SMS, Voice, WhatsApp, and Airtime Top-ups in a day. Once you reach this limit, all subsequent messages will fail during that day. (Days start at midnight UTC. Set to 0 to disable.)',
      disabledText: 'Only the account owner can change this setting.',
      showLabel: true,
      showInfoTop: true,
    },
  }
})

export const selectApiKeys = createSelector(pathOr([], ['user', 'apiKeys', 'data', 'tokens']), (apiKeys) =>
  apiKeys.filter((apiKey) => !apiKey.deleted),
)
