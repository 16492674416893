import { find, pathOr, propEq } from 'ramda'
import WhatsappIcon from '@mui/icons-material/WhatsApp'
import {
  BackendCampaignTemplate,
  CampaignTemplateType,
  CampaignTemplateUrl,
  LocalActionType,
  PartFamily,
  PartTypes,
  SenderIdTypes,
} from './types'

export const Q_ACTIONS = {
  [LocalActionType.SendSMS]: {
    type: LocalActionType.SendSMS,
    label: 'Send SMS to contact',
    key: 'sendSMS',
    icon: 'sms',
  },
  [LocalActionType.PlayAudio]: {
    type: LocalActionType.PlayAudio,
    label: 'Play audio message',
    key: 'play',
    icon: 'phone',
  },
  [LocalActionType.API]: {
    type: LocalActionType.API,
    label: 'Make API request (webhook)',
    key: 'makeAPIRequest',
    icon: 'settings_remote',
  },
  [LocalActionType.Replay]: {
    type: LocalActionType.Replay,
    label: 'Replay the question',
    key: 'replayQuestion',
    icon: 'replay_circle_filled',
  },
  [LocalActionType.Resend]: {
    type: LocalActionType.Resend,
    label: 'Resend the question',
    key: 'resendQuestion',
    icon: 'send',
  },
  [LocalActionType.Hangup]: {
    type: LocalActionType.Hangup,
    label: 'Hangup',
    key: 'hangup',
    icon: 'call_end',
  },
  [LocalActionType.FinishFlow]: {
    type: LocalActionType.FinishFlow,
    label: 'End campaign for contact',
    key: 'finishFlow',
    icon: 'stop',
  },
  [LocalActionType.UnsubscribeAll]: {
    type: LocalActionType.UnsubscribeAll,
    label: 'Unsubscribe contact from all campaigns',
    key: 'unsubscribeAll',
    icon: 'unsubscribe',
  },
  [LocalActionType.SubscribeToCampaign]: {
    type: LocalActionType.SubscribeToCampaign,
    label: 'Subscribe contact to campaign',
    key: 'subscribeToCampaign',
    icon: 'input',
  },
  [LocalActionType.SendSMSToPhoneNumbers]: {
    type: LocalActionType.SendSMSToPhoneNumbers,
    label: 'Send SMS to someone else',
    key: 'sendSMSToPhoneNumbers',
    icon: 'send_to_mobile',
  },
  [LocalActionType.Transfer]: {
    type: LocalActionType.Transfer,
    label: 'Transfer call to someone',
    key: 'transfer',
    icon: 'phone',
  },
  [LocalActionType.Jump]: {
    type: LocalActionType.Jump,
    label: 'Jump to question',
    key: 'jump',
    icon: 'account_tree',
  },
  [LocalActionType.Topup]: {
    type: LocalActionType.Topup,
    label: 'Send Airtime Topup to contact',
    key: 'topup',
    icon: 'account_balance_wallet',
  },
  [LocalActionType.UpdateContact]: {
    type: LocalActionType.UpdateContact,
    label: 'Update contact',
    key: 'updateContact',
    icon: 'contacts',
  },
  [LocalActionType.SendEmail]: {
    type: LocalActionType.SendEmail,
    label: 'Send Email',
    key: 'sendEmail',
    icon: 'email',
  },
  [LocalActionType.SendWhatsApp]: {
    type: LocalActionType.SendWhatsApp,
    label: 'Send WhatsApp Message',
    key: 'sendWhatsapp',
    icon: WhatsappIcon,
  },
  [LocalActionType.PauseSubscription]: {
    type: LocalActionType.PauseSubscription,
    label: 'Pause Campaign for Participant',
    key: 'setPaused',
    icon: 'pause',
  },
  [LocalActionType.ResumeSubscription]: {
    type: LocalActionType.ResumeSubscription,
    label: "Resume contact's subscription in a campaign",
    key: 'setPaused',
    icon: 'play_arrow',
  },
}

export const Q_ACTIONS_DESCRIPTIONS = {
  [LocalActionType.PlayAudio]: {
    answer: "Play an audio message, e.g., as a response based on the contact's answer to the question.",
    invalid:
      'Play an audio message, e.g., to let the contact know their answer was invalid and to give them another chance to answer the question.',
    timeout: 'Play a message, e.g., to remind the contact that you are waiting for an answer to the question.',
    failure: 'Play an audio message, e.g., to let the contact know that the question is closed.',
    default: 'Play an audio message',
  },
  [LocalActionType.UpdateContact]: {
    answer: "Save information to the contact's profile, e.g., the contact's answer to the question.",
    invalid: "Save information to the contact's profile, e.g., the contact's invalid answer to the question.",
    timeout:
      "Save information to the contact's profile, e.g., that the contact did not reply within the timeout period.",
    failure:
      "Save information to the contact's profile, e.g., that the contact failed to answer the question after all attempts.",
    callAnswered: "Save information to the contact's profile, e.g., that the contact answered the phone call.",
    callNotAnswered: "Save information to the contact's profile, e.g., that the contact did not answer the phone call.",
    callError:
      "Save information to the contact's profile, e.g., that there was an error when trying to call the contact.",
    topupSuccess: "Save information to the contact's profile, e.g., that the top-up succeeded.",
    topupFailure: "Save information to the contact's profile, e.g., that the top-up failed.",
    apiSuccess: "Save information to the contact's profile, e.g., fields from the response to your API request.",
    apiFailure: "Save information to the contact's profile, e.g., that your API request failed, along with the error.",
    default: "Save information to any field in the contact's profile.",
  },
  [LocalActionType.SendSMS]: {
    answer: "Send a message to the contact, e.g., as a response based on the contact's answer to the question.",
    invalid:
      'Send the contact a message, e.g., to let them know their answer was invalid and to give them another chance to answer the question.',
    timeout: 'Send the contact a message, e.g., to remind them that you are waiting for an answer to the question.',
    failure: 'Send a message to the contact, e.g., to let them know that the question is closed.',
    callAnswered: 'Send a message to the contact, e.g., to summarize the phone call they just received.',
    callNotAnswered: 'Send a message to the contact, e.g., to summarize the phone call that they missed.',
    callError: 'Send a message to the contact, e.g., to summarize the phone call that did not go through.',
    topupSuccess:
      'Send a message to the contact, e.g., to let them know that the top-up they just received came from you.',
    topupFailure: 'Send a message to the contact, e.g., to let them know that the top-up failed.',
    apiSuccess: 'Send a message to the contact, e.g., with fields from the response to your API request.',
    apiFailure: "Send a message to the contact, e.g., to notify them you couldn't get the data from the API request.",
    default: 'Send a message to the contact.',
  },
  [LocalActionType.Jump]: {
    answer: 'Jump the contact to a later question in the survey, skipping everything in between.',
    failure: 'Jump the contact to a later question in the survey, skipping everything in between.',
    apiSuccess:
      'Jump the contact to a later question in the survey, skipping everything in between, if your API request is successful.',
    apiFailure:
      'Jump the contact to a later question in the survey, skipping everything in between, if your API request fails.',
    default: 'Jump the contact to a later question in the survey, skipping everything in between.',
  },
  [LocalActionType.SendWhatsApp]: {
    answer:
      "Send a WhatsApp message to the contact, e.g., as a response based on the contact's answer to the question.",
    invalid:
      'Send the contact a WhatsApp message, e.g., to let them know their answer was invalid and to give them another chance to answer the question.',
    timeout:
      'Send the contact a WhatsApp message, e.g., to remind them that you are waiting for an answer to the question.',
    failure: 'Send the contact a WhatsApp message, e.g., to let the contact know that the question is closed.',
    apiSuccess: 'Send a WhatsApp message to the contact, e.g., with fields from the response to your API request.',
    apiFailure:
      "Send a WhatsApp message to the contact, e.g., to notify them you couldn't get the data from the API request.",
    default: 'Send a WhatsApp message to the contact.',
  },
  [LocalActionType.Topup]: {
    answer: 'Send mobile credit to the contact, e.g., as a reward or incentive.',
    invalid: 'Send mobile credit to the contact, e.g., as an incentive.',
    timeout: 'Send mobile credit to the contact, e.g., as an incentive.',
    failure: 'Send mobile credit to the contact, e.g., as an incentive.',
    callAnswered: 'Send mobile credit to the contact, e.g., as a reward or incentive for answering the call.',
    callNotAnswered: 'Send mobile credit to the contact, e.g., as an encouragement to pick up the next phone call.',
    callError: 'Send mobile credit to the contact, e.g., as a reward or incentive.',
    apiSuccess: 'Send mobile credit to the contact, e.g., as a reward or incentive, if your API request is successful.',
    apiFailure: 'Send mobile credit to the contact, e.g., as a reward or incentive, if your API request fails.',
    default: 'Send mobile credit to the contact, e.g., as a reward or incentive.',
  },
  [LocalActionType.SubscribeToCampaign]: {
    answer:
      "Subscribe the contact to another campaign—immediately or in the future—based on the contact's answer to the question.",
    invalid: 'Subscribe the contact to another campaign—immediately or in the future.',
    timeout: 'Subscribe the contact to another campaign—immediately or in the future.',
    failure: 'Subscribe the contact to another campaign—immediately or in the future.',
    callAnswered: 'Subscribe the contact to another campaign—immediately or in the future.',
    callNotAnswered: 'Subscribe the contact to another campaign—immediately or in the future.',
    callError: 'Subscribe the contact to another campaign—immediately or in the future.',
    topupSuccess: 'Subscribe the contact to another campaign—immediately or in the future.',
    topupFailure:
      'Subscribe the contact to another campaign, e.g., one that asks the contact for a different number to top-up.',
    apiSuccess:
      'Subscribe the contact to another campaign—immediately or in the future—if your API request is successful.',
    apiFailure: 'Subscribe the contact to another campaign—immediately or in the future—if your API request fails.',
    inbound: 'Subscribe the contact to a campaign—immediately or in the future.',
    default: 'Subscribe the contact to another campaign—immediately or in the future.',
  },
  [LocalActionType.API]: {
    answer: "Send information to an external system, e.g., send the contact's answer to your database or CRM.",
    invalid: "Send information to an external system, e.g., send the contact's invalid answer to your database or CRM.",
    timeout:
      'Send information to an external system, e.g., add to your database or CRM that the contact did not reply within the timeout period.',
    failure:
      'Send information to an external system, e.g., add to your database or CRM that the contact failed to answer the question after all attempts.',
    callAnswered:
      'Send information to an external system, e.g., add to your database or CRM that the contact answered the phone call.',
    callNotAnswered:
      'Send information to an external system, e.g., add to your database or CRM that the contact did not answer the phone call.',
    callError:
      'Send information to an external system, e.g., add to your database or CRM that there was an error when trying to call the contact.',
    topupSuccess:
      'Send information to an external system, e.g., add to your database or CRM that the top-up succeeded.',
    topupFailure: 'Send information to an external system, e.g., add to your database or CRM that the top-up failed.',
    apiSuccess:
      'Send information to an external system, e.g., send fields from the response to your API request to your database or CRM.',
    apiFailure:
      'Send information to an external system, e.g., add to your database or CRM that your API request failed, along with the error.',
    default: 'Send information to an external system, e.g., to your database or CRM.',
  },
  [LocalActionType.SendSMSToPhoneNumbers]: {
    answer:
      "Send a message to multiple people, e.g., notify your team about the contacts' answer. SMS recipients can be personalized via custom fields.",
    invalid:
      "Send a message to multiple people, e.g., notify your team about the contact's invalid response. SMS recipients can be personalized via custom fields.",
    timeout:
      'Send a message to multiple people, e.g., notify your team that the contact did not reply within the timeout period. SMS recipients can be personalized via custom fields.',
    failure:
      'Send a message to multiple people, e.g., notify your team that the contact failed to answer the question after all attempts. SMS recipients can be personalized via custom fields.',
    callAnswered:
      'Send a message to multiple people, e.g., notify your team that the contact answered the phone call. SMS recipients can be personalized via custom fields.',
    callNotAnswered:
      'Send a message to multiple people, e.g., notify your team that the contact did not answer the phone call. SMS recipients can be personalized via custom fields.',
    callError:
      'Send a message to multiple people, e.g., notify your team that there was an error when trying to call the contact. SMS recipients can be personalized via custom fields.',
    topupSuccess:
      'Send a message to multiple people, e.g., notify your team that the top-up succeeded. SMS recipients can be personalized via custom fields.',
    topupFailure:
      'Send a message to multiple people, e.g., notify your team that the top-up failed. SMS recipients can be personalized via custom fields.',
    apiSuccess:
      'Send a message to multiple people, e.g., notify your team with fields from the response to your API request. SMS recipients can be personalized via custom fields.',
    apiFailure:
      'Send a message to multiple people, e.g., notify your team that your API request failed, along with the error.. SMS recipients can be personalized via custom fields.',
    default:
      'Send a message to multiple people, e.g., send a notification to your team. SMS recipients can be personalized via custom fields.',
  },
  [LocalActionType.SendEmail]: {
    answer:
      'Send an email to multiple people, e.g., send a notification to your team. Email recipients can be personalized via custom fields.',
    invalid:
      "Send an email to multiple people, e.g., notify your team about the contact's invalid response. Email recipients can be personalized via custom fields.",
    timeout:
      'Send an email to multiple people, e.g., notify your team that the contact did not reply within the timeout period. Email recipients can be personalized via custom fields.',
    failure:
      'Send an email to multiple people, e.g., notify your team that the contact failed to answer the question after all attempts. Email recipients can be personalized via custom fields.',
    callAnswered:
      'Send an email to multiple people, e.g., notify your team that the contact answered the phone call. Email recipients can be personalized via custom fields.',
    callNotAnswered:
      'Send an email to multiple people, e.g., notify your team that the contact did not answer the phone call. Email recipients can be personalized via custom fields.',
    callError:
      'Send an email to multiple people, e.g., notify your team that there was an error when trying to call the contact. Email recipients can be personalized via custom fields.',
    topupSuccess:
      'Send an email to multiple people, e.g., notify your team that the top-up succeeded. Email recipients can be personalized via custom fields.',
    topupFailure:
      'Send an email to multiple people, e.g., notify your team that the top-up failed. Email recipients can be personalized via custom fields.',
    apiSuccess:
      'Send an email to multiple people, e.g., notify your team with fields from the response to your API request. Email recipients can be personalized via custom fields.',
    apiFailure:
      'Send an email to multiple people, e.g., notify your team that your API request failed, along with the error. Email recipients can be personalized via custom fields.',
    default:
      'Send an email to multiple people, e.g., send a notification to your team. Email recipients can be personalized via custom fields.',
  },
  [LocalActionType.FinishFlow]: {
    answer:
      'Unsubscribe the contact from this campaign. The contact will not receive any more messages in this campaign.',
    invalid:
      'Unsubscribe the contact from this campaign. The contact will not receive any more messages in this campaign.',
    timeout:
      'Unsubscribe the contact from this campaign. The contact will not receive any more messages in this campaign.',
    failure:
      'Unsubscribe the contact from this campaign. The contact will not receive any more messages in this campaign.',
    callAnswered:
      'Unsubscribe the contact from this campaign. The contact will not receive any more calls / messages in this campaign.',
    callNotAnswered:
      'Unsubscribe the contact from this campaign. The contact will not receive any more calls / messages in this campaign.',
    callError:
      'Unsubscribe the contact from this campaign. The contact will not receive any more calls / messages in this campaign.',
    topupSuccess:
      'Unsubscribe the contact from this campaign. The contact will not receive any more top-ups / messages in this campaign.',
    topupFailure:
      'Unsubscribe the contact from this campaign. The contact will not receive any more top-ups / messages in this campaign.',
    apiSuccess:
      'Unsubscribe the contact from this campaign. No more API requests will be run and the contact will not receive any more messages in this campaign.',
    apiFailure:
      'Unsubscribe the contact from this campaign. No more API requests will be run and the contact will not receive any more messages in this campaign.',
    default:
      'Unsubscribe the contact from this campaign. The contact will not receive any more messages in this campaign.',
  },
  [LocalActionType.Transfer]: {
    answer: 'Transfer the call to another phone number, e.g., so the contact can speak with an agent.',
    default: 'Transfer the call to another phone number, e.g., so the contact can speak with an agent.',
  },
  [LocalActionType.Replay]: {
    invalid: 'Play the question again to give the contact another chance to answer validly.',
    timeout:
      'Play the question again, e.g., to remind the contact what the question is and to give them another chance to answer.',
  },
  [LocalActionType.Resend]: {
    invalid: 'Send the question again to give the contact another chance to answer validly.',
    timeout:
      'Send the question again, e.g., to remind the contact what the question is and to give them another chance to answer.',
  },
  [LocalActionType.Hangup]: {
    invalid: "End the phone call, e.g., if you don't want the contact to continue because their answer was invalid.",
    timeout:
      "End the phone call, e.g., if you don't want the contact to continue because they did not reply within the timeout period.",
    failure:
      "End the phone call, e.g., if you don't want the contact to continue because they failed to answer the question after all attempts.",
    default: 'End the phone call.',
  },
  [LocalActionType.UnsubscribeAll]: {
    inbound:
      'The contact will be unsubscribed from all campaigns and will not receive any more messages from any campaign, e.g., for opt out keywords, like STOP.',
  },
  [LocalActionType.PauseSubscription]: {
    default:
      'Pause the campaign for this participant. If this is an IVR, the call will be hung up. You can resume the paused campaign subscription later.',
  },
  [LocalActionType.ResumeSubscription]: {
    default: "Resume the campaign for this participant. If this is an IVR, we'll start a new call.",
  },
}

export const MESSAGE_PERSONALIZATION = {
  message: [
    {
      label: 'Message Text',
      value: 'message.text',
    },
    {
      label: 'Message Virtual Number',
      value: 'message.virtualNumber',
    },
  ],
}

export const TYPES = {
  VOICEBLAST: {
    defaultType: 'voice',
    name: 'voiceblast',
    url: 'voiceblast',
    template: BackendCampaignTemplate.VoiceBlast,
    title: 'Voice Blast',
    icon: 'phone',
    senderIdInfo: 'Select what should appear on Contacts phones as whom the call is from (e.g., MyOrg or 12125551212)',
  },
  SMSBLAST: {
    defaultType: 'sms',
    name: 'smsblast',
    url: 'smsblast',
    template: BackendCampaignTemplate.SMSBlast,
    title: 'SMS Blast Campaign',
    icon: 'sms',
    senderIdInfo:
      'Select what should appear on Contacts phones as whom the SMS Message is from (e.g., MyOrg or 12125551212)',
  },
  IVR: {
    defaultType: 'voice',
    name: 'ivr',
    url: 'voice-survey',
    template: BackendCampaignTemplate.VoiceSurvey,
    title: 'Voice IVR Survey',
    icon: 'phone',
    callResultActions: true,
    callResultActionsPath: ['variables', 'callResultActions'],
    reconnectActions: true,
    reconnectActionsPath: ['variables', 'reconnectActions'],
    isSurvey: true,
    welcome: true,
    welcomeType: 'audio',
    welcomeKey: 'voice',
    welcomeTypeProperties: ['playfile', 'say', 'voice'],
    senderIdInfo:
      'Select what should appear on Contacts phones as whom the Voice Call is from (e.g., MyOrg or 12125551212)',
    content: {
      voice: {
        name: 'voice',
        wrap: PartTypes.VoiceQuestion,
        unwrap: 'parts',
        qunwrap: [
          'audio',
          'keypressAfterAudio',
          'retries',
          'onAnswer',
          'repeatQuestion',
          'onInvalidReply',
          'onTimeout',
          'onRetriesExhausted',
          'questionId',
        ],
        path: ['voiceQuestion', 'audio', 'playfile'],
      },
    },
  },
  DRIP: {
    defaultType: 'sms',
    name: 'drip',
    url: 'drip',
    template: BackendCampaignTemplate.Drip,
    title: 'Drip Campaign',
    icon: 'dynamic_feed',
    senderIdInfo:
      'Select what should appear on Contacts phones as whom each message or Voice Call is from (e.g., MyOrg or 12125551212)',
    callResultActions: true,
    callResultActionsPath: ['callResultActions'],
  },
  REMINDER: {
    icon: 'alarm',
    name: 'reminder',
    template: BackendCampaignTemplate.Reminder,
    title: 'Reminder Campaign',
    url: 'reminder',
  },
  SMSSURVEY: {
    defaultType: 'sms',
    name: 'smssurvey',
    url: 'sms-survey',
    template: BackendCampaignTemplate.SMSSurvey,
    title: 'SMS Survey',
    icon: 'assignment',
    isSurvey: true,
    welcome: true,
    welcomeType: 'message',
    welcomeTypeProperties: ['text'],
    welcomeKey: 'sms',
    senderIdInfo:
      'Select what should appear on Contacts phones as whom each SMS Message is from (e.g., MyOrg or 12125551212)',
    content: {
      sms: {
        name: 'sms',
        wrap: PartTypes.SMSQuestion,
        qwrap: PartTypes.SMSQuestion,
        labels: {
          smsMessage: { title: 'SMS Message', key: PartTypes.SMSMessage },
          smsQuestion: { title: 'SMS Question', key: PartTypes.SMSQuestion },
        },
        unwrap: ['message'],
        path: ['message', 'text'],
        qunwrap: ['message', 'retries', 'onAnswer', 'onInvalidReply', 'onTimeout', 'onRetriesExhausted'],
      },
    },
  },
  WHATSAPPSURVEY: {
    defaultType: 'whatsapp',
    name: 'whatsappsurvey',
    url: 'whatsapp-survey',
    template: BackendCampaignTemplate.WhatsAppSurvey,
    title: 'WhatsApp Survey',
    icon: () => <WhatsappIcon sx={{ color: 'text.secondary' }} />,
    isSurvey: true,
    welcome: false,
    welcomeType: 'message',
    welcomeTypeProperties: ['text'],
    welcomeKey: 'whatsapp',
    senderIdInfo:
      'Select what should appear on Contacts phones as whom the messages are from (e.g., MyOrg or 12125551212)',
    content: {
      whatsapp: {
        name: 'whatsapp',
        wrap: 'whatsappMessage',
        qwrap: 'whatsappQuestion',
        labels: {
          whatsappMessage: { title: 'WhatsApp Message', key: 'whatsappMessage' },
          whatsappQuestion: { title: 'WhatsApp Question', key: 'whatsappQuestion' },
        },
        unwrap: ['message'],
        path: ['message'],
        qunwrap: ['message', 'retries', 'onAnswer', 'onInvalidReply', 'onTimeout', 'onRetriesExhausted', 'openEnded'],
        qSenderId: 'senderIdWhatsapp',
      },
    },
  },
  CATI: {
    defaultType: 'cati',
    name: 'cati',
    url: 'cati',
    template: BackendCampaignTemplate.CATI,
    title: 'CATI Survey',
    icon: 'headset_mic',
    senderIdInfo:
      'Select what should appear on Contacts phones as whom the SMS Message is from (e.g., MyOrg or 12125551212)',
    isSurvey: true,
    welcome: false,
    callResultActions: false,
    content: {
      cati: {
        name: 'cati',
        wrap: 'catiMessage',
        qwrap: 'catiQuestion',
        labels: {
          catiMessage: { title: 'CATI Message', key: 'catiMessage' },
          catiQuestion: { title: 'CATI Question', key: 'catiQuestion' },
        },
        unwrap: ['message'],
        path: ['message'],
        qunwrap: ['message', 'retries', 'onAnswer', 'onInvalidReply', 'onTimeout', 'onRetriesExhausted', 'openEnded'],
      },
    },
  },
}

export const MESSAGE_TYPES = [
  {
    key: PartTypes.SMSMessage,
    family: PartFamily.SMS,
    label: 'SMS Message',
  },
  {
    key: PartTypes.SMSQuestion,
    family: PartFamily.SMS,
    label: 'SMS Question',
  },
  {
    key: PartTypes.VoiceMessage,
    family: PartFamily.Voice,
    label: 'Voice Call',
  },
  {
    key: PartTypes.VoiceQuestion,
    family: PartFamily.Voice,
    label: 'Voice Question',
  },
  {
    key: PartTypes.ActionsOnly,
    family: PartFamily.Action,
    label: 'Actions',
  },
  {
    key: PartTypes.WhatsAppMessage,
    family: PartFamily.WhatsApp,
    label: 'WhatsApp Message',
  },
  {
    key: PartTypes.WhatsAppQuestion,
    family: PartFamily.WhatsApp,
    label: 'WhatsApp Question',
  },
  {
    key: PartTypes.CATIMessage,
    family: PartFamily.CATI,
    label: 'CATI Message',
  },
  {
    key: PartTypes.CATIQuestion,
    family: PartFamily.CATI,
    label: 'CATI Question',
  },
  {
    key: PartTypes.ApiCall,
    family: PartFamily.API,
    label: 'API Request',
  },
  {
    key: PartTypes.Topup,
    family: PartFamily.Topup,
    label: 'Airtime Topup',
  },
]

export const SENDER_ID_TYPES = {
  callerIds: {
    label: 'Pick a virtual number or sender ID:',
    dataSource: [
      {
        listName: 'defaultSenderId',
        label: '',
        types: [
          {
            key: 'sender',
            label: '',
            numeric: false,
          },
        ],
      },
      {
        listName: 'voice',
        label: 'Virtual Numbers',
        types: [
          {
            key: 'phonenumber',
            label: 'Virtual Numbers',
            numeric: false,
          },
          {
            key: 'sender',
            label: 'Sender Ids',
            numeric: true,
          },
        ],
      },
    ],
  },
  senderId: {
    label: 'Pick a sender ID:',
    dataSource: [
      {
        listName: 'defaultSenderId',
        label: '',
        types: [
          {
            key: 'sender',
            label: '',
            numeric: false,
          },
        ],
      },
      {
        listName: 'sms',
        label: 'Virtual Numbers',
        types: [
          {
            key: 'sender',
            label: 'Sender Ids',
            numeric: false,
          },
          {
            key: 'phonenumber',
            label: 'Virtual Numbers',
            numeric: false,
          },
        ],
      },
    ],
  },
  senderIdQuestions: {
    label: 'Pick a virtual number or sender ID:',
    dataSource: [
      {
        listName: 'sms',
        label: 'Virtual Numbers',
        types: [
          {
            key: 'phonenumber',
            label: 'Virtual Numbers',
            numeric: false,
          },
          {
            key: 'sender',
            label: 'Sender Ids',
            numeric: false,
          },
        ],
      },
    ],
  },
  senderIdReplies: {
    label: 'Which Phone Number will receive responses?',
    dataSource: [
      {
        listName: 'sms',
        label: 'Virtual Numbers',
        types: [
          {
            key: 'phonenumber',
            label: 'Virtual Numbers',
            numeric: false,
          },
        ],
      },
    ],
  },
  senderIdWhatsapp: {
    label: 'Pick a sender ID for WhatsApp Messages:',
    dataSource: [
      {
        listName: 'whatsapp',
        label: 'WhatsApp Numbers',
        types: [
          {
            key: 'phonenumber',
            label: 'WhatsApp Sender Ids',
            numeric: false,
          },
        ],
      },
    ],
  },
}

export const SENDER_ID_MAP: Record<string, SenderIdTypes[]> = {
  voiceCall: [SenderIdTypes.CallerIds],
  smsQuestion: [SenderIdTypes.SenderIdQuestions, SenderIdTypes.SenderIdReplies],
  smsMessage: [SenderIdTypes.SenderId],
  whatsapp: [SenderIdTypes.SenderIdWhatsapp],
  catiMessage: [],
  catiQuestion: [],
}

export const SMS_ACTION_DEFAULT_SENDERID = {
  smsQuestion: {
    value: SenderIdTypes.SenderIdQuestions,
    label: 'SMS Questions Sender ID',
  },
  voiceQuestion: {
    value: SenderIdTypes.SenderId,
    label: 'SMS Messages Sender ID',
  },
  actionsOnly: {
    value: SenderIdTypes.SenderId,
    label: 'SMS Messages Sender ID',
  },
  catiQuestion: {
    value: SenderIdTypes.SenderId,
    label: 'SMS Messages Sender ID',
  },
}

export const messageTypeTextMap = {
  'sms-q': {
    label: 'Question',
    conditionsTitle: 'Ask this question',
  },
  'sms-m': {
    label: 'Message',
    conditionsTitle: 'Send this message',
  },
  'voice-q': {
    label: 'Question',
    conditionsTitle: 'Ask this question',
  },
  'voice-m': {
    label: 'Message',
    conditionsTitle: 'Send this message',
  },
  'action-q': {
    label: 'Actions Only',
    conditionsTitle: 'Run these actions',
  },
  'action-m': {
    label: 'Question Only',
    conditionsTitle: 'Run these actions',
  },
  'whatsapp-q': {
    label: 'Question',
    conditionsTitle: 'Ask this question',
  },
  'whatsapp-m': {
    label: 'Message',
    conditionsTitle: 'Send this message',
  },
  'cati-q': {
    label: 'Question',
    conditionsTitle: 'Ask this question',
  },
  'cati-m': {
    label: 'Message',
    conditionsTitle: 'Send this message',
  },
  'topup-q': {
    label: 'Airtime Topup',
    conditionsTitle: 'Send this Airtime Topup',
  },
  'api-q': {
    label: 'API Request',
    conditionsTitle: 'Make this API Request',
  },
}

export const cropText = ({
  text,
  limit,
  firstCutOff,
  secondCutOff,
}: {
  text: string
  limit: number
  firstCutOff: number
  secondCutOff: number
}) => {
  if (text.length > limit) {
    return `${text.substr(0, firstCutOff)}...${text.substr(text.length - secondCutOff, text.length)}`
  }
  return text
}

export const iconMap = {
  smsMessage: 'sms',
  smsQuestion: 'question_answer',
  voiceMessage: 'phone',
  voiceQuestion: 'ring_volume',
  actionsOnly: 'account_tree',
  whatsappQuestion: 'whatsapp',
  whatsappMessage: 'whatsapp',
  catiMessage: 'headset_mic',
  catiQuestion: 'headset_mic',
  apiCall: 'http',
  topup: 'money',
}

export const displayMap = {
  [LocalActionType.SendSMS]: {
    path: [Q_ACTIONS.sms.key, 'text'],
    icon: 'sms',
  },
  [LocalActionType.SendSMSToPhoneNumbers]: {
    path: [Q_ACTIONS.sendSMSToPhoneNumbers.key, 'text'],
    icon: 'sms',
  },
  [LocalActionType.API]: {
    path: [Q_ACTIONS.api.key],
    icon: 'settings_remote',
    url: {
      path: [Q_ACTIONS.api.key, 'url'],
      icon: 'settings_remote',
    },
  },
  [LocalActionType.PlayAudio]: {
    path: [Q_ACTIONS.voice.key, 'playfile'],
    icon: 'phone',
  },
  [LocalActionType.Transfer]: {
    path: [Q_ACTIONS.transfer.key, 'to'],
    icon: 'phone',
  },
  [LocalActionType.Jump]: {
    path: [Q_ACTIONS.jump.key, 'nextPart'],
    icon: 'account_tree',
  },
  [LocalActionType.Topup]: {
    path: [Q_ACTIONS.topup.key],
    icon: 'account_balance_wallet',
  },
  [LocalActionType.UpdateContact]: {
    path: [Q_ACTIONS.updateContact.key],
    icon: 'contacts',
  },
  [LocalActionType.SubscribeToCampaign]: {
    path: [Q_ACTIONS.subscribeToCampaign.key],
    icon: 'input',
  },
  [LocalActionType.SendWhatsApp]: {
    path: [Q_ACTIONS.sendWhatsapp.key],
    icon: 'whatsapp',
  },
  [LocalActionType.SendEmail]: {
    path: [Q_ACTIONS.sendEmail.key, 'to'],
    icon: 'email',
  },
  [LocalActionType.ResumeSubscription]: {
    path: [Q_ACTIONS.resumeSubscription.key, 'campId'],
    icon: 'play_arrow',
  },
}

export const getMessageLabel = (key: string) => pathOr('', ['label'], find(propEq('key', key), MESSAGE_TYPES))

export const TEMPLATES_TO_TYPE: Record<BackendCampaignTemplate, CampaignTemplateType> = {
  [BackendCampaignTemplate.CATI]: CampaignTemplateType.CATI,
  [BackendCampaignTemplate.Drip]: CampaignTemplateType.Drip,
  [BackendCampaignTemplate.Reminder]: CampaignTemplateType.Reminder,
  [BackendCampaignTemplate.SMSBlast]: CampaignTemplateType.SMSBlast,
  [BackendCampaignTemplate.SMSSurvey]: CampaignTemplateType.SMSSurvey,
  [BackendCampaignTemplate.VoiceBlast]: CampaignTemplateType.VoiceBlast,
  [BackendCampaignTemplate.VoiceSurvey]: CampaignTemplateType.VoiceSurvey,
  [BackendCampaignTemplate.WhatsAppSurvey]: CampaignTemplateType.WhatsAppSurvey,
}

export const TYPES_TO_TEMPLATE = (Object.keys(TEMPLATES_TO_TYPE) as BackendCampaignTemplate[]).reduce(
  (accum, key: BackendCampaignTemplate) => ({
    ...accum,
    [TEMPLATES_TO_TYPE[key]]: key,
  }),
  {} as Record<CampaignTemplateType, BackendCampaignTemplate>,
)

export const TEMPLATE_TYPES = [
  BackendCampaignTemplate.CATI,
  BackendCampaignTemplate.Drip,
  BackendCampaignTemplate.Reminder,
  BackendCampaignTemplate.SMSBlast,
  BackendCampaignTemplate.SMSSurvey,
  BackendCampaignTemplate.VoiceSurvey,
  BackendCampaignTemplate.WhatsAppSurvey,
]

export const TEMPLATES_TO_TITLE: Record<BackendCampaignTemplate, string> = {
  [BackendCampaignTemplate.CATI]: TYPES.CATI.title,
  [BackendCampaignTemplate.Drip]: TYPES.DRIP.title,
  [BackendCampaignTemplate.Reminder]: TYPES.REMINDER.title,
  [BackendCampaignTemplate.SMSBlast]: TYPES.SMSBLAST.title,
  [BackendCampaignTemplate.SMSSurvey]: TYPES.SMSSURVEY.title,
  [BackendCampaignTemplate.VoiceBlast]: TYPES.VOICEBLAST.title,
  [BackendCampaignTemplate.VoiceSurvey]: TYPES.IVR.title,
  [BackendCampaignTemplate.WhatsAppSurvey]: TYPES.WHATSAPPSURVEY.title,
}

export const URLS_TO_TYPE: Record<CampaignTemplateUrl, CampaignTemplateType> = {
  [CampaignTemplateUrl.CATI]: CampaignTemplateType.CATI,
  [CampaignTemplateUrl.Drip]: CampaignTemplateType.Drip,
  [CampaignTemplateUrl.Reminder]: CampaignTemplateType.Reminder,
  [CampaignTemplateUrl.SMSBlast]: CampaignTemplateType.SMSBlast,
  [CampaignTemplateUrl.SMSSurvey]: CampaignTemplateType.SMSSurvey,
  [CampaignTemplateUrl.VoiceBlast]: CampaignTemplateType.VoiceBlast,
  [CampaignTemplateUrl.VoiceSurvey]: CampaignTemplateType.VoiceSurvey,
  [CampaignTemplateUrl.WhatsAppSurvey]: CampaignTemplateType.WhatsAppSurvey,
}

export const CAMPAIGN_STATUS_MAP = {
  Draft: {
    published: 'false',
  },
  Launched: {
    published: 'true',
    launched: 'true',
  },
  Scheduled: {
    published: 'true',
    launched: 'false',
  },
  Stopped: {
    aborted: 'true',
  },
}

export const CAMPAIGN_FILTERABLE_ATTRIBUTES = [
  'archived',
  'published',
  'launched',
  'aborted',
  'page',
  'size',
  'search',
  'templates',
]

export const DRAGTYPE = 'CAMPAIGN_QUESTION_ACTION'

export const repeatQuestionOptions = [
  {
    label: '0',
    value: '0',
  },
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '6',
    value: '6',
  },
  {
    label: '7',
    value: '7',
  },
  {
    label: '8',
    value: '8',
  },
  {
    label: '9',
    value: '9',
  },
  {
    label: '*',
    value: '*',
  },
  {
    label: '#',
    value: '#',
  },
]

// Defaults
export const TIMEOUT_DEFAULT = 10
export const CAMPAIGN_RETRIES = 1
export const smsMaxLength = 7
export const MAX_VOICE_RETRIES = 3
export const DEFAULT_SPEECH_LANGAUGE = 'en-US'
export const defaultRepeatQuestion = {
  reply: '0',
}

// ACTIONS
export const ACTION_GET_CAMPAIGNS_REQUEST = '@actions/CAMPAIGNS/GET_LIST_REQUEST'
export const ACTION_GET_CAMPAIGNS_SUCCESS = '@actions/CAMPAIGNS/GET_LIST_SUCCESS'
export const ACTION_GET_CAMPAIGNS_FAILURE = '@actions/CAMPAIGNS/GET_LIST_FAILURE'

export const ACTION_GET_CAMPAIGN_ITEM_REQUEST = '@actions/CAMPAIGNS/GET_ITEM_REQUEST'
export const ACTION_GET_CAMPAIGN_ITEM_SUCCESS = '@actions/CAMPAIGNS/GET_ITEM_SUCCESS'
export const ACTION_GET_CAMPAIGN_ITEM_FAILURE = '@actions/CAMPAIGNS/GET_ITEM_FAILURE'

export const ACTION_GET_CAMPAIGN_SUMMARY_REQUEST = '@actions/CAMPAIGNS/GET_SUMMARY_REQUEST'
export const ACTION_GET_CAMPAIGN_SUMMARY_SUCCESS = '@actions/CAMPAIGNS/GET_SUMMARY_SUCCESS'
export const ACTION_GET_CAMPAIGN_SUMMARY_FAILURE = '@actions/CAMPAIGNS/GET_SUMMARY_FAILURE'

export const ACTION_SAVE_CAMPAIGN_REQUEST = '@actions/CAMPAIGNS/SAVE_ITEM_REQUEST'
export const ACTION_SAVE_CAMPAIGN_SUCCESS = '@actions/CAMPAIGNS/SAVE_ITEM_SUCCESS'
export const ACTION_SAVE_CAMPAIGN_FAILURE = '@actions/CAMPAIGNS/SAVE_ITEM_FAILURE'

export const ACTION_PUBLISH_CAMPAIGN_REQUEST = '@actions/CAMPAIGNS/PUBLISH_ITEM_REQUEST'
export const ACTION_PUBLISH_CAMPAIGN_SUCCESS = '@actions/CAMPAIGNS/PUBLISH_ITEM_SUCCESS'
export const ACTION_PUBLISH_CAMPAIGN_FAILURE = '@actions/CAMPAIGNS/PUBLISH_ITEM_FAILURE'

export const ACTION_OPEN_CAMPAIGN_REQUEST = '@actions/CAMPAIGNS/OPEN_ITEM_REQUEST'
export const ACTION_OPEN_CAMPAIGN_SUCCESS = '@actions/CAMPAIGNS/OPEN_ITEM_SUCCESS'
export const ACTION_OPEN_CAMPAIGN_FAILURE = '@actions/CAMPAIGNS/OPEN_ITEM_FAILURE'

export const ACTION_ABORT_CAMPAIGN_REQUEST = '@actions/CAMPAIGNS/ABORT_ITEM_REQUEST'
export const ACTION_ABORT_CAMPAIGN_SUCCESS = '@actions/CAMPAIGNS/ABORT_ITEM_SUCCESS'
export const ACTION_ABORT_CAMPAIGN_FAILURE = '@actions/CAMPAIGNS/ABORT_ITEM_FAILURE'

export const ACTION_ARCHIVE_CAMPAIGN_REQUEST = '@actions/CAMPAIGNS/ARCHIVE_ITEM_REQUEST'
export const ACTION_ARCHIVE_CAMPAIGN_SUCCESS = '@actions/CAMPAIGNS/ARCHIVE_ITEM_SUCCESS'
export const ACTION_ARCHIVE_CAMPAIGN_FAILURE = '@actions/CAMPAIGNS/ARCHIVE_ITEM_FAILURE'

export const ACTION_UPDATE_CAMPAIGN_ITEM = '@actions/CAMPAIGNS/UPDATE_ITEM'
export const ACTION_CAMPAIGN_ITEM_CONTACTS_SET = '@actions/CAMPAIGNS/ITEM_CONTACTS_SET'

export const ACTION_NAVIGATE_CAMPAIGN_ITEM_CONTACTS = '@actions/CAMPAIGNS/NAVIGATE_CONTACTS'
export const ACTION_SEARCH_CAMPAIGN_ITEM_CONTACTS = '@actions/CAMPAIGNS/SEARCH_CONTACTS'
export const ACTION_SELECT_CAMPAIGN_ITEM_CONTACTS = '@actions/CAMPAIGNS/SELECT_CONTACTS'

export const ACTION_SUBSCRIBE_CONTACT_TO_CAMPAIGN_REQUEST = '@actions/CAMPAIGNS/SUBSCRIBE_CONTACT_REQUEST'
export const ACTION_SUBSCRIBE_CONTACT_TO_CAMPAIGN_SUCCESS = '@actions/CAMPAIGNS/SUBSCRIBE_CONTACT_SUCCESS'
export const ACTION_SUBSCRIBE_CONTACT_TO_CAMPAIGN_FAILURE = '@actions/CAMPAIGNS/SUBSCRIBE_CONTACT_FAILURE'

export const ACTION_SUBSCRIBE_GROUP_TO_CAMPAIGN_REQUEST = '@actions/CAMPAIGNS/SUBSCRIBE_GROUP_REQUEST'
export const ACTION_SUBSCRIBE_GROUP_TO_CAMPAIGN_SUCCESS = '@actions/CAMPAIGNS/SUBSCRIBE_GROUP_SUCCESS'
export const ACTION_SUBSCRIBE_GROUP_TO_CAMPAIGN_FAILURE = '@actions/CAMPAIGNS/SUBSCRIBE_GROUP_FAILURE'

export const ACTION_SUBSCRIBE_SEGMENT_TO_CAMPAIGN_REQUEST = '@actions/CAMPAIGNS/SUBSCRIBE_SEGMENT_REQUEST'
export const ACTION_SUBSCRIBE_SEGMENT_TO_CAMPAIGN_SUCCESS = '@actions/CAMPAIGNS/SUBSCRIBE_SEGMENT_SUCCESS'
export const ACTION_SUBSCRIBE_SEGMENT_TO_CAMPAIGN_FAILURE = '@actions/CAMPAIGNS/SUBSCRIBE_SEGMENT_FAILURE'

export const ACTION_UNSUBSCRIBE_FROM_CAMPAIGN_REQUEST = '@actions/CAMPAIGNS/UNSUBSCRIBE_FROM_CAMPAIGN_REQUEST'
export const ACTION_UNSUBSCRIBE_FROM_CAMPAIGN_SUCCESS = '@actions/CAMPAIGNS/UNSUBSCRIBE_FROM_CAMPAIGN_SUCCESS'
export const ACTION_UNSUBSCRIBE_FROM_CAMPAIGN_FAILURE = '@actions/CAMPAIGNS/UNSUBSCRIBE_FROM_CAMPAIGN_FAILURE'

export const ACTION_GET_CAMPAIGN_ITEM_RUNS_REQUEST = '@actions/CAMPAIGNS/GET_ITEM_RUNS_REQUEST'
export const ACTION_GET_CAMPAIGN_ITEM_RUNS_SUCCESS = '@actions/CAMPAIGNS/GET_ITEM_RUNS_SUCCESS'
export const ACTION_GET_CAMPAIGN_ITEM_RUNS_FAILURE = '@actions/CAMPAIGNS/GET_ITEM_RUNS_FAILURE'

export const ACTION_COPY_CAMPAIGN_REQUEST = '@actions/CAMPAIGNS/COPY_REQUEST'
export const ACTION_COPY_CAMPAIGN_SUCCESS = '@actions/CAMPAIGNS/COPY_SUCCESS'
export const ACTION_COPY_CAMPAIGN_FAILURE = '@actions/CAMPAIGNS/COPY_FAILURE'

export const ACTION_GET_CAMPAIGN_FILE_REQUEST = '@actions/CAMPAIGNS/GET_FILE_REQUEST'
export const ACTION_GET_CAMPAIGN_FILE_SUCCESS = '@actions/CAMPAIGNS/GET_FILE_SUCCESS'
export const ACTION_GET_CAMPAIGN_FILE_FAILURE = '@actions/CAMPAIGNS/GET_FILE_FAILURE'

export const ACTION_SAVE_CAMPAIGN_FILE_REQUEST = '@actions/CAMPAIGNS/SAVE_FILE_REQUEST'
export const ACTION_SAVE_CAMPAIGN_FILE_SUCCESS = '@actions/CAMPAIGNS/SAVE_FILE_SUCCESS'
export const ACTION_SAVE_CAMPAIGN_FILE_FAILURE = '@actions/CAMPAIGNS/SAVE_FILE_FAILURE'

export const ACTION_CAMPAIGN_FILE_RESET = '@actions/CAMPAIGNS/RESET_FILE'

export const ACTION_CAMPAIGNS_NAVIGATE = '@actions/CAMPAIGNS/NAVIGATE'
export const ACTION_CAMPAIGNS_PAGE_SIZE = '@actions/CAMPAIGNS/SET_PAGE_SIZE'

export const ACTION_CAMPAIGNS_SET_CHANGED = '@actions/CAMPAIGNS/SET_CHANGED'

export const ACTION_EXPORT_CAMPAIGN_REPORT = '@actions/CAMPAIGNS/EXPORT_REPORT'

// ERRORS
export const ERROR_GET_CAMPAIGNS_SERVER = 'Failed to load campaigns. Please try again later.'
export const ERROR_GET_CAMPAIGN_SUMMARY_SERVER = 'Failed to load campaign summary. Please try again later.'
export const ERROR_GET_CAMPAIGN_ITEM_SERVER = 'Failed to load campaign item. Please try again later.'
export const ERROR_GET_CAMPAIGN_ITEM_NOT_FOUND = 'Campaign not found.'
export const ERROR_SAVE_CAMPAIGN_SERVER = 'Failed to save campaign. Please try again later.'
export const ERROR_PUBLISH_CAMPAIGN_SERVER =
  'Failed to publish campaign. Please check the campaign for any missing or incorrect content.'
export const ERROR_OPEN_CAMPAIGN_SERVER = 'Failed to open campaign. Please try again later.'
export const ERROR_ABORT_CAMPAIGN_SERVER = 'Failed to abort campaign. Please try again later.'
export const ERROR_ARCHIVE_CAMPAIGN_SERVER = 'Could not change archived status of campaign. Please try again later.'
export const ERROR_SUBSCRIBE_TO_CAMPAIGN_SERVER = 'Failed to subscribe contact to campaign. Please try again later.'
export const ERROR_SUBSCRIBE_GROUP_TO_CAMPAIGN_SERVER = 'Failed to subscribe group to campaign. Please try again later'
export const ERROR_SUBSCRIBE_SEGMENT_TO_CAMPAIGN_SERVER =
  'Failed to subscribe segment to campaign. Please try again later'
export const ERROR_COPY_CAMPAIGN_SERVER = 'Failed to copy campaign. Please try again later.'
export const ERROR_ABORT_CAMPAIGN_RUN_FINISHED = 'The subscription is already completed.'
export const ERROR_EXPORT_CAMPAIGN_REPORT_SERVER = 'Failed to export report. Please try again later.'
export const ERROR_EXPORT_DOWNLOAD_SERVER = 'Failed to download report. Please try again later.'
export const ERROR_WHATSAPP_LIST_BUTTON_LENGTH =
  'The button text cannot contain more than 20 characters (including spaces)'
export const ERROR_MISSING_T0 = 'You have to set an event time.'
export const ERROR_RESUME_SUBSCRIPTION = 'Failed to resume subscription. Please try again later.'

// INFO
export const DEFAULT_SENDER_ID_MESSAGE = 'Please contact Support to register a branded Sender ID for your organization.'
export const SENDER_ID_PLACEHOLDER = 'Please choose a sender ID'
export const RUNNING_CAMPAIGNS_DISABLED_TOOLTIP = 'Currently not available when editing running campaigns.'
export const EXPORT_CAMPAIGN_REPORT_PENDING =
  'Your campaign report is being generated. You will receive a notification when done. Or you can find the completed report under Exports.'
export const OTHER_UPDATED_CAMPAIGN =
  'This campaign has been updated by another user. Please refresh the page to see the latest changes.'
export const NO_LIBRARY_MESSAGES_TOOLTIP =
  'No messages available in the library. Please save messages to your library use this feature.'

export const WA_LIST_BUTTON_LENGTH = 20
export const WA_LIST_TITLE_LENGTH = 24
export const WA_LIST_DESCRIPTION_LENGTH = 72

export const WA_BUTTON_TITLE_LENGTH = 20

export const CAMPAIGN_CHANGE_DELAY = 500

export const CAMPAIGN_SIDEBAR_WIDTH = 240

export const LIBRARY_MESSAGE_TYPES = [PartFamily.SMS, PartFamily.CATI, PartFamily.API]
