import { equals } from 'ramda'
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { noop } from './definitions'

type TimeoutID = ReturnType<typeof setTimeout>

type ToBeDebounced<T extends any[]> = (...args: T) => void
export const useDebouncedFn = <T extends any[]>(fn: ToBeDebounced<T>, delay = 500) => {
  const timer = useRef<TimeoutID>()

  const debouncedFn = useCallback(
    (...args: T) => {
      if (timer.current) {
        clearTimeout(timer.current)
      }

      timer.current = setTimeout(() => {
        fn(...args)
      }, delay)
    },
    [delay, fn],
  )

  useEffect(
    () => () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    },
    [],
  )

  return debouncedFn
}

export const useDebouncedChange = <T extends any>(value: T, onChange: (value: T) => void, delay = 500) => {
  const [state, setState] = useState(value)
  const debouncedOnChange = useDebouncedFn(onChange, delay)

  const handleOnChange = useCallback(
    (newValue: T) => {
      setState(newValue)
      debouncedOnChange(newValue)
    },
    [debouncedOnChange],
  )

  return [state, setState, handleOnChange] as const
}

export const useDebouncedState = <T extends any>(
  value: T,
  onChange: (value: T) => void,
  delay = 500,
  changeOnUnmount = false,
) => {
  const onChangeRef = useRef(onChange)
  const nextValue = useRef<T | null>(null) // to store the value that will be passed to onChange soon

  /**
   * This is a trick to keep the ref function up-to-date
   * so it won't trigger the effect below when the onChange function changes
   */
  useEffect(() => {
    onChangeRef.current = onChange
  }, [onChange])

  useEffect(() => {
    nextValue.current = value
    const timer = setTimeout(() => {
      onChangeRef.current(value)
      nextValue.current = null
    }, delay)

    return () => {
      clearTimeout(timer)
    }
  }, [delay, value])

  useEffect(() => {
    if (!changeOnUnmount) {
      return noop
    }

    return () => {
      if (nextValue.current) {
        onChangeRef.current(nextValue.current)
      }
    }
  }, [changeOnUnmount])
}

// only update state if the originalState is different (ramda's equals is deep comparison) with the current state
export const useDeepState = <T>(originalState: T): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(originalState)

  useEffect(() => {
    setState((s) => {
      if (!equals(s, originalState)) {
        return originalState
      }
      return s
    })
  }, [originalState])

  return [state, setState]
}

export const usePrevious = (value: any) => {
  const ref = useRef<any>()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export const cannot = (part: never): never => {
  throw new Error(`cannot happen: ${JSON.stringify(part)}`)
}
