import { pathOr } from 'ramda'
import { getPageSize } from '@/app/service/storage'
import config from '@/config'

const pageSize = getPageSize({
  namespace: 'campaigns',
  defaultValue: pathOr(25, ['modules', 'campaigns', 'pageSize', 'default'], config),
})

export default {
  list: {
    loading: false,
    loadingItems: [],
    initialized: false,
    error: {},
    archived: 'false',
    data: {
      values: {},
      pages: {},
      page: 1,
      size: pageSize,
      total: 0,
    },
  },
  item: {
    loading: false,
    error: {},
    data: {},
    changed: false,
  },
  itemSummary: {
    loading: false,
    error: {},
    data: {},
  },
  contacts: {
    loadingItems: [],
  },
  groups: {
    loadingItems: [],
  },
  segments: {
    loadingItems: [],
  },
  files: {
    loading: false,
    loadingItems: [],
    error: {},
    data: {},
  },
}
