import { useUpdateFileMutation } from '@/__generated/es-api'
import { createNotification } from '@/app/module/notifications/store/actions'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { useAppDispatch } from '@/app/store/redux-hooks'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { getFileSuccess } from '@/app/module/campaigns/store/actions'

export const useUpdateFile = (messageId: string) => {
  const dispatch = useAppDispatch()
  const orgId = useSelector(selectOrgId)
  const [update, response] = useUpdateFileMutation()

  const updateFile = useCallback(
    async (filename: string, transcript: string) => {
      try {
        await update({
          filename,
          orgId: orgId.toString(),
          body: {
            transcript,
          },
        }).unwrap()
        dispatch(
          getFileSuccess({
            id: messageId,
            filename,
            transcription: transcript,
          }),
        )
        return true
      } catch (error) {
        dispatch(
          createNotification({
            'update-file': {
              type: 'error',
              disableAutoHide: true,
              message: 'Failed to update file transcript, please try again later.',
            },
          }),
        )
        return false
      }
    },
    [messageId, orgId, dispatch, update],
  )

  return [updateFile, response] as const
}
