import { useTranscribeFileMutation } from '@/__generated/es-api'
import { createNotification } from '@/app/module/notifications/store/actions'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { captureError } from '@/app/service/util/error'
import { useAppDispatch } from '@/app/store/redux-hooks'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

export const useTranscribeAudio = () => {
  const dispatch = useAppDispatch()
  const orgId = useSelector(selectOrgId)
  const [transcribe, response] = useTranscribeFileMutation()

  const transcribeAudio = useCallback(
    async (filename: string, language: string, store?: boolean) => {
      try {
        await transcribe({
          orgId: orgId.toString(),
          body: {
            filename,
            language,
            store,
          },
        }).unwrap()
      } catch (error) {
        dispatch(
          createNotification({
            'transcribe-audio': {
              type: 'error',
              disableAutoHide: true,
              message: 'Failed to transcribe audio, please try again later.',
            },
          }),
        )
        captureError(error)
      }
    },
    [orgId, dispatch, transcribe],
  )

  return [transcribeAudio, response] as const
}
