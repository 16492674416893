import { Typography } from '@mui/material'
import React from 'react'
import QuestionNumberInput from './question/number'

type Props = {
  retries: number
  retriesEnabled: boolean
  onChange: (payload: { value: string }) => void
}

const ResponseVoiceRetries: React.FC<Props> = ({ retries, retriesEnabled, onChange }) => (
  <div>
    <Typography color="textSecondary" component="div" gutterBottom variant="caption">
      <QuestionNumberInput
        afterText="additional chances."
        beforeText="Specify the number of times that we should allow the Contact to reply invalidly or time out before we take the Failure actions you specify below:"
        data-testid="retries-input"
        editable={retriesEnabled}
        min={0}
        value={retries.toString()}
        onChange={onChange}
      />
    </Typography>
    {!retriesEnabled && (
      <Typography color="textSecondary" component="div" variant="caption" gutterBottom={true}>
        Note: To set the number of chances, please first select and specify Actions for Invalid Keypress or Timeout.
      </Typography>
    )}
    {retriesEnabled && (
      <Typography color="textSecondary" component="div" variant="caption" gutterBottom={true}>
        Note: after we take the Failure actions you specify, the survey will continue, unless you add a "Hangup" action
        in the Failure section.
      </Typography>
    )}
  </div>
)

export default ResponseVoiceRetries
