import { reduce } from 'ramda'
import { cannot } from '@/app/helpers'
import { iconMap, MESSAGE_TYPES } from '@/app/module/campaigns/definitions'
import {
  BaseMessage,
  BasePart,
  ContentAudio,
  ContentWhatsApp,
  Message,
  Part,
  PartFamily,
  PartTypes,
  ReminderMessage,
  VoiceMessagePart,
  VoiceQuestionPart,
} from '@/app/module/campaigns/types'

export const getAudioContent = (audio: ContentAudio) => {
  return audio.playfile || audio.say || ''
}

export const getWhatsAppContent = (whatsapp: ContentWhatsApp) => {
  return whatsapp.text || whatsapp.image || whatsapp.audio || whatsapp.video || whatsapp.document || ''
}

/**
 * @param part Part - campaign part
 * @returns a string representation of the content of the part to be displayed in the campaign part list of jump action
 */
export const getMessageContentPreview = (message: Message | ReminderMessage): string => {
  switch (message.type) {
    case PartFamily.Action:
    case PartFamily.Topup: {
      return ''
    }
    case PartFamily.API: {
      const contentArr = [message.apiCall.method, message.apiCall.url]
      return contentArr.filter(Boolean).join(' ')
    }
    case PartFamily.SMS:
    case PartFamily.CATI: {
      return message.message.text || ''
    }
    case PartFamily.WhatsApp: {
      return getWhatsAppContent(message.message)
    }
    case PartFamily.Voice: {
      return getAudioContent(message.audio)
    }
    default: {
      return cannot(message)
    }
  }
}

/**
 * @param part Part - campaign part
 * @returns a string representation of the content of the part to be displayed in the campaign part list of jump action
 */
export const getPartContentPreview = (part: Part): string => {
  const message = toMessage(part)
  return getMessageContentPreview(message)
}

export const selectContent = (message: Part, index: number) =>
  reduce(
    (accum, type) => {
      if (type.key in message) {
        return {
          message: getPartContentPreview(message),
          icon: iconMap[type.key],
          id: message.id,
          name: `${index + 1}. ${message.label || type.label}`,
        }
      }
      return accum
    },
    {},
    MESSAGE_TYPES,
  )

/**
 * Converts a Part (Data Model) to a Message (View Model)
 */
export const toMessage = (part: Part): Message => {
  const baseMessage: Omit<BaseMessage, 'hasQuestion'> = {
    id: part.id,
    changed: false,
    label: part.label,
    when: part.when,
  }

  if ('delta' in part) {
    baseMessage.delta = part.delta
  }

  if (PartTypes.ActionsOnly in part) {
    return {
      ...baseMessage,
      type: PartFamily.Action,
      hasQuestion: true,
      actionsOnly: {
        actions: part.actionsOnly.actions,
      },
    }
  }

  if (PartTypes.ApiCall in part) {
    return {
      ...baseMessage,
      type: PartFamily.API,
      hasQuestion: true,
      apiCall: part.apiCall,
    }
  }

  if (PartTypes.CATIMessage in part) {
    return {
      ...baseMessage,
      type: PartFamily.CATI,
      hasQuestion: false,
      message: part.catiMessage.message,
    }
  }

  if (PartTypes.CATIQuestion in part) {
    return {
      ...baseMessage,
      type: PartFamily.CATI,
      hasQuestion: true,
      message: part.catiQuestion.message,
      onAnswer: part.catiQuestion.onAnswer,
      openEnded: part.catiQuestion.openEnded,
    }
  }

  if (PartTypes.SMSMessage in part) {
    return {
      ...baseMessage,
      type: PartFamily.SMS,
      hasQuestion: false,
      message: part.smsMessage.message,
    }
  }

  if (PartTypes.SMSQuestion in part) {
    return {
      ...baseMessage,
      type: PartFamily.SMS,
      hasQuestion: true,
      message: part.smsQuestion.message,
      onAnswer: part.smsQuestion.onAnswer || [],
      onInvalidReply: part.smsQuestion.onInvalidReply,
      onRetriesExhausted: part.smsQuestion.onRetriesExhausted,
      onTimeout: part.smsQuestion.onTimeout,
      openEnded: part.smsQuestion.openEnded || undefined,
      retries: part.smsQuestion.retries,
      useAi: part.smsQuestion.useAi,
    }
  }

  if (PartTypes.Topup in part) {
    return {
      ...baseMessage,
      type: PartFamily.Topup,
      hasQuestion: true,
      topup: part.topup,
    }
  }

  if (PartTypes.VoiceMessage in part) {
    return {
      ...baseMessage,
      type: PartFamily.Voice,
      hasQuestion: false,
      audio: part.voiceMessage.audio,
      callResultActions: part.callResultActions,
    }
  }

  if (PartTypes.VoiceQuestion in part) {
    return {
      ...baseMessage,
      type: PartFamily.Voice,
      hasQuestion: true,
      audio: part.voiceQuestion.audio,
      callResultActions: part.callResultActions,
      keypressAfterAudio: part.voiceQuestion.keypressAfterAudio,
      onAnswer: part.voiceQuestion.onAnswer,
      onInvalidReply: part.voiceQuestion.onInvalidReply,
      onRetriesExhausted: part.voiceQuestion.onRetriesExhausted,
      onTimeout: part.voiceQuestion.onTimeout,
      processingReply: part.voiceQuestion.processingReply,
      repeatQuestion: part.voiceQuestion.repeatQuestion,
      retries: part.voiceQuestion.retries,
      speechSettings: part.voiceQuestion.speechSettings,
      spoken: part.voiceQuestion.spoken,
      useAi: part.voiceQuestion.useAi,
    }
  }

  if (PartTypes.WhatsAppMessage in part) {
    return {
      ...baseMessage,
      type: PartFamily.WhatsApp,
      hasQuestion: false,
      message: part.whatsappMessage.message,
    }
  }

  if (PartTypes.WhatsAppQuestion in part) {
    return {
      ...baseMessage,
      type: PartFamily.WhatsApp,
      hasQuestion: true,
      message: part.whatsappQuestion.message,
      onAnswer: part.whatsappQuestion.onAnswer,
      onButtons: part.whatsappQuestion.onButtons,
      onInvalidReply: part.whatsappQuestion.onInvalidReply,
      onList: part.whatsappQuestion.onList,
      onRetriesExhausted: part.whatsappQuestion.onRetriesExhausted,
      onTimeout: part.whatsappQuestion.onTimeout,
      openEnded: part.whatsappQuestion.openEnded,
      retries: part.whatsappQuestion.retries,
      useAi: part.whatsappQuestion.useAi,
      transcribe: part.whatsappQuestion.transcribe,
    }
  }

  return cannot(part)
}

/**
 * Converts a Message (View Model) to a Part (Data Model)
 */
export const fromMessage = (message: Message): Part => {
  const basePart: BasePart = {
    id: message.id,
    delta: message.delta,
    label: message.label,
    when: message.when,
  }
  switch (message.type) {
    case PartFamily.Action:
      return {
        ...basePart,
        id: message.id,
        actionsOnly: {
          actions: message.actionsOnly.actions,
        },
      }
    case PartFamily.API:
      return {
        ...basePart,
        id: message.id,
        apiCall: message.apiCall,
      }
    case PartFamily.CATI: {
      if (!message.hasQuestion) {
        return {
          ...basePart,
          id: message.id,
          catiMessage: {
            message: message.message,
          },
        }
      }
      return {
        ...basePart,
        id: message.id,
        catiQuestion: {
          message: message.message,
          onAnswer: message.onAnswer,
          openEnded: message.openEnded,
        },
      }
    }
    case PartFamily.SMS: {
      if (!message.hasQuestion) {
        return {
          ...basePart,
          id: message.id,
          smsMessage: {
            message: message.message,
          },
        }
      }
      return {
        ...basePart,
        id: message.id,
        smsQuestion: {
          message: message.message,
          onAnswer: message.onAnswer,
          onInvalidReply: message.onInvalidReply,
          onRetriesExhausted: message.onRetriesExhausted,
          onTimeout: message.onTimeout,
          openEnded: message.openEnded,
          retries: message.retries,
          useAi: message.useAi,
        },
      }
    }
    case PartFamily.Topup: {
      return {
        ...basePart,
        id: message.id,
        topup: message.topup,
      }
    }
    case PartFamily.Voice: {
      if (!message.hasQuestion) {
        return {
          ...basePart,
          id: message.id,
          voiceMessage: {
            audio: message.audio,
          },
          callResultActions: message.callResultActions,
        }
      }
      return {
        ...basePart,
        id: message.id,
        voiceQuestion: {
          audio: message.audio,
          keypressAfterAudio: message.keypressAfterAudio,
          onAnswer: message.onAnswer,
          onInvalidReply: message.onInvalidReply,
          onRetriesExhausted: message.onRetriesExhausted,
          onTimeout: message.onTimeout,
          processingReply: message.processingReply,
          repeatQuestion: message.repeatQuestion,
          retries: message.retries,
          speechSettings: message.speechSettings,
          spoken: message.spoken,
          useAi: message.useAi,
        },
        callResultActions: message.callResultActions,
      }
    }
    case PartFamily.WhatsApp: {
      if (!message.hasQuestion) {
        return {
          ...basePart,
          id: message.id,
          whatsappMessage: {
            message: message.message,
          },
        }
      }
      return {
        ...basePart,
        id: message.id,
        whatsappQuestion: {
          message: message.message,
          onAnswer: message.onAnswer,
          onButtons: message.onButtons,
          onInvalidReply: message.onInvalidReply,
          onList: message.onList,
          onRetriesExhausted: message.onRetriesExhausted,
          onTimeout: message.onTimeout,
          openEnded: message.openEnded,
          retries: message.retries,
          useAi: message.useAi,
          transcribe: message.transcribe,
        },
      }
    }
    default:
      return cannot(message)
  }
}

export const getPartFamily = (part: Part): PartFamily => {
  if (PartTypes.ActionsOnly in part) {
    return PartFamily.Action
  }
  if (PartTypes.ApiCall in part) {
    return PartFamily.API
  }
  if (PartTypes.CATIMessage in part) {
    return PartFamily.CATI
  }
  if (PartTypes.CATIQuestion in part) {
    return PartFamily.CATI
  }
  if (PartTypes.SMSMessage in part) {
    return PartFamily.SMS
  }
  if (PartTypes.SMSQuestion in part) {
    return PartFamily.SMS
  }
  if (PartTypes.Topup in part) {
    return PartFamily.Topup
  }
  if (PartTypes.VoiceMessage in part) {
    return PartFamily.Voice
  }
  if (PartTypes.VoiceQuestion in part) {
    return PartFamily.Voice
  }
  if (PartTypes.WhatsAppMessage in part) {
    return PartFamily.WhatsApp
  }
  if (PartTypes.WhatsAppQuestion in part) {
    return PartFamily.WhatsApp
  }
  return cannot(part)
}

export const isPartQuestion = (part: Part): boolean => {
  return (
    PartTypes.ActionsOnly in part ||
    PartTypes.ApiCall in part ||
    PartTypes.CATIQuestion in part ||
    PartTypes.SMSQuestion in part ||
    PartTypes.Topup in part ||
    PartTypes.VoiceQuestion in part ||
    PartTypes.WhatsAppQuestion in part
  )
}

export const isVoicePart = (part: Part): part is VoiceMessagePart | VoiceQuestionPart =>
  PartTypes.VoiceMessage in part || PartTypes.VoiceQuestion in part
