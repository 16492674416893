import {
  LocalOnInvalidReply,
  LocalOnRetriesExhausted,
  LocalOnTimeout,
  OnInvalidReply,
  OnRetriesExhausted,
  OnTimeout,
  RepeatQuestion,
} from '@/app/module/campaigns/types'
import {
  fromFailure,
  fromInvalid,
  fromRepeatQuestion,
  fromTimeout,
  toFailure,
  toInvalid,
  toRepeatQuestion,
  toTimeout,
} from './response-configs-utils'

export type SpecificConfigs = {
  keypressAfterAudio: boolean
  repeatQuestion: RepeatQuestion
  onInvalidReply: OnInvalidReply
  onTimeout: OnTimeout
  retries: number
  onRetriesExhausted: OnRetriesExhausted
}

export type SpecificConfigsState = {
  keypressAfterAudio: boolean
  repeatQuestion: RepeatQuestion
  onInvalidReply: LocalOnInvalidReply
  onTimeout: LocalOnTimeout
  retries: number
  onRetriesExhausted: LocalOnRetriesExhausted
}

export const fromConfigs = (configs: SpecificConfigs): SpecificConfigsState => ({
  keypressAfterAudio: configs.keypressAfterAudio,
  repeatQuestion: fromRepeatQuestion(configs.repeatQuestion),
  onInvalidReply: fromInvalid(configs.onInvalidReply),
  onTimeout: fromTimeout(configs.onTimeout),
  retries: configs.retries,
  onRetriesExhausted: fromFailure(configs.onRetriesExhausted),
})

export const toConfigs = (state: SpecificConfigsState): SpecificConfigs => ({
  keypressAfterAudio: state.keypressAfterAudio,
  repeatQuestion: toRepeatQuestion(state.repeatQuestion),
  onInvalidReply: toInvalid(state.onInvalidReply),
  onTimeout: toTimeout(state.onTimeout),
  retries: state.retries,
  onRetriesExhausted: toFailure(state.onRetriesExhausted),
})
