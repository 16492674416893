import { applySpec, compose, concat, indexBy, merge, omit, pathOr, prop, uniq, without } from 'ramda'
import { createReducer } from '@/app/service/util'
import {
  ACTION_ABORT_CAMPAIGN_FAILURE,
  ACTION_ABORT_CAMPAIGN_REQUEST,
  ACTION_ABORT_CAMPAIGN_SUCCESS,
  ACTION_ARCHIVE_CAMPAIGN_FAILURE,
  ACTION_ARCHIVE_CAMPAIGN_REQUEST,
  ACTION_ARCHIVE_CAMPAIGN_SUCCESS,
  ACTION_CAMPAIGNS_NAVIGATE,
  ACTION_CAMPAIGNS_PAGE_SIZE,
  ACTION_CAMPAIGNS_SET_CHANGED,
  ACTION_CAMPAIGN_FILE_RESET,
  ACTION_CAMPAIGN_ITEM_CONTACTS_SET,
  ACTION_COPY_CAMPAIGN_FAILURE,
  ACTION_COPY_CAMPAIGN_REQUEST,
  ACTION_COPY_CAMPAIGN_SUCCESS,
  ACTION_GET_CAMPAIGNS_FAILURE,
  ACTION_GET_CAMPAIGNS_REQUEST,
  ACTION_GET_CAMPAIGNS_SUCCESS,
  ACTION_GET_CAMPAIGN_FILE_FAILURE,
  ACTION_GET_CAMPAIGN_FILE_REQUEST,
  ACTION_GET_CAMPAIGN_FILE_SUCCESS,
  ACTION_GET_CAMPAIGN_ITEM_FAILURE,
  ACTION_GET_CAMPAIGN_ITEM_REQUEST,
  ACTION_GET_CAMPAIGN_ITEM_SUCCESS,
  ACTION_GET_CAMPAIGN_SUMMARY_FAILURE,
  ACTION_GET_CAMPAIGN_SUMMARY_REQUEST,
  ACTION_GET_CAMPAIGN_SUMMARY_SUCCESS,
  ACTION_OPEN_CAMPAIGN_FAILURE,
  ACTION_OPEN_CAMPAIGN_REQUEST,
  ACTION_OPEN_CAMPAIGN_SUCCESS,
  ACTION_PUBLISH_CAMPAIGN_FAILURE,
  ACTION_PUBLISH_CAMPAIGN_REQUEST,
  ACTION_PUBLISH_CAMPAIGN_SUCCESS,
  ACTION_SAVE_CAMPAIGN_FAILURE,
  ACTION_SAVE_CAMPAIGN_FILE_FAILURE,
  ACTION_SAVE_CAMPAIGN_FILE_REQUEST,
  ACTION_SAVE_CAMPAIGN_FILE_SUCCESS,
  ACTION_SAVE_CAMPAIGN_REQUEST,
  ACTION_SAVE_CAMPAIGN_SUCCESS,
  ACTION_SUBSCRIBE_CONTACT_TO_CAMPAIGN_FAILURE,
  ACTION_SUBSCRIBE_CONTACT_TO_CAMPAIGN_REQUEST,
  ACTION_SUBSCRIBE_CONTACT_TO_CAMPAIGN_SUCCESS,
  ACTION_SUBSCRIBE_GROUP_TO_CAMPAIGN_FAILURE,
  ACTION_SUBSCRIBE_GROUP_TO_CAMPAIGN_REQUEST,
  ACTION_SUBSCRIBE_GROUP_TO_CAMPAIGN_SUCCESS,
  ACTION_UNSUBSCRIBE_FROM_CAMPAIGN_FAILURE,
  ACTION_UNSUBSCRIBE_FROM_CAMPAIGN_REQUEST,
  ACTION_UNSUBSCRIBE_FROM_CAMPAIGN_SUCCESS,
  ACTION_UPDATE_CAMPAIGN_ITEM,
} from '@/app/module/campaigns/definitions'
import defaultState from './state'

const transformCampaigns = (state, value = { values: [] }) => {
  const indexedPage = indexBy(prop('id'), value.values || [])

  return compose(
    merge(state),
    applySpec({
      page: ({ page }) => parseInt(page, 10),
      size: ({ size }) => parseInt(size, 10),
      total: ({ total }) => parseInt(total, 10),
      values: () => ({
        ...state.values,
        ...indexedPage,
      }),
      pages: ({ page }) => ({
        ...state.pages,
        [page]: value.values.map(({ id }) => id),
      }),
    }),
  )(value)
}

export default createReducer(defaultState, {
  [ACTION_GET_CAMPAIGNS_REQUEST]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loading: true,
      ...value.filters,
    },
  }),

  [ACTION_GET_CAMPAIGNS_SUCCESS]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      error: {},
      loading: false,
      initialized: true,
      data: transformCampaigns(state.list.data, value),
    },
  }),

  [ACTION_GET_CAMPAIGNS_FAILURE]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loading: false,
      error: value,
    },
  }),

  // GET ITEM
  [ACTION_GET_CAMPAIGN_ITEM_REQUEST]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
      error: {},
    },
  }),

  [ACTION_GET_CAMPAIGN_ITEM_SUCCESS]: (state, { value }) => ({
    ...state,
    item: {
      loading: false,
      error: {},
      data: value,
    },
    files: {
      ...state.files,
      data: {
        ...state.files.data,
        ...value.files,
      },
    },
  }),

  [ACTION_GET_CAMPAIGN_ITEM_FAILURE]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),

  [ACTION_GET_CAMPAIGN_SUMMARY_REQUEST]: (state) => ({
    ...state,
    itemSummary: {
      ...state.itemSummary,
      loading: true,
      error: {},
    },
  }),

  [ACTION_GET_CAMPAIGN_SUMMARY_SUCCESS]: (state, { value }) => ({
    ...state,
    itemSummary: {
      loading: false,
      error: {},
      data: value,
    },
  }),

  [ACTION_GET_CAMPAIGN_SUMMARY_FAILURE]: (state, { value }) => ({
    ...state,
    itemSummary: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),

  // UPDATE
  [ACTION_UPDATE_CAMPAIGN_ITEM]: (state, { value }) => ({
    ...state,
    item: {
      loading: false,
      error: {},
      data: value || defaultState.item.data,
    },
  }),

  // SET TYPE
  [ACTION_CAMPAIGN_ITEM_CONTACTS_SET]: (state, { value = {} }) => ({
    ...state,
    item: {
      ...state.item,
      data: {
        ...state.item.data,
        contacts: {
          ...(state.item.data.contacts || {}),
          allContactsAreSelected: value.isAll,
          excludedContactIds: value.isAll ? value.list : [],
          includedContactIds: value.isAll ? [] : value.list,
        },
      },
    },
  }),

  // PUBLISH
  [ACTION_PUBLISH_CAMPAIGN_REQUEST]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
    },
    list: {
      ...state.list,
      loadingItems: uniq(concat([value.id], state.list.loadingItems)),
    },
  }),

  [ACTION_PUBLISH_CAMPAIGN_SUCCESS]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
    },
    list: {
      ...state.list,
      loadingItems: without([value.id], state.list.loadingItems),
      data: {
        ...state.list.data,
        values: {
          ...state.list.data.values,
          [value.id]: {
            ...state.list.data.values[value.id],
            published: value.published,
          },
        },
      },
    },
  }),

  [ACTION_PUBLISH_CAMPAIGN_FAILURE]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
    },
    list: {
      ...state.list,
      loadingItems: without([value.id], state.list.loadingItems),
    },
  }),

  // OPEN
  [ACTION_OPEN_CAMPAIGN_REQUEST]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: uniq(concat([value], state.list.loadingItems)),
    },
  }),

  [ACTION_OPEN_CAMPAIGN_SUCCESS]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: without([value.id], state.list.loadingItems),
      data: {
        ...state.list.data,
        values: {
          ...state.list.data.values,
          [value.id]: {
            ...state.list.data.values[value.id],
            open: value.open,
          },
        },
      },
    },
  }),

  [ACTION_OPEN_CAMPAIGN_FAILURE]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: without([value.id], state.list.loadingItems),
    },
  }),

  // ABORT
  [ACTION_ABORT_CAMPAIGN_REQUEST]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: uniq(concat([value], state.list.loadingItems)),
    },
  }),

  [ACTION_ABORT_CAMPAIGN_SUCCESS]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: without([value.id], state.list.loadingItems),
      data: {
        ...state.list.data,
        values: {
          ...state.list.data.values,
          [value.id]: {
            ...state.list.data.values[value.id],
            aborted: value.aborted,
          },
        },
      },
    },
  }),

  [ACTION_ABORT_CAMPAIGN_FAILURE]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: without([value.id], state.list.loadingItems),
    },
  }),

  // ARCHIVE
  [ACTION_ARCHIVE_CAMPAIGN_REQUEST]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: uniq(concat([value], state.list.loadingItems)),
    },
  }),

  [ACTION_ARCHIVE_CAMPAIGN_SUCCESS]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: without([value.id], state.list.loadingItems),
      data: {
        ...state.list.data,
        values: {
          ...state.list.data.values,
          [value.id]: {
            ...state.list.data.values[value.id],
            archived: value.archived,
          },
        },
      },
    },
  }),
  [ACTION_ARCHIVE_CAMPAIGN_FAILURE]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: without([value.id], state.list.loadingItems),
    },
  }),

  // COPY
  [ACTION_COPY_CAMPAIGN_REQUEST]: (state) => ({
    ...state,
    list: {
      ...state.list,
      loading: true,
    },
  }),

  [ACTION_COPY_CAMPAIGN_SUCCESS]: (state) => ({
    ...state,
    list: {
      ...state.list,
      loading: false,
    },
  }),

  [ACTION_COPY_CAMPAIGN_FAILURE]: (state) => ({
    ...state,
    list: {
      ...state.list,
      loading: false,
    },
  }),

  // SAVE
  [ACTION_SAVE_CAMPAIGN_REQUEST]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
    },
  }),

  [ACTION_SAVE_CAMPAIGN_SUCCESS]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      data: value,
    },
  }),

  [ACTION_SAVE_CAMPAIGN_FAILURE]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
    },
  }),

  // SUBSCRIBE CONTACT
  [ACTION_SUBSCRIBE_CONTACT_TO_CAMPAIGN_REQUEST]: (state, { value }) => ({
    ...state,
    contacts: {
      ...state.contacts,
      loadingItems: uniq(concat([value.contactId], state.contacts.loadingItems)),
    },
  }),
  [ACTION_SUBSCRIBE_CONTACT_TO_CAMPAIGN_SUCCESS]: (state, { value }) => ({
    ...state,
    contacts: {
      ...state.contacts,
      loadingItems: without([value.contactId], state.contacts.loadingItems),
    },
  }),
  [ACTION_SUBSCRIBE_CONTACT_TO_CAMPAIGN_FAILURE]: (state, { value }) => ({
    ...state,
    contacts: {
      ...state.contacts,
      loadingItems: without([value.contactId], state.contacts.loadingItems),
    },
  }),

  // SUBSCRIBE GROUP
  [ACTION_SUBSCRIBE_GROUP_TO_CAMPAIGN_REQUEST]: (state, { value }) => ({
    ...state,
    groups: {
      ...state.groups,
      loadingItems: uniq(concat([value.groupId], state.groups.loadingItems)),
    },
  }),
  [ACTION_SUBSCRIBE_GROUP_TO_CAMPAIGN_SUCCESS]: (state, { value }) => ({
    ...state,
    groups: {
      ...state.groups,
      loadingItems: without([value.groupId], state.groups.loadingItems),
    },
  }),
  [ACTION_SUBSCRIBE_GROUP_TO_CAMPAIGN_FAILURE]: (state, { value }) => ({
    ...state,
    groups: {
      ...state.groups,
      loadingItems: without([value.groupId], state.groups.loadingItems),
    },
  }),

  // ABORT CONTACT RUN
  [ACTION_UNSUBSCRIBE_FROM_CAMPAIGN_REQUEST]: (state, { value }) => ({
    ...state,
    contacts: {
      ...state.contacts,
      ...(value.includedContactIds && {
        loadingItems: uniq(concat(value.includedContactIds, state.contacts.loadingItems)),
      }),
    },
    groups: {
      ...state.groups,
      ...(value.includedGroupIds && {
        loadingItems: uniq(concat(value.includedGroupIds, state.groups.loadingItems)),
      }),
    },
    segments: {
      ...state.segments,
      ...(value.includedGroupIds && {
        loadingItems: uniq(concat(value.includedGroupIds, state.segments.loadingItems)),
      }),
    },
  }),
  [ACTION_UNSUBSCRIBE_FROM_CAMPAIGN_SUCCESS]: (state, { value }) => ({
    ...state,
    contacts: {
      ...state.contacts,
      loadingItems: without(value.includedContactIds, state.contacts.loadingItems),
    },
    groups: {
      ...state.groups,
      loadingItems: without(value.includedGroupIds, state.groups.loadingItems),
    },
    segments: {
      ...state.segments,
      loadingItems: without(value.includedGroupIds, state.segments.loadingItems),
    },
  }),
  [ACTION_UNSUBSCRIBE_FROM_CAMPAIGN_FAILURE]: (state, { value }) => ({
    ...state,
    contacts: {
      ...state.contacts,
      loadingItems: without(value.includedContactIds, state.contacts.loadingItems),
    },
    groups: {
      ...state.groups,
      loadingItems: without(value.includedGroupIds, state.groups.loadingItems),
    },
    segments: {
      ...state.segments,
      loadingItems: without(value.includedGroupIds, state.segments.loadingItems),
    },
  }),

  // GET FILE
  [ACTION_GET_CAMPAIGN_FILE_REQUEST]: (state, { value }) => ({
    ...state,
    files: {
      ...state.files,
      loading: true,
      loadingItems: uniq(concat([value.id], state.files.loadingItems)),
    },
  }),
  [ACTION_GET_CAMPAIGN_FILE_SUCCESS]: (state, { value }) => ({
    ...state,
    files: {
      ...state.files,
      loading: false,
      loadingItems: without([value.id], state.files.loadingItems),
      data: {
        ...state.files.data,
        [value.filename]: {
          ...pathOr({}, [value.filename], state.files.data),
          ...value,
        },
      },
    },
  }),
  [ACTION_GET_CAMPAIGN_FILE_FAILURE]: (state, { value }) => ({
    ...state,
    files: {
      ...state.files,
      loading: false,
      loadingItems: without([value.id], state.files.loadingItems),
    },
  }),

  // SAVE FILE
  [ACTION_SAVE_CAMPAIGN_FILE_REQUEST]: (state, { value }) => ({
    ...state,
    files: {
      ...state.files,
      loading: true,
      loadingItems: uniq(concat([value.id], state.files.loadingItems)),
    },
  }),
  [ACTION_SAVE_CAMPAIGN_FILE_SUCCESS]: (state, { value }) => ({
    ...state,
    files: {
      ...state.files,
      loading: false,
      loadingItems: without([value.id], state.files.loadingItems),
      data: {
        ...state.files.data,
        [value.filename]: value,
      },
    },
  }),
  [ACTION_SAVE_CAMPAIGN_FILE_FAILURE]: (state, { value }) => ({
    ...state,
    files: {
      ...state.files,
      loading: false,
      loadingItems: without([value.id], state.files.loadingItems),
    },
  }),

  // RESET FILE(S)
  [ACTION_CAMPAIGN_FILE_RESET]: (state, { value }) => ({
    ...state,
    files: {
      loading: false,
      loadingItems: [],
      data: value.id ? omit([value.id], state.files.data) : defaultState.files.data,
    },
  }),

  // NAVIGATE
  [ACTION_CAMPAIGNS_NAVIGATE]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      archived: value.archived,
      data: {
        ...state.list.data,
        page: value.page,
      },
    },
  }),

  // PAGE SIZE
  [ACTION_CAMPAIGNS_PAGE_SIZE]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      data: {
        ...state.list.data,
        size: value || state.data.size,
      },
    },
  }),
  [ACTION_CAMPAIGNS_SET_CHANGED]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      changed: value,
    },
  }),
})
