import validate, { hasPzURLError, hasRequiredError } from '@/app/service/validate'
import { compose, isEmpty, isNil, path, pathOr, reduce, uniq, values } from 'ramda'
import SMSHelper from 'smshelper'
import { hasBody, validateJSON } from './api-helpers'
import { ERROR_MISSING_T0, MAX_VOICE_RETRIES, messageTypeTextMap, smsMaxLength } from './definitions'
import {
  CAMPAIGN_NO_CONTENT,
  ContactSelectionType,
  hasContent,
  LanguageType,
  NO_SENDER_ID_TEXT,
  reduceIndexed,
  SenderIdsForDripType,
  SMSBLAST_NO_CONTENT_TEXT,
} from './helpers'
import { areSenderIdsSet } from './message-type-helpers'
import { isReminderCampaign } from './models/classes/reminder-campaign.class'
import { CampaignItem, ContentAudio, OnAnswerType, OnTimeout, Part, PartFamily } from './types'
import { getPartFamily, isPartQuestion, isVoicePart, toMessage } from './utils/part-content'
import { getVoicePartTranslation } from './utils/part-content/voice'
import { validateWhatsAppButtons, validateWhatsAppList } from './whatsapp-helpers'

export const checkCampaignForValidationErrors = ({
  item,
  contacts,
  senderIdsForDrip,
}: {
  item: CampaignItem | undefined
  contacts: ContactSelectionType
  senderIdsForDrip: SenderIdsForDripType
}) => {
  if (!item) {
    return {
      isValid: true,
      errorMessages: [],
    }
  }

  const { type } = item
  if (type === 'smsblast') {
    const errorMessages = []
    let isValid = true

    if (!path(['variables', 'message', 'text'], item)) {
      isValid = false
      errorMessages.push(SMSBLAST_NO_CONTENT_TEXT)
    }
    if (!areSenderIdsSet({ item, availableSenderIds: senderIdsForDrip })) {
      isValid = false
      errorMessages.push(NO_SENDER_ID_TEXT)
    }

    return {
      isValid,
      errorMessages,
    }
  }
  const isOpen = item.launchOpen === undefined ? true : item.launchOpen
  const languages = pathOr([], ['uiStore', 'languages'], item) as LanguageType[]
  const defaultLanguage = pathOr('', ['uiStore', 'defaultLanguage'], item)

  const checkParts = (messages: Part[] = []): string[] =>
    reduceIndexed(
      (acc: string[], message: Part, id: number) => {
        const errorMessages: string[] = []

        if (!languages.length) {
          const errorMessage = validateContent(message)
          if (errorMessage) {
            errorMessages.push(errorMessage)
          }
        } else {
          const errorsMap: Record<string, { message: string; languages: string[] }> = {}
          for (let i = 0; i < languages.length; i += 1) {
            const language = languages[i]
            let errorMessage = ''
            // only check for default language of non voice parts
            if (defaultLanguage === language.value) {
              errorMessage = validateContent(message)
            } else if (isVoicePart(message)) {
              const content = getVoicePartTranslation(message, language.value)
              if (content) {
                errorMessage = validateVoiceContent(content, true)
              }
            }

            if (errorMessage) {
              errorsMap[errorMessage] = {
                ...pathOr({}, [errorMessage], errorsMap),
                message: errorMessage,
                languages: [...pathOr([], [errorMessage, 'languages'], errorsMap), language.name],
              }
            }
          }

          const errors = Object.values(errorsMap)
          for (let i = 0; i < errors.length; i += 1) {
            const error = errors[i]

            const errorMessage = `${error.message} (Language${error.languages.length > 1 ? 's' : ''}: ${error.languages.join(', ')})`
            errorMessages.push(errorMessage)
          }
        }

        if (errorMessages.length) {
          const messageType = getPartFamily(message)
          const hasQuestion = isPartQuestion(message)
          const { label } =
            messageTypeTextMap[`${messageType}-${hasQuestion ? 'q' : 'm'}` as keyof typeof messageTypeTextMap]
          acc.push(
            `${Number(id) + 1}. ${errorMessages
              .map((errorMessage) => `${message.label || label} ${errorMessage}`)
              .join('. ')}`,
          )
        }
        return acc
      },
      [],
      messages,
    )

  const parts = pathOr([], ['variables', 'parts'], item)
  const validSenderIds = areSenderIdsSet({ item, availableSenderIds: senderIdsForDrip })

  const errorMessages = checkParts(parts)

  if (!parts.length) {
    errorMessages.push(CAMPAIGN_NO_CONTENT)
  }

  if (!validSenderIds) {
    errorMessages.push(NO_SENDER_ID_TEXT)
  }
  if (isReminderCampaign(item) && !item.variables.defaultArgs?.t0) {
    errorMessages.push(ERROR_MISSING_T0)
  }

  return {
    isValid:
      errorMessages.length === 0 &&
      (isOpen || contacts.allContactsAreSelected || pathOr(0, ['includedContactIds', 'length'], contacts)),
    errorMessages,
  }
}

export const validateHeaders = (headers: Record<string, string[]>) => {
  if (headers['']) {
    return 'has an empty header name'
  }

  const headerKeys = Object.keys(headers)
  for (let i = 0; i < headerKeys.length; i += 1) {
    const key = headerKeys[i]
    const headerValues = headers[key]
    if (headerValues.length === 0) {
      return `has no value for header '${key}'`
    }
    if (headerValues.includes('')) {
      return `has an empty value for header '${key}'`
    }
  }

  return ''
}

export const hasChoices = (onAnswer: OnAnswerType[]) =>
  reduce(
    (accum: boolean, { replies = [], ranges = [], reply }) => {
      const hasReplies = replies.length > 0 || ranges.length > 0 || !!reply
      return accum && hasReplies
    },
    true,
    onAnswer,
  )

export const hasRanges = (onAnswer: OnAnswerType[]) => {
  for (let i = 0; i < onAnswer.length; i += 1) {
    const { ranges } = onAnswer[i]
    if (ranges?.length) {
      return true
    }
  }
  return false
}

// basic check of a campaign part (SMS Question, WhatsApp Message, etc)
// returns empty string if valid, or an error message of the first error found
export const validateContent = (content: Part): string => {
  const item = toMessage(content)
  switch (item.type) {
    // action
    case PartFamily.Action:
      return ''

    case PartFamily.API: {
      const {
        apiCall: { method, url, contentType, headers, body },
      } = item

      if (!url) {
        return 'has no URL'
      }

      const invalidUrl = validate(hasRequiredError, hasPzURLError)(url)
      if (invalidUrl) {
        return 'has an invalid URL'
      }

      if (hasBody(method)) {
        if (!body) {
          return 'has no body'
        }

        if (contentType === 'application/json' && !validateJSON(body)) {
          return 'has invalid JSON in body'
        }
      }

      return validateHeaders(headers || {})
    }

    // CATI
    case PartFamily.CATI: {
      if (item.hasQuestion && item.onAnswer && !hasChoices(item.onAnswer)) {
        return '- all choices must have a reply'
      }
      if (!hasContent(pathOr({}, ['message'], item), 'text', false)) {
        return 'has no content'
      }
      return ''
    }

    // SMS
    case PartFamily.SMS: {
      if (item.hasQuestion && item.onAnswer && !hasChoices(item.onAnswer)) {
        return '- all choices must have a reply text'
      }
      if (item.hasQuestion && item.onAnswer && item.useAi && hasRanges(item.onAnswer)) {
        return '- ranges are not supported with AI yet'
      }
      if (!hasContent(pathOr({}, ['message'], item), 'text', false)) {
        return 'has no text'
      }
      const parts = SMSHelper.parts(pathOr('', ['message', 'text'], item))
      if (parts > smsMaxLength) {
        return 'is too long'
      }
      return (item.hasQuestion && item.onTimeout && validateTimeout(item.onTimeout)) || ''
    }

    case PartFamily.Topup: {
      const maxAmount = path(['topup', 'maxAmount'], item)
      const desiredAmount = path(['topup', 'desiredAmount'], item)
      if (!maxAmount) {
        return 'has no max amount'
      }

      const valid = isNil(desiredAmount) || Number(maxAmount) >= Number(desiredAmount)
      if (!valid) {
        return 'max amount must be greater than or equal to desired amount'
      }
      return ''
    }

    // voice
    case PartFamily.Voice: {
      if (item.hasQuestion && item.onAnswer) {
        if (item.speechSettings) {
          const speechLanguages = (item.speechSettings.languages || {}) as Record<string, string>
          const languages = compose<Record<string, string>, string[], string[]>(uniq, values)(speechLanguages)
          for (let i = 0; i < item.onAnswer.length; i += 1) {
            let hasSpeechReplies = false
            const { speechReplies } = item.onAnswer[i]
            for (let j = 0; j < languages.length; j += 1) {
              const language = languages[j]
              if (speechReplies?.[language]?.length) {
                hasSpeechReplies = true
                break
              }
            }

            if (!hasSpeechReplies) {
              return '- all choices must have at least one speech recognition word configured'
            }
          }
        } else if (!hasChoices(item.onAnswer)) {
          return '- all choices must have a keypress or range'
        }
      }

      const voiceErr = validateVoiceContent(item.audio)
      if (voiceErr) {
        return voiceErr
      }
      if (item.hasQuestion && Number(item.retries) > MAX_VOICE_RETRIES) {
        return `has too many retries (max ${MAX_VOICE_RETRIES})`
      }
      return (item.hasQuestion && item.onTimeout && validateTimeout(item.onTimeout)) || ''
    }

    // whatsapp
    case PartFamily.WhatsApp: {
      if (item.hasQuestion) {
        if (item.onAnswer && !hasChoices(item.onAnswer)) {
          return '- all choices must have a reply text'
        }
        if (item.onAnswer && item.useAi && hasRanges(item.onAnswer)) {
          return '- ranges are not supported with AI yet'
        }
        if (item.onButtons) {
          const errorMessage = validateWhatsAppButtons(item.onButtons)
          if (errorMessage) {
            return errorMessage
          }
        }
        if (item.onList) {
          const errorMessage = validateWhatsAppList(item.onList)
          if (errorMessage) {
            return errorMessage
          }
        }
      }

      const valid =
        pathOr(0, ['message', 'text', 'length'], item) > 0 ||
        pathOr(0, ['message', 'image', 'length'], item) > 0 ||
        pathOr(0, ['message', 'audio', 'length'], item) > 0 ||
        pathOr(0, ['message', 'video', 'length'], item) > 0 ||
        pathOr(0, ['message', 'document', 'length'], item) > 0
      if (!valid) {
        return 'has no text, image, audio, video or document'
      }
      return (item.hasQuestion && item.onTimeout && validateTimeout(item.onTimeout)) || ''
    }

    default:
      return '' // unhandled, but nothing to do
  }
}

export const validateTimeout = (onTimeout: OnTimeout) => {
  const { timeout, timeoutSeconds } = onTimeout
  if (typeof timeoutSeconds === 'number') {
    if (timeoutSeconds < 0) {
      return 'timeout cannot be negative'
    }
    if (timeoutSeconds >= 1000) {
      return 'timeout cannot be greater than 999 seconds' // got it from backend
    }
  } else if (timeout) {
    const { unit, value } = timeout

    if (value < 0) {
      return 'timeout cannot be negative'
    }

    if (value && !unit) {
      return 'timeout must have a unit'
    }
    if (!value && unit) {
      return 'timeout cannot be zero'
    }
  }
  return ''
}

export const validateVoiceContent = (voiceContent: ContentAudio, ignoreAudio = false): string => {
  const hasSay = voiceContent.say
  const hasVoice = voiceContent.voice

  if (hasSay) {
    if (!hasVoice) {
      return 'has no selected voice for Text-to-Speech'
    }
  } else if (hasVoice) {
    return 'has no message for Text-to-Speech'
  } else if (!ignoreAudio && !hasContent(voiceContent, 'playfile', false)) {
    return 'has no audio'
  }

  return ''
}

export const hasValidTTSContent = (voiceContent: ContentAudio): boolean => {
  const { voice, say, translations } = voiceContent
  if (!voice || !say) {
    return false
  }

  return (
    !translations ||
    isEmpty(translations) ||
    Object.values(translations).every(
      (translation) => (!translation.voice && !translation.say) || (translation.voice && translation.say),
    )
  )
}
