import { esApi } from '@/__generated/es-api'
import { createNotification } from '@/app/module/notifications/store/actions'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { processErrors } from '@/app/service/util/errorCodes'
import { useAppDispatch } from '@/app/store/redux-hooks'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

const extendedApi = esApi.enhanceEndpoints({
  endpoints: {
    createSpeechPreview: {
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/say`,
        method: 'POST',
        body: queryArg.body,
        responseHandler: (response) => response.blob(),
      }),
    },
  },
})

export const useCreateSpeechPreview = () => {
  const dispatch = useAppDispatch()
  const orgId = useSelector(selectOrgId)
  const [createPreview, res] = extendedApi.endpoints.createSpeechPreview.useMutation()

  return [
    useCallback(
      async (voice: string, what: string) => {
        try {
          await createPreview({
            orgId: String(orgId),
            body: {
              voice,
              what,
            },
          }).unwrap()
          return true
        } catch (err: any) {
          dispatch(
            createNotification({
              'get-voice-preview': {
                type: 'error',
                message: processErrors(err, 'Failed to get voice preview. Please try again.'),
              },
            }),
          )
          return false
        }
      },
      [orgId, createPreview, dispatch],
    ),
    res,
  ] as const
}
