import React, { PureComponent } from 'react'
import { DropzoneRef } from 'react-dropzone'
import Uploader from '@/app/component/atom/upload'
import AudioWrapper from './wrapper'

type Props = {
  id: string
  loading: boolean
  uploadContent: React.ReactNode
  uploadRef: React.RefObject<DropzoneRef>

  onUpload: (file: File) => void
}

export default class AudioUploader extends PureComponent<Props> {
  render() {
    const { id, loading, uploadContent, uploadRef, onUpload } = this.props

    return (
      <AudioWrapper border={false} loading={loading}>
        <div style={{ height: '100%' }}>
          <Uploader
            accept={acceptList}
            extensions={extensionList}
            namespace={`campaign-${id}`}
            uploadContent={uploadContent}
            uploadRef={uploadRef}
            onAccept={({ file }: { file: File }) => {
              onUpload(file)
            }}
          />
        </div>
      </AudioWrapper>
    )
  }
}

export const acceptList = [
  '.mp3',
  '.wav',
  '.ogg',
  '.m4a',
  '.aac',
  '.opus',
  '.wma',
  '.mp4',
  'audio/ogg',
  'audio/wav',
  'audio/webm',
  'audio/x-aac',
  'audio/aac',
  'audio/opus',
  'audio/wma',
  'audio/mp4',
]

export const extensionList = ['wav', 'mp3', 'ogg', 'm4a', 'aac', 'opus', 'wma', 'mp4']
