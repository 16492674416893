import { Language } from '@/app/module/campaigns/types'

export type TranscriptionLang = {
  name: string
  speechRecognition?: string
  transcribe?: string
}

export const getSpeechLanguages = (languages: TranscriptionLang[]) =>
  languages.reduce<Language[]>((acc, lang) => {
    if (!lang.speechRecognition) {
      return acc
    }
    acc.push({ name: lang.name, value: lang.speechRecognition })
    return acc
  }, [])

export const getTranscribeLanguages = (languages: TranscriptionLang[]) =>
  languages.reduce<Language[]>((acc, lang) => {
    if (!lang.transcribe) {
      return acc
    }
    acc.push({ name: lang.name, value: lang.transcribe })
    return acc
  }, [])
