import IconText from '@/app/component/layout/icon-text'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import { hasValidTTSContent } from '@/app/module/campaigns/campaign-validation-helpers'
import Voice from '@/app/module/campaigns/component/item/steps/content/voice'
import { Q_ACTIONS, displayMap } from '@/app/module/campaigns/definitions'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { pathOr } from 'ramda'
import { Component } from 'react'

export default class CampaignContentQuestionDialogSMS extends Component {
  state = {
    audio: pathOr('', [Q_ACTIONS.voice.key], this.props.value),
    error: '',
  }

  isSaveDisabled = () => {
    const { audio, error } = this.state
    const isAudioFile = typeof audio?.playfile === 'string'
    return !!error || !audio || (isAudioFile && !audio.playfile) || (!isAudioFile && !hasValidTTSContent(audio))
  }

  render() {
    const { voiceProps, onSave, onClose, languages, defaultLanguage } = this.props

    return (
      <CustomDialog open={true} onClose={onClose}>
        <DialogTitle style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <IconText>
              <Icon>{displayMap.voice.icon}</Icon>
              <Typography variant="h6">{Q_ACTIONS.voice.label}</Typography>
            </IconText>
            <IconButton onClick={onClose}>
              <Icon>close</Icon>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent style={{ paddingTop: '24px' }}>
          <div
            style={{
              width: '70vw',
              minWidth: '300px',
              maxWidth: '100%',
            }}
          >
            <Voice
              {...voiceProps}
              languages={languages}
              defaultLanguage={defaultLanguage}
              enableTranscription
              item={{
                id: voiceProps.id,
                audio: this.state.audio,
              }}
              changeHandler={(item) => {
                this.setState({ audio: pathOr('', ['audio'], item) })
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={this.isSaveDisabled()}
            onClick={() =>
              onSave({
                play: this.state.audio,
              })
            }
          >
            Save
          </Button>
        </DialogActions>
      </CustomDialog>
    )
  }
}
