import { Checkbox, Select } from '@/app/component/atom/form'
import { repeatQuestionOptions } from '@/app/module/campaigns/definitions'
import { RepeatQuestion } from '@/app/module/campaigns/types'
import { styled, Typography } from '@mui/material'
import React from 'react'
import { ActionsBoxContainer } from './response-configs-components'

type Props = {
  repeatQuestion: RepeatQuestion

  onChange: (value: Partial<RepeatQuestion>) => void
}

const ResponseVoiceRepeatKey: React.FC<Props> = ({ repeatQuestion, onChange }) => {
  const handleKeyChange = React.useCallback(({ value }: { value: string }) => onChange({ reply: value }), [onChange])
  const handleToggle = React.useCallback(
    ({ value }: { value: string }) => {
      const enabled = value === 'true'
      onChange({ enabled, reply: enabled ? '0' : '' })
    },
    [onChange],
  )

  return (
    <div className="campaign-question-repeat-box">
      <Checkbox
        editable
        label="Repeat question"
        name="repeat-question"
        value={(repeatQuestion.enabled || false).toString()}
        onChange={handleToggle}
      />
      <ActionsBoxContainer>
        <Typography color="textSecondary" gutterBottom variant="caption">
          Specify a key to repeat the question. When the contact presses this key, the question will be repeated once.
          The keypress will not be counted as a valid or invalid reply.
        </Typography>
        {repeatQuestion.enabled && (
          <SelectContainer>
            <StyledSelect
              id="campaign-item-repeatQuestion"
              label="Select key"
              name="repeat-question"
              editable
              value={repeatQuestion.reply || '0'}
              values={repeatQuestionOptions}
              onChange={handleKeyChange}
            />
          </SelectContainer>
        )}
      </ActionsBoxContainer>
    </div>
  )
}

const SelectContainer = styled('div')({
  backgroundColor: 'rgb(245, 245, 245)',
  padding: '12px',
  margin: '12px 0',
})

const StyledSelect = styled(Select<string>)({
  width: '10%',
})

export default React.memo(ResponseVoiceRepeatKey)
